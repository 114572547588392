import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled, css } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 25rem;
    padding: 0.5rem 1rem;
  }
`;

const TitleContainer = styled(DialogTitle)(
  ({ theme }) => css`
    min-height: 1.25rem;
    font-size: 2rem;
    color: ${theme.colors.gray7};
    font-weight: 500;
    padding-bottom: 0;
  `
);

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    min-width: auto;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    color: ${theme.colors.gray7};
  `
);

const ActionButton = styled(Button)<{ $variant: 'primary' | 'secondary' }>(
  ({ theme, $variant }) => css`
    color: ${$variant === 'primary' ? theme.palette.common.white : theme.colors.gray7};
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.125rem;
    font-size: 1rem;
    background-color: ${$variant === 'primary' ? theme.colors.red : theme.palette.common.white};
    border-color: ${$variant === 'primary' ? theme.colors.red : theme.colors.gray7};
    text-transform: uppercase;

    :hover {
      background-color: ${$variant === 'primary' ? theme.colors.gray7 : theme.palette.common.white};
      color: ${$variant === 'primary' ? theme.palette.common.white : theme.colors.gray7};
    }
  `
);

interface BasicFormDialogProps {
  children?: React.ReactNode;
  formTitle?: string;
  handleSubmit?: (event: React.FormEvent) => Promise<void>;
  open: boolean;
  toggleModal: () => void;
}

type Props = BasicFormDialogProps;

const BasicFormDialog = ({
  children,
  formTitle,
  handleSubmit,
  open,
  toggleModal,
}: Props): JSX.Element => {
  return (
    <StyledDialog open={open} onClose={toggleModal}>
      <TitleContainer>{formTitle}</TitleContainer>
      <CloseButton aria-label='close' onClick={toggleModal}>
        <CloseIcon />
      </CloseButton>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ mr: '1rem', mb: '.5rem' }}>
        <ActionButton $variant='secondary' onClick={toggleModal} disableRipple>
          Cancel
        </ActionButton>
        <ActionButton $variant='primary' onClick={handleSubmit} type='submit'>
          Submit
        </ActionButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default BasicFormDialog;
