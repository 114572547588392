import React from 'react';
import { css, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { formatCurrency } from 'utils/functions';
import { SummaryLoanDetailsType } from './types';
import { formatPercent } from 'utils/functions';
import theme from 'theme';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const StatDisplay = styled('div')(
  ({ theme }) => css`
    background-color: black;
    padding: 1.5rem;
    text-align: left;
    color: white;
    width: calc(45% - 1.5rem);
    display: flex;
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
    ${theme.breakpoints.down('sm')} {
      width: calc(100% - 2rem);
      margin-bottom: 2rem;
    }
  `
);

const SubInterestFont = styled(Typography)`
  font-family: 'Teko';
  font-size: 3.375rem;
  line-height: 142%;
  padding-top: 0.5rem;
`;

const SubHeaderText = styled(Typography)`
  font-family: 'Teko';
  opacity: 0.5;
  font-size: 1rem;
  line-height: 181%;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
`;

const MarginBox = styled(Box)`
  margin-left: 7.5rem;
  ${theme.breakpoints.down(1100)} {
    margin-left: 3.5rem;
  }
  ${theme.breakpoints.down('md')} {
    margin-left: 0;
  }
`;

interface SummaryLoanDetailsProps {
  summaryLoanDetails: SummaryLoanDetailsType;
}

type Props = SummaryLoanDetailsProps;

const SummaryLoanDetails = ({ summaryLoanDetails }: Props): JSX.Element => {
  return (
    <Container sx={{ mb: '3rem' }}>
      <Wrapper>
        <StatDisplay>
          <Box>
            <SubInterestFont>{formatCurrency(summaryLoanDetails.currentCommitted)}</SubInterestFont>
            <SubHeaderText>Amount Committed</SubHeaderText>
          </Box>
        </StatDisplay>
        <StatDisplay>
          <Box>
            <SubInterestFont>{formatCurrency(summaryLoanDetails.currentDeployed)}</SubInterestFont>
            <SubHeaderText>Amount Deployed</SubHeaderText>
          </Box>
          <MarginBox>
            <SubInterestFont>{formatPercent(summaryLoanDetails?.currentRate)}</SubInterestFont>
            <SubHeaderText>Current Rate</SubHeaderText>
          </MarginBox>
        </StatDisplay>
      </Wrapper>
    </Container>
  );
};

export default SummaryLoanDetails;
