import { css, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const LoginButton = styled(Button)(
  ({ theme }) => css`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 21.25rem;
    max-width: 100%;
    background-color: ${theme.colors.red};
  `
);

export default LoginButton;
