import React, { useMemo } from 'react';
import { investorsHeaders, totalHeaders } from 'utils/MockData/investors';
import ExpandableList from 'components/ExpandableList/ExpandableList';
import { getDatesListForVariant, getDaysList, getDaysListForQuarter } from 'utils/functions';
import { DateRangeVariant, getDateTime, getDisplayDateByVariant } from 'utils/dates';
import { AllInvestorsData, DailyInvestorData } from 'generated/graphql';
import { Investor } from 'types';

interface InvestorExpandableListProps {
  currentDate: string;
  allInvestorsData?: AllInvestorsData;
  variant: DateRangeVariant;
}

type Props = InvestorExpandableListProps;

const InvestorExpandableList = ({ currentDate, allInvestorsData, variant }: Props) => {
  const daysList = useMemo(
    () =>
      variant === 'quarter'
        ? getDaysListForQuarter(getDisplayDateByVariant(getDateTime(currentDate), 'quarter'))
        : getDaysList(currentDate, 'yyyy-MM'),
    [currentDate, variant]
  );
  const datesList = useMemo(
    () => getDatesListForVariant(currentDate, variant),
    [currentDate, variant]
  );

  const items = useMemo<Investor[]>(
    () =>
      (allInvestorsData &&
        allInvestorsData?.investors
          ?.map(({ investor, investorTotals }): Investor | undefined => {
            if (!investor?.memberName) {
              return undefined;
            }

            return {
              id: investor?.id || undefined,
              name: investor?.memberName,
              interestEarned: investorTotals?.interestAmount,
            };
          })
          .filter((x): x is Investor => !!x)) ||
      [],
    [allInvestorsData]
  );

  const details = useMemo<{ capital: number; interest: number }[][]>(() => {
    if (!allInvestorsData?.investors || allInvestorsData.investors.length === 0) {
      return [];
    }

    return allInvestorsData?.investors
      ?.map(({ investor, dailyInvestorData }) => {
        if (!investor?.memberName) {
          return undefined;
        }

        return datesList.map((date) => {
          const dayData = dailyInvestorData.find((x: DailyInvestorData) => x?.date === date);

          return {
            capital: dayData ? dayData.committedAmount : 0,
            interest: dayData ? dayData.interestAmount : 0,
          };
        });
      })
      .filter((x): x is { capital: number; interest: number }[] => !!x);
  }, [allInvestorsData?.investors, datesList]);

  const detailsTotal = useMemo<{ committed: number; interest: number }[]>((): any => {
    if (
      !allInvestorsData?.total?.dailyInvestorData ||
      allInvestorsData?.total.dailyInvestorData.length === 0
    ) {
      return [];
    }

    const dailyTotalData = allInvestorsData?.total.dailyInvestorData;

    return datesList.map((date) => {
      const dayData = dailyTotalData.find((x) => x?.date === date);
      const committed = dayData?.committedAmount ?? 0;
      const gpCommitted = dayData?.gpCommitted ?? 0;

      return {
        committed: committed,
        lpInvestment: committed - gpCommitted,
        interest: dayData ? dayData.interestAmount : 0,
      };
    });
  }, [allInvestorsData?.total?.dailyInvestorData, datesList]);

  const totalItem = useMemo(
    () => ({
      name: 'Totals',
      interestEarned: allInvestorsData?.total?.interestTotal || 0,
    }),
    [allInvestorsData?.total?.interestTotal]
  );

  return (
    <ExpandableList
      days={daysList}
      items={items}
      headers={investorsHeaders}
      totalHeaders={totalHeaders}
      details={details}
      detailsTotal={detailsTotal}
      title='Investors'
      tallyHeaders={['Interest Earned']}
      rowHeaders={['Interest Earned']}
      totalItem={totalItem}
      fixOnScroll
    />
  );
};

export default InvestorExpandableList;
