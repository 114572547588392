import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CapitalData, InterestData } from './types';
import { formatCurrency, formatPercent } from 'utils/functions';
import TitledContainer from 'components/TitledContainer/TitledContainer';

export const Wrapper = styled('div')`
  color: white;
  * {
    font-family: 'Teko';
  }
  height: 100%;
`;

export const CapitalFont = styled(Typography)`
  font-size: 4.5rem;
  font-weight: 400;
  letter-spacing: 0.07rem;
`;

export const SubHeaderText = styled(Typography)`
  opacity: 0.6;
  font-size: 0.875rem;
  font-weight: 700;
  padding-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
`;

export const SubInterestFont = styled(Typography)`
  font-size: 3.375rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
  letter-spacing: 0.09375rem;
  line-height: 1;
`;

interface OverviewCardProps {
  reportData?: InterestData | CapitalData | undefined;
  timePeriod?: string;
  divider?: boolean;
  withChildren?: boolean;
  children?: React.ReactNode;
  title?: string;
}

type Props = OverviewCardProps;

const OverviewCard = ({
  title,
  reportData,
  timePeriod,
  divider,
  withChildren,
  children,
}: Props): JSX.Element => {
  return (
    <Wrapper>
      <TitledContainer
        borderless
        dark
        divider={divider}
        title={title ? title : timePeriod?.length ? `${timePeriod} Report` : 'Capital'}
      >
        {withChildren && children}
        {!withChildren && (
          <>
            {timePeriod?.length && (
              <CapitalFont variant='h1'>
                {formatCurrency((reportData as InterestData)?.interestAmount)}
              </CapitalFont>
            )}
            {!timePeriod?.length && (
              <CapitalFont variant='h1'>
                {formatCurrency((reportData as CapitalData)?.deployedCapital)}
              </CapitalFont>
            )}
            <SubHeaderText>
              {timePeriod?.length && `${timePeriod} Interest Amount: P&L`}
              {!timePeriod?.length && 'Deployed Capital'}
            </SubHeaderText>
            {timePeriod?.length && (
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Box sx={{ mr: 3 }}>
                  <SubInterestFont>
                    {formatCurrency((reportData as InterestData)?.totalInterestExpense)}
                  </SubInterestFont>
                  <SubHeaderText>Total Investor Earned Interest</SubHeaderText>
                </Box>
                <Box sx={{ mr: 3 }}>
                  <SubInterestFont>
                    {formatCurrency((reportData as InterestData)?.dailyInterestExpense)}
                  </SubInterestFont>
                  <SubHeaderText>Daily Investor Earned Interest</SubHeaderText>
                </Box>
                <Box>
                  <SubInterestFont>
                    {formatPercent((reportData as InterestData)?.blendedInterestRate)}
                  </SubInterestFont>
                  <SubHeaderText>Blended Interest Rate</SubHeaderText>
                </Box>
              </Box>
            )}
            {!timePeriod?.length && (
              <>
                <CapitalFont variant='h1' sx={{ marginTop: '-0.6rem' }}>
                  {formatCurrency((reportData as CapitalData)?.capitalCommitment)}
                </CapitalFont>
                <SubHeaderText>Capital Commitment</SubHeaderText>
              </>
            )}
          </>
        )}
      </TitledContainer>
    </Wrapper>
  );
};

export default OverviewCard;
