import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountPage from './routes/Account/AccountPage';
import Error404Page from './routes/Error404Page/Error404Page';
import LoanSummary from './routes/LoanSummary/LoanSummary';
import LoanDetails from './routes/LoanDetails/LoanDetails';
import InvestorDetails from 'routes/InvestorDetails/InvestorDetails';
import ProtectedRoute from 'routes/ProtectedRoute/ProtectedRoute';
import Login from 'routes/Login/Login';
import PasswordReset from 'routes/PasswordReset/PasswordReset';

function AppRoutes(): JSX.Element {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/password-reset' element={<PasswordReset />} />
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <LoanSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path='/my-account'
          element={
            <ProtectedRoute>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/loan-details/:id'
          element={
            <ProtectedRoute>
              <LoanDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path='/investors/:id'
          element={
            <ProtectedRoute>
              <InvestorDetails />
            </ProtectedRoute>
          }
        />
        <Route path='/404' element={<Error404Page />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
