import React from 'react';
import Box from '@mui/material/Box';
import { ifProp } from 'styled-tools';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { Label } from 'components/ExpandableList/ListAccordion/ListAccordion';
import { formatValue, doubleDigitFormat } from 'utils/functions';
import omit from 'lodash.omit';

export const DetailsContainer = styled('div')`
  display: flex;
  overflow: hidden;
`;

export const DetailHeaders = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.mifGray9};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem;

    ${Label} {
      font-weight: 700;
      margin-bottom: 0.35rem;
      width: max-content;
      color: ${theme.colors.gray7};

      &:first-child {
        margin-bottom: 1.2rem;
        opacity: 1;
      }
    }
  `
);

export const CardsContainer = styled(Box)<{ $borderRadius: boolean }>(
  ({ theme }) => css`
    display: flex;
    overflow: scroll;
    scrollbar-color: ${theme.colors.mifBlue1} ${theme.colors.mifGray11};
    background-color: white;

    &::-webkit-scrollbar {
      background-color: ${theme.colors.mifGray11};
      height: 0.5rem;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.mifBlue1};
      border-radius: 0.25rem;
    }

    ${ifProp(
      '$borderRadius',
      css`
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
      `
    )}
  `
);

export const DetailCard = styled('div')<{ $color?: 'success' | 'error' }>(
  ({ theme, $color }) => css`
    margin: 0.5rem 0;
    padding: 2rem 0;
    background-color: ${$color && theme.palette[$color]
      ? theme.palette[$color].light
      : theme.palette.common.white};

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &:hover {
      background-color: ${$color && theme.palette[$color]
        ? theme.palette[$color].main
        : theme.colors.mifGray10};
    }
  `
);

export const DetailValue = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.gray7};
    font-size: 0.8125rem;
    line-height: 2.3;
    font-weight: 700;
  `
);

export const InnerWrapper = styled('div')(
  ({ theme }) => css`
    border-right: 0.0625rem solid ${theme.colors.mifGray8};
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    ${DetailValue} {
      &:first-child {
        font-family: 'Teko';
        font-size: 1.625rem;
        line-height: 1.42;
        font-weight: 600;
      }

      &:last-child {
        opacity: 0.5;
      }
    }
  `
);

interface ExpandedDetailsProps {
  days?: Array<number | string>;
  details?: object | Record<string, string | number>[];
  headers: Array<string>;
  borderRadius?: boolean;
  endOfList?: boolean;
}

type Props = ExpandedDetailsProps;

const ExpandedDetails = ({ borderRadius = false, days, details, headers }: Props): JSX.Element => {
  return (
    <DetailsContainer>
      <DetailHeaders>
        {headers?.map((header, index) => (
          <Label key={index}>{header}</Label>
        ))}
      </DetailHeaders>
      <CardsContainer $borderRadius={borderRadius}>
        {days?.map((item, index) => {
          const detail = details
            ? Array.isArray(details)
              ? (details[index] as Record<string, string | number>) || undefined
              : details
            : undefined;

          let cardColor: 'success' | 'error' | undefined;
          // @ts-ignore
          if (detail?.['hasDeployedAdjustments']) {
            cardColor = 'success';
          } else {
            // @ts-ignore
            if (detail?.['hasLateFees']) {
              cardColor = 'error';
            }
          }

          return (
            <DetailCard key={index} $color={cardColor}>
              <InnerWrapper>
                <DetailValue>
                  {typeof item === 'string' ? item : doubleDigitFormat(item)}
                </DetailValue>
                {detail &&
                  Object.entries(
                    omit(detail, [
                      'hasLateFees',
                      'hasDeployedAdjustments',
                      'hasCommittedAdjustments',
                    ])
                  ).map(([label, value]) => {
                    if (label !== '__typename') {
                      return <DetailValue key={label}>{formatValue(label, value)}</DetailValue>;
                    }
                  })}
              </InnerWrapper>
            </DetailCard>
          );
        })}
      </CardsContainer>
    </DetailsContainer>
  );
};

export default ExpandedDetails;
