import React from 'react';
import { DateTime, Info } from 'luxon';
import { FormErrors, Loan } from 'types';
import FormattedNumber from 'components/FormattedNumber/FormattedNumber';
import { DateRangeVariant, getDateTime, getDisplayDateByVariant } from './dates';
import { AllLoansData } from 'generated/graphql';

export function formatCurrency(value: number | string | undefined | null) {
  if (!value) {
    return '-';
  }

  if (value || value === 0) {
    const setNumber = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(setNumber);
  } else {
    return;
  }
}

export function formatPercent(value: number | string | undefined | null) {
  if (!value) {
    return '-';
  } else {
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    return (
      <FormattedNumber
        minimumFractionDigits={0}
        maximumFractionDigits={4}
        style='percent'
        value={numberValue}
      />
    );
  }
}

export function formatValue(label: string, value: number | string) {
  if (label === 'date') {
    return value;
  } else if (label === 'rate') {
    return formatPercent(value);
  } else {
    return formatCurrency(value);
  }
}

export function doubleDigitFormat(number: number): string {
  if (number < 10 && number >= 0) {
    return `0${number}`;
  } else {
    return `${number}`;
  }
}

export function formatUSPhone(value: string | number) {
  const phoneString = typeof value === 'number' ? value.toString() : value;
  const finalFour = phoneString.slice(-4);

  return `xxx-xxx-${finalFour}`;
}

export const currentDate = DateTime.now();
export const currentMonth = currentDate.get('month');
export const currentQuarter = currentDate.get('quarter');
export const currentYear = currentDate.get('year');
export const formattedDate = getDisplayDateByVariant(
  DateTime.local(currentYear, currentMonth),
  'month'
);

export function getDaysList(date: string, format = 'MMMM yyyy') {
  const daysList = [];
  const daysInMonth = DateTime.fromFormat(date, format).daysInMonth;

  for (let i = 1; i <= daysInMonth; i++) {
    daysList.push(i);
  }

  return daysList;
}

const monthsByQuarter = new Map([
  ['Q1', ['January', 'February', 'March']],
  ['Q2', ['April', 'May', 'June']],
  ['Q3', ['July', 'August', 'September']],
  ['Q4', ['October', 'November', 'December']],
]);

export function getQuarterArray() {
  const quarter = currentQuarter.toString();
  const year = currentYear.toString();
  const quarterValue = `Q${quarter}`;
  const months = monthsByQuarter.get(quarterValue);

  return months?.map((month) => {
    return `${month} ${year}`;
  });
}

// Get a list of days for each month in the quarter.
// The year is important because of leap years.
// Ex. ["01/01", "01/02", ..., "03/30", "03/31"]
export function getDaysListForQuarter(quarterYear: string) {
  const [quarterPeriod, year] = quarterYear.split(' ');

  return (
    monthsByQuarter
      .get(quarterPeriod)
      ?.map((month) => {
        const abbreviatedMonth = `${DateTime.fromFormat(month, 'MMMM').toFormat('MM')}`;

        return getDaysList(`${month} ${year}`).map(
          (day) => `${abbreviatedMonth}/${doubleDigitFormat(day)}`
        );
      })
      .flat() ?? []
  );
}

export function getDatesListForVariant(
  date: string,
  variant: DateRangeVariant,
  format = 'yyyy-MM'
): string[] {
  if (variant === 'quarter') {
    return getDatesListInQuarter(date, format);
  }

  return getDatesListInMonth(date, format);
}

export function getItems(loansData: AllLoansData): Loan[] {
  return loansData.loans
    .map(({ loanInfo, loanTotals }): Loan | undefined => {
      if (!loanInfo.client?.clientName) {
        return undefined;
      }

      let loanName = loanInfo.client.clientName;
      if (loanInfo.compoundInterest) {
        loanName += '***';
      }

      return {
        id: loanInfo.id || undefined,
        name: loanName,
        rate: loanInfo.sofrRate ? loanTotals.blendedInterestRate : loanInfo.interestRate,
        committed: loanTotals.lastCommittedAmount,
        deployed: loanTotals.lastDeployedAmount,
        interestTotal: loanTotals.interestAmount,
      };
    })
    .filter((x): x is Loan => !!x);
}

export function getDetails(
  allLoansData: AllLoansData | undefined,
  datesList: string[]
): {
  deployed: number;
  interest: number;
  hasDeployedAdjustments: boolean;
  hasCommittedAdjustments: boolean;
  hasLateFees: boolean;
}[][] {
  if (!allLoansData?.loans || allLoansData.loans.length === 0) {
    return [];
  }

  return allLoansData.loans
    .map(({ loanInfo, dailyLoanData }) => {
      if (!loanInfo.client?.clientName) {
        return undefined;
      }

      return datesList.map((date) => {
        const dayData = dailyLoanData.find((x) => x.date === date);

        return {
          deployed: dayData ? dayData.deployedAmount : 0,
          interest: dayData ? dayData.interestAmount : 0,
          hasDeployedAdjustments: dayData ? dayData.hasDeployedAdjustments : false,
          hasCommittedAdjustments: dayData ? dayData.hasCommittedAdjustments : false,
          hasLateFees: dayData ? dayData.hasLateFees : false,
        };
      });
    })
    .filter(
      (
        x
      ): x is {
        deployed: number;
        interest: number;
        hasDeployedAdjustments: boolean;
        hasCommittedAdjustments: boolean;
        hasLateFees: boolean;
      }[] => !!x
    );
}

export function getDetailsTotal(
  allLoansData: AllLoansData | undefined,
  datesList: string[]
): { deployed: number; interest: number }[] {
  if (!allLoansData?.total.dailyLoanData || allLoansData?.total.dailyLoanData.length === 0) {
    return [];
  }

  const dailyTotalData = allLoansData?.total.dailyLoanData;

  return datesList.map((date) => {
    const dayData = dailyTotalData.find((x) => x.date === date);

    return {
      deployed: dayData ? dayData.deployedAmount : 0,
      interest: dayData ? dayData.interestAmount : 0,
    };
  });
}

export function getDatesListInMonth(date: string, format = 'yyyy-MM'): string[] {
  const daysList: string[] = [];
  const dateTime = DateTime.fromFormat(date, format);
  const daysInMonth = dateTime.daysInMonth;

  const prefix = dateTime.toFormat('yyyy-MM');

  for (let i = 1; i <= daysInMonth; i++) {
    daysList.push(`${prefix}-${i.toString().padStart(2, '0')}`);
  }

  return daysList;
}

export function getDatesListInQuarter(date: string, format = 'yyyy-MM'): string[] {
  const dateTime = DateTime.fromFormat(date, format);
  const start = dateTime.startOf('quarter');
  const end = dateTime.endOf('quarter');

  return getDatesListBetween(start, end, true);
}

export function getDatesListBetween(
  startDate: DateTime,
  endDate: DateTime,
  endInclusive = true
): string[] {
  let dateCursor: DateTime = startDate;

  const dateList: string[] = [];
  while (dateCursor <= endDate || dateCursor.hasSame(endDate, 'day')) {
    if (!endInclusive && dateCursor.hasSame(endDate, 'day')) {
      // Skip this date since it's not inclusive.
      dateCursor = dateCursor.plus({ day: 1 });
      continue;
    }

    dateList.push(dateCursor.toISODate());
    dateCursor = dateCursor.plus({ day: 1 });
  }

  return dateList;
}

export function getMonthsList() {
  const allMonths = Info.months('numeric', currentDate);
  const monthsList = [];

  let i = 0;
  while (parseFloat(allMonths[i]) <= currentMonth) {
    const month = parseFloat(allMonths[i]);
    const monthItem = DateTime.local(currentYear, month).toFormat('MMMM yyyy');
    monthsList.push(monthItem);
    i++;
  }

  return monthsList;
}

// Returns a list of strings representing quarters in the current year, up to the current date
// ex. where now = 5/14/2022 - ["Q1 2022", "Q2 2022"]
export function getQuartersList() {
  return Array.from({ length: currentQuarter }, (_, quarter) => `Q${quarter + 1} ${currentYear}`);
}

// Returns a string representing the range of months for a quarter
// ex. "January - March 2022"
export function getQuarterMonthRange(quarterYear: string) {
  const [quarterPeriod, year] = quarterYear.split(' ');
  const [startMonth, , endMonth] = monthsByQuarter.get(quarterPeriod) ?? [];

  return `${startMonth} - ${endMonth} ${year}`;
}

export function getQuarterMonthRangeFromDate(startYearMonth: string) {
  const start = getDateTime(startYearMonth).startOf('quarter');
  const end = start.endOf('quarter');

  return `${start.toFormat('MMMM')} - ${end.toFormat('MMMM yyyy')}`;
}

export function getReportStartDate(period: string) {
  if (period === 'This Year') {
    return DateTime.local(currentYear);
  } else if (period === 'Last Year') {
    return DateTime.local(currentYear).minus({ years: 1 });
  } else if (period === 'This Month') {
    return DateTime.local(currentYear, currentMonth);
  } else if (period === 'Last Month') {
    return DateTime.local(currentYear, currentMonth).minus({ months: 1 });
  } else if (period === 'This Quarter') {
    return currentDate.startOf('quarter');
  } else if (period === 'Last Quarter') {
    return currentDate.minus({ quarter: 1 }).startOf('quarter');
  } else {
    return DateTime.local(currentYear);
  }
}

export function getReportEndDate(period: string) {
  if (period === 'This Year') {
    return currentDate;
  } else if (period === 'Last Year') {
    return DateTime.local(currentYear, 12, 31).minus({ years: 1 });
  } else if (period === 'This Month') {
    return DateTime.local(currentYear, currentMonth).endOf('month');
  } else if (period === 'Last Month') {
    return DateTime.local(currentYear, currentMonth).minus({ months: 1 }).endOf('month');
  } else if (period === 'This Quarter') {
    return currentDate.endOf('quarter');
  } else if (period === 'Last Quarter') {
    return currentDate.minus({ quarter: 1 }).endOf('quarter');
  } else {
    return DateTime.local(currentYear);
  }
}

function startDateOfQuarter(quarter: number) {
  return DateTime.fromFormat(quarter.toString(), 'q').get('month');
}

function endDateOfQuarter(quarter: number) {
  return DateTime.fromFormat(quarter.toString(), 'q').endOf('quarter');
}

export function validateInputs(
  values: any,
  errors: FormErrors,
  setErrors: React.SetStateAction<any>
) {
  const temp: any = { ...errors };

  if ('email' in values) {
    temp.email = values.email ? '' : 'This field is required.';

    if (values.email)
      temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email) ? '' : 'Email is not valid.';
  }

  if ('password' in values) temp.password = values.password ? '' : 'This field is required.';

  setErrors({
    ...temp,
  });
}
