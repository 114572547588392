import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AccountPage = (): JSX.Element => {
  return (
    <Container>
      <Box sx={{ py: 3 }}>
        <Typography variant='h1'>My Account</Typography>
      </Box>
    </Container>
  );
};

export default AccountPage;
