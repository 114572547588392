import React from 'react';
import { switchProp } from 'styled-tools';
import Alert, { AlertColor } from '@mui/material/Alert';
import { css, styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(
  ({ theme }) => css`
    align-items: center;
    font-size: 0.875rem;
    font-weight: 700;

    ${switchProp('severity', {
      error: css`
        color: ${theme.palette.error.main};

        svg {
          color: ${theme.palette.error.main};
        }
      `,
      warning: css`
        color: ${theme.palette.warning.main};

        svg {
          color: ${theme.palette.warning.main};
        }
      `,
      info: css`
        background-color: ${theme.colors.red};
        color: ${theme.colors.white};

        svg {
          color: ${theme.colors.white};
        }
      `,
    })}
  `
);

interface AlertBarProps {
  message: string;
  type: AlertColor | undefined;
}

type Props = AlertBarProps;

const AlertBar = ({ message, type }: Props): JSX.Element => {
  return <StyledAlert severity={type}>{message}</StyledAlert>;
};

export default AlertBar;
