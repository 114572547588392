import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  Set2FaMethodDocument,
  Set2FaMethodMutation,
  Set2FaMethodMutationVariables,
} from 'generated/graphql';

type Data = Set2FaMethodMutation;
type Variables = Set2FaMethodMutationVariables;

export const useMutationSet2FAMethod = (
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> => {
  return useMutation<Data, Variables>(Set2FaMethodDocument, options);
};

export default useMutationSet2FAMethod;
