import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import LoanActivity from 'components/LoanActivity/LoanActivity';
import UploadedDocuments from 'components/UploadedDocuments/UploadedDocuments';

interface UploadsAndActivityProps {
  loanInfoId: number;
}

const UploadsAndActivity = (props: UploadsAndActivityProps): JSX.Element => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={7} xs={12}>
          {props.loanInfoId && <UploadedDocuments loanInfoId={props.loanInfoId} />}
        </Grid>
        <Grid item md={5} xs={12}>
          <LoanActivity loanInfoId={props.loanInfoId} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UploadsAndActivity;
