import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'components/Header/Header';
import AlertBar from 'components/AlertBar/AlertBar';
import { useUserContext } from 'contexts/UserContext/UserContext';
import useMutationUpdateInvestorDocumentsViewed from 'hooks/mutations/useMutationUpdateInvestorDocumentsViewed/useMutationUpdateInvestorDocumentsViewed';
import useQueryInvestorDocuments from 'hooks/queries/useQueryInvestorDocuments/useQueryInvestorDocuments';

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
}

type Props = LayoutProps;

const Layout = ({ children }: Props): JSX.Element => {
  const params = useParams();
  const [investorIds, setInvestorIds] = useState<number[]>([]);
  const { role, user } = useUserContext();
  const [updateInvestorDocumentsViewed] = useMutationUpdateInvestorDocumentsViewed();
  const [showDocumentAlert, setShowDocumentAlert] = useState<boolean>(false);

  const { data, refetch } = useQueryInvestorDocuments({
    skip: !investorIds || investorIds.length === 0,
    variables: {
      investorIds: investorIds,
    },
  });

  useEffect(() => {
    const investors = user?.investors?.map((investor) => {
      return investor.id;
    });
    if (investorIds.length === 0 && investors && investors?.length > 0) {
      setInvestorIds(investors);
    }

    if (data?.investorDocuments) {
      for (const document of data.investorDocuments) {
        const all = document.allInvestors;
        const investors = document?.investors?.length ?? 0;
        const found =
          document?.investorsViewed?.filter(
            (element) => element?.id && investorIds?.includes(element.id)
          ) ?? [];

        if (all && found.length !== investorIds.length) {
          setShowDocumentAlert(true);
        }

        if (!all && found.length < investors) {
          setShowDocumentAlert(true);
        }
      }
    }
  }, [data?.investorDocuments, investorIds, showDocumentAlert, user?.investors]);

  useEffect(() => {
    const pageParam = params?.id ? parseInt(params.id, 10) : null;
    const idMatch = pageParam && investorIds?.includes(pageParam);
    if (idMatch && showDocumentAlert) {
      updateInvestorDocumentsViewed({
        variables: {
          input: {
            id: pageParam,
          },
        },
        update(cache, result) {
          refetch();
        },
      });
    }
  }, [investorIds, params.id, refetch, showDocumentAlert, user, updateInvestorDocumentsViewed]);

  return (
    <div>
      {showDocumentAlert && (
        <AlertBar message='New Documents are available for viewing.' type='info' />
      )}
      <Header />
      {children}
    </div>
  );
};

export default Layout;
