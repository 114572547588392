import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { css, styled } from '@mui/material/styles';
import BillingForm from 'components/InvestorProfile/BillingForm/BillingForm';
import DetailsForm from 'components/InvestorProfile/DetailsForm/DetailsForm';
import useMutationUpdateBilling from 'hooks/mutations/useMutationUpdateBilling/useMutationUpdateBilling';
import EditDetailsModal from './EditDetailsModal/EditDetailsModal';
import { Exact, GetInvestorByNameQuery, Investor, Maybe } from 'generated/graphql';
import { InitialBankingValues } from './types';
import { ApolloQueryResult } from '@apollo/client/core/types';

const EditButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    font-weight: 600;
    letter-spacing: 0.125rem;
    font-size: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: ${theme.colors.gray7};
    border: 2px solid ${theme.palette.common.white};
    text-transform: uppercase;

    :hover {
      background-color: ${theme.palette.common.white};
      color: ${theme.colors.gray7};
    }
  `
);

interface InvestorProfileProps {
  investor?: Maybe<Investor> | undefined;
  refetchInvestor: (
    variables?: Partial<Exact<{ id?: number }>> | undefined
  ) => Promise<ApolloQueryResult<GetInvestorByNameQuery>>;
}

type Props = InvestorProfileProps;

const InvestorProfile = ({ investor, refetchInvestor }: Props): JSX.Element => {
  const [updateBilling] = useMutationUpdateBilling();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [values, setValues] = useState<InitialBankingValues>({
    bankName: '',
    accountNumber: null,
    routingNumber: null,
  });

  useEffect(() => {
    if (investor) {
      setValues({
        bankName: investor?.bankName,
        accountNumber: investor?.accountNumber,
        routingNumber: investor?.routingNumber,
      });
    }
  }, [investor]);

  const toggleModal = () => {
    setOpen(!open);
  };

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const intCheck = name === 'accountNumber' || name === 'routingNumber';

    setValues({
      ...values,
      [name]: intCheck ? Number(value) : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (investor) {
      const { data } = await updateBilling({
        variables: {
          input: {
            investorId: investor.id,
            accountNumber: values?.accountNumber,
            bankName: values?.bankName,
            routingNumber: values?.routingNumber,
          },
        },
      });

      if (!data?.updateBilling?.success) {
        setError(
          data?.updateBilling?.errorMessage ??
            'There was an issue updating your billing information. Try again later.'
        );
      } else {
        await refetchInvestor();
        toggleModal();
      }
    }
  };

  const detailsTitle: Maybe<string> | undefined = investor?.memberName;

  return (
    <Container>
      <Grid container columnSpacing={12}>
        <DetailsForm investor={investor} title={detailsTitle} />
        <BillingForm investor={investor} />
      </Grid>
      <Grid container alignItems='center' flexDirection='column' sx={{ mt: '3rem' }}>
        <EditButton disableRipple={true} onClick={toggleModal} variant='text'>
          Edit Details
        </EditButton>
      </Grid>
      <EditDetailsModal
        open={open}
        errorMessage={error}
        handleInputValue={handleInputValue}
        toggleModal={toggleModal}
        submitForm={handleSubmit}
        values={values}
      />
    </Container>
  );
};

export default InvestorProfile;
