import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetInvestorByIdDocument,
  GetInvestorByIdQueryVariables,
  GetInvestorByIdQuery,
} from 'generated/graphql';

type Data = GetInvestorByIdQuery;
type Variables = GetInvestorByIdQueryVariables;

function useQueryGetInvestorsById(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(GetInvestorByIdDocument, {
    ...options,
  });
}

export default useQueryGetInvestorsById;
