import React from 'react';
import { ifProp } from 'styled-tools';
import { css, styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)<{ $type?: 'download' }>(
  ({ theme }) => css`
    font-size: 1em;
    color: ${theme.palette.common.white};
    border-color: ${theme.palette.common.black};
    height: 2.7rem;
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    gap: 1.5rem;

    &:hover {
      border-color: ${theme.palette.common.black};
    }

    ${ifProp(
      { $type: 'download' },
      css`
        height: auto;
        border: 0;
        padding: 1.125rem 4rem 1.125rem 1.25rem;
        line-height: 1.5rem;
        background-color: ${theme.colors.red};

        &:hover {
          border: 0;
          background-color: ${theme.colors.gray7};
        }
      }
    `
    )}
  `
);

type ReportButtonProps =
  | {
      buttonText: string;
      onClick?: never;
      type: 'download';
      href: string;
    }
  | {
      buttonText: string;
      onClick?: () => void;
      type?: never;
      href?: never;
    };

type Props = ReportButtonProps;

const ReportButton = ({ buttonText, type, onClick, href }: Props): JSX.Element => {
  return (
    <>
      <StyledButton
        id='export-button'
        variant='outlined'
        size='small'
        disableElevation
        $type={type}
        onClick={onClick}
        disableRipple={type !== 'download'}
        // @ts-expect-error `download` doesn't exist on Button, even though href makes it an anchor element.
        download={type === 'download'}
        target={type === 'download' ? '_blank' : null}
        href={href}
      >
        {type === 'download' && <DownloadIcon fontSize={'small'} sx={{ mr: 1 }} />}
        {buttonText}
      </StyledButton>
    </>
  );
};

export default ReportButton;
