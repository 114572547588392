import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TitledContainer from 'components/TitledContainer/TitledContainer';
import { useQuery } from '@apollo/client';
import {
  GetLoanActivityByIdDocument,
  GetLoanActivityByIdQuery,
  GetLoanActivityByIdQueryVariables,
} from 'generated/graphql';
import { MILLENNIUM_USER } from 'utils/constants';
import AddLoanNote from 'components/AddLoanNote/AddLoanNote';
import { useUserContext } from 'contexts/UserContext/UserContext';

const ActivityList = styled('div')`
  height: calc(31.25rem - 11.5rem);
  overflow-y: scroll;
  margin-bottom: 0.75rem;
`;

const ActivityItem = styled(Typography)`
  margin-bottom: 0.5rem;
`;

interface Activity {
  id: string;
  text: string;
  amount?: string;
  date: string;
  timestamp: number;
}

interface LoanActivityProps {
  loanInfoId: number;
}

const LoanActivity = (props: LoanActivityProps) => {
  const { role } = useUserContext();
  const { data: loanData, refetch } = useQuery<
    GetLoanActivityByIdQuery,
    GetLoanActivityByIdQueryVariables
  >(GetLoanActivityByIdDocument, {
    variables: { id: props.loanInfoId },
  });

  const activities = (loanData?.loanActivity?.activity ?? []) as Activity[];

  return (
    <TitledContainer title='Activity'>
      <ActivityList>
        {activities.map(({ id, amount, date, text }) => (
          <ActivityItem key={id}>
            {text} {`${amount ? `${amount} ` : ''}`}
            {date}
          </ActivityItem>
        ))}
      </ActivityList>
      {/* We add a `key` here so that we reset the form when the client/loan changes */}
      {role === MILLENNIUM_USER && (
        <AddLoanNote key={`loan-${props.loanInfoId}`} loanId={props.loanInfoId} onAdd={refetch} />
      )}
    </TitledContainer>
  );
};

export default LoanActivity;
