import React, { useState } from 'react';
import { css, styled } from '@mui/material/styles';
import { Button, FormControl, InputBase } from '@mui/material';
import useMutationAddLoanNote from 'hooks/mutations/useMutationAddLoanNote/useMutationAddLoanNote';
import { useUserContext } from 'contexts/UserContext/UserContext';

const Form = styled('form')`
  max-width: 100%;
`;

const FormGroup = styled(FormControl)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const Input = styled(InputBase)<{ error: boolean }>(
  ({ theme }) => css`
    background-color: white;
    border: 1px solid ${theme.colors.gray7};
    border-radius: 0.1875rem;

    .MuiInputBase-input {
      padding: 0.625rem 0.75rem;
      position: relative;
    }
  `
);

const SubmitButton = styled(Button)(
  ({ theme }) => css`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: ${theme.colors.red};
    border-color: ${theme.colors.red};
    text-transform: uppercase;
  `
);

const ErrorMessage = styled('p')(
  ({ theme }) => css`
    margin-top: 0.35rem;
    color: ${theme.colors.red};
    font-size: 0.9rem;
  `
);

interface AddLoanNoteProps {
  loanId: number;
  onAdd: () => void;
}

const AddLoanNote = (props: AddLoanNoteProps) => {
  const [note, setNote] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [addLoanNote] = useMutationAddLoanNote();
  const { user } = useUserContext();

  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setNote(value);
  };

  const resetForm = () => {
    setError('');
    setNote('');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const trimmed = note.trim();

    // If the note is empty / all whitespace, just return.
    if (!trimmed) {
      return;
    }

    // This shouldn't happen because of the input maxlength,
    // but it's easy enough to get around that.
    if (trimmed.length > 255) {
      setError('Note must be less than 256 characters.');
      return;
    }

    const { data } = await addLoanNote({
      variables: {
        loanId: props.loanId,
        note: trimmed,
        userId: user?.id,
      },
    });

    if (!data?.addLoanNote?.success) {
      setError(
        data?.addLoanNote?.errorMessage ?? 'There was an issue saving your note. Try again later.'
      );
    } else {
      resetForm();
      props.onAdd();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          fullWidth
          id='new-note'
          name='new-note'
          type='text'
          placeholder='New note'
          error={!!error}
          inputProps={{ maxLength: 255 }}
          value={note}
          onChange={handleInputEvent}
        />
        <SubmitButton color='primary' type='submit' variant='contained'>
          Submit
        </SubmitButton>
      </FormGroup>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Form>
  );
};

export default AddLoanNote;
