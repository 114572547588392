import React, { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import MapBlock from 'components/MapBlock/MapBlock';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LoanSummaryTab } from 'components/LoanSummary/LoanSummaryTabNav/types';
import InterestSummary from 'components/LoanSummary/InterestSummary/InterestSummary';
import InvestorMonthlySummary from 'components/LoanSummary/InvestorMonthlySummary/InvestorMonthlySummary';
import useQueryInvestorSummary from 'hooks/queries/useQueryInvestorSummary/useQueryInvestorSummary';
import useQueryPortfolioSummary from 'hooks/queries/useQueryPortfolioSummary/useQueryPortfolioSummary';
import InvestorExpandableList from 'components/InvestorExpandableList/InvestorExpandableList';
import LoanExpandableList from 'components/LoanExpandableList/LoanExpandableList';
import TabPanel from 'components/LoanSummary/LoanSummaryTabNav/TabPanel';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { getDateTime } from 'utils/dates';
import Layout from 'components/Layout/Layout';
import { HARBORLINK_CLIENT_ID, MILLENNIUM_USER } from 'utils/constants';
import { DateRangeVariant } from 'generated/graphql';

const TabContentWrapper = styled('div')(
  ({ theme }) => css`
    margin-top: -0.3rem;
    border-top: 0.25rem solid ${theme.colors.mifGray14};
    background: #f7f7f7;
  `
);

const HeaderBox = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding: 1.5rem;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
);

const HeaderContainer = styled(Container)(
  ({ theme }) => css`
    background-color: ${theme.colors.gray7};
    color: white;
    text-transform: uppercase;
    max-width: 100%;
    padding: 2rem 4rem 0;

    h2 {
      font-weight: 700;
      line-height: 144%;
      letter-spacing: 0.125rem;
    }
  `
);

const StyledTab = styled(Tab)`
  padding: 0.75rem 2.5rem;
  margin-right: 2.5rem;
`;

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    padding: 0.25rem 2.5rem;
    color: white;
    font-size: 1.25rem;
    font-family: 'Teko';
    font-weight: 600;
    letter-spacing: 0.1875rem;
    text-transform: uppercase;
    border: none;
    border-bottom: 0.25rem solid ${theme.colors.red} !important;

    svg {
      fill: white;
      right: 2rem;
    }
  `
);

function a11yProps(index: LoanSummaryTab) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface LoanSummaryProps {
  tab?: LoanSummaryTab;
}

type Props = LoanSummaryProps;

const LoanSummary = ({ tab }: Props): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { role, user } = useUserContext();
  const currentTab = tab || LoanSummaryTab.Monthly;
  const [value, setValue] = React.useState(currentTab);
  const [currentDate, setCurrentStartDate] = useState<string>(() =>
    DateTime.now().toFormat('yyyy-MM')
  );
  const [currentEndDate, setCurrentEndDate] = useState<string>(() =>
    DateTime.now().endOf('month').toISODate()
  );

  const setCurrentDate = useCallback(
    (newValue: string, endValue?: string) => {
      setCurrentStartDate(newValue);
      setCurrentEndDate(
        endValue ||
          getDateTime(newValue)
            .endOf(value === LoanSummaryTab.Quarterly ? 'quarter' : 'month')
            .toISODate()
      );
    },
    [value]
  );

  const handleTabChange = async (_event: React.SyntheticEvent, newValue: LoanSummaryTab) => {
    setValue(newValue);

    if (newValue === LoanSummaryTab.Quarterly) {
      setCurrentDate(
        getDateTime(currentDate).startOf('quarter').toFormat('yyyy-MM'),
        getDateTime(currentDate).endOf('quarter').toISODate()
      );
    } else {
      const endOfQuarter = getDateTime(currentDate).endOf('quarter');
      const now = DateTime.now();

      let newCurrentDateTime: DateTime;
      if (endOfQuarter > now && !endOfQuarter.hasSame(now, 'month')) {
        newCurrentDateTime = DateTime.now();
      } else {
        newCurrentDateTime = endOfQuarter;
      }

      setCurrentDate(
        newCurrentDateTime.toFormat('yyyy-MM'),
        newCurrentDateTime.endOf('month').toISODate()
      );
    }
  };

  const { data: investorSummaryData } = useQueryInvestorSummary({
    variables: {
      input: {
        yearMonth: currentDate,
        endDate: currentEndDate,
        variant:
          value === LoanSummaryTab.Quarterly ? DateRangeVariant.Quarter : DateRangeVariant.Month,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: portfolioSummaryData } = useQueryPortfolioSummary({
    variables: {
      input: {
        yearMonth: currentDate,
        endDate: currentEndDate,
        variant:
          value === LoanSummaryTab.Quarterly ? DateRangeVariant.Quarter : DateRangeVariant.Month,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const loansData = useMemo(() => {
    const isGP = user?.investors?.reduce((acc: boolean, cur) => cur.isGP || acc, false) ?? false;

    if (isGP) {
      return portfolioSummaryData?.portfolioSummary?.loansData;
    } else if (portfolioSummaryData?.portfolioSummary?.loansData) {
      const loans = portfolioSummaryData.portfolioSummary.loansData.loans ?? [];
      return {
        ...portfolioSummaryData.portfolioSummary.loansData,
        loans: loans.filter((loan) => loan.loanInfo.clientId !== HARBORLINK_CLIENT_ID),
      };
    }
  }, [portfolioSummaryData?.portfolioSummary?.loansData, user?.investors]);

  return (
    <Layout>
      <HeaderContainer>
        <HeaderBox>
          <Typography variant='h1'>Loan Summary</Typography>
        </HeaderBox>
        <Box sx={{ width: '100%', mx: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {!isMobile && (
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label='Portfolio Summary Tabs'
                variant='scrollable'
              >
                <StyledTab
                  label='Monthly'
                  value={LoanSummaryTab.Monthly}
                  {...a11yProps(LoanSummaryTab.Monthly)}
                />
                <StyledTab
                  label='Quarterly'
                  value={LoanSummaryTab.Quarterly}
                  {...a11yProps(LoanSummaryTab.Quarterly)}
                />
              </Tabs>
            )}
            {isMobile && (
              <StyledSelect
                value={value}
                variant='standard'
                onChange={(event) =>
                  handleTabChange(
                    event as unknown as React.SyntheticEvent,
                    event.target.value as LoanSummaryTab
                  )
                }
              >
                <MenuItem value={LoanSummaryTab.Monthly}>Monthly</MenuItem>
                <MenuItem value={LoanSummaryTab.Quarterly}>Quarterly</MenuItem>
              </StyledSelect>
            )}
          </Box>
        </Box>
      </HeaderContainer>
      <TabContentWrapper>
        <TabPanel value={value} index={LoanSummaryTab.Monthly}>
          {role === MILLENNIUM_USER ? (
            <InterestSummary
              variant='month'
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              data={portfolioSummaryData?.portfolioSummary?.monthlySummary}
            />
          ) : (
            <InvestorMonthlySummary
              variant='month'
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              data={investorSummaryData?.investorSummary}
              portfolioSummary={portfolioSummaryData?.portfolioSummary}
            />
          )}
          {/*<MapBlock items={portfolioSummaryData?.portfolioSummary?.loansData.loans} />*/}
          <LoanExpandableList currentDate={currentDate} allLoansData={loansData} variant='month' />
          {role === MILLENNIUM_USER && (
            <InvestorExpandableList
              currentDate={currentDate}
              // @ts-ignore
              allInvestorsData={investorSummaryData?.investorSummary?.investorsData}
              variant='month'
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={LoanSummaryTab.Quarterly}>
          {role === MILLENNIUM_USER ? (
            <InterestSummary
              variant='quarter'
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              data={portfolioSummaryData?.portfolioSummary?.monthlySummary}
            />
          ) : (
            <InvestorMonthlySummary
              variant='quarter'
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              data={investorSummaryData?.investorSummary}
              portfolioSummary={portfolioSummaryData?.portfolioSummary}
            />
          )}
          {/*<MapBlock items={portfolioSummaryData?.portfolioSummary?.loansData.loans} />*/}
          <LoanExpandableList
            currentDate={currentDate}
            allLoansData={loansData}
            variant='quarter'
          />
          {role === MILLENNIUM_USER && (
            <InvestorExpandableList
              currentDate={currentDate}
              // @ts-ignore
              allInvestorsData={investorSummaryData?.investorSummary?.investorsData}
              variant='quarter'
            />
          )}
        </TabPanel>
      </TabContentWrapper>
    </Layout>
  );
};

export default LoanSummary;
