export const investorsHeaders = ['Date', 'Capital', 'Interest'];

export const totalHeaders = ['Date', 'investor total', 'LP Investment', 'Interest Earned'];

export const investorsInfoTotal = {
  'Investor Total': 2065000,
  'LP Investment': 764049.44,
  'Interest Earned': 580.22,
};

export const investorsData = [
  {
    name: 'James Kyle',
    isGP: true,
    capital: 0, // GP capital is calculated based on total deployed - capital of others.
    'Interest Earned': 12060.91,
  },
  {
    name: 'Dave Pampush',
    capital: 50000,
    'Interest Earned': 440.3,
  },
  {
    name: 'Jim Kyle',
    capital: 50000,
    'Interest Earned': 448.35,
  },
  {
    name: 'Kyle Holdings',
    capital: 50000,
    'Interest Earned': 471.65,
  },
  {
    name: 'Ken Trent',
    capital: 50000,
    'Interest Earned': 3044.3,
  },
  {
    name: 'Charles Heekin',
    capital: 50000,
    'Interest Earned': 2171.15,
  },
  {
    name: 'Elisa Spain',
    capital: 50000,
    'Interest Earned': 618.24,
  },
];

export const reportPeriodOptions = [
  {
    label: 'This Year',
    value: 'This Year',
  },
  {
    label: 'Last Year',
    value: 'Last Year',
  },
  {
    label: 'This Month',
    value: 'This Month',
  },
  {
    label: 'Last Month',
    value: 'Last Month',
  },
  {
    label: 'This Quarter',
    value: 'This Quarter',
  },
  {
    label: 'Last Quarter',
    value: 'Last Quarter',
  },
  {
    label: 'Custom',
    value: 'Custom',
  },
];

export const staticReportDetails = {
  summaryData: {
    committedCapital: 750000.0,
    blendedInterestRate: 0.1225,
    returnAmount: 21001.0,
  },
  capitalData: {
    openingAmount: 772284.55,
    contributions: 0,
    incomeAllocation: 21530.23,
    distributions: 0,
    closingAmount: 793814.78,
  },
};
