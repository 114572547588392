import React from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Investor, Maybe } from 'generated/graphql';
import useQueryInvestorDocuments from 'hooks/queries/useQueryInvestorDocuments/useQueryInvestorDocuments';
import TitledContainer from 'components/TitledContainer/TitledContainer';
import UploadedDoc from 'components/UploadedDocuments/UploadedDoc';

const DocumentsContainer = styled(Container)`
  margin-bottom: 2rem;
  max-width: 1152px;
`;

interface InvestorProfileProps {
  investor: Maybe<Investor> | undefined;
}

type Props = InvestorProfileProps;

const InvestorDocuments = ({ investor }: Props): JSX.Element => {
  const { data } = useQueryInvestorDocuments({
    skip: !investor,
    variables: {
      investorIds: investor?.id ? [investor?.id] : [],
    },
  });

  return (
    <DocumentsContainer>
      <TitledContainer borderless={true} title='Documents'>
        {data?.investorDocuments?.map((document) => {
          const fullS3Url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${document.documentS3Url}`;

          return (
            <UploadedDoc
              key={document.id}
              filename={document.documentName}
              uploadDate={document.documentDate}
              downloadUrl={fullS3Url}
            />
          );
        })}
      </TitledContainer>
    </DocumentsContainer>
  );
};

export default InvestorDocuments;
