import React, { PropsWithChildren, useMemo } from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import useAppContext from 'contexts/AppContext/AppContext';

const AuthorizedApolloProvider = ({ children }: PropsWithChildren) => {
  const { token } = useAppContext();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_API,
  });

  const authLink = useMemo(() => {
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
  }, [token]);

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
