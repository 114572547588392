import React, { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { css, styled } from '@mui/material/styles';
import ClientSelect from 'components/ClientSelect/ClientSelect';
import SummaryLoanDetails from 'components/LoanDetails/SummaryLoanDetails';
import UploadsAndActivity from 'components/UploadsAndActivity/UploadsAndActivity';
import InterestAccrualBlock from 'components/InterestAccrualBlock/InterestAccrualBlock';
import useQueryPortfolioSummary from 'hooks/queries/useQueryPortfolioSummary/useQueryPortfolioSummary';
import { getDatesListForVariant, getDetails } from 'utils/functions';
import { DateRangeVariant } from 'generated/graphql';
import Layout from 'components/Layout/Layout';
import { getDateTime } from 'utils/dates';

const HighlightSection = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.gray7};
    padding: 0 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10rem;

    ${theme.breakpoints.down(1100)} {
      padding: 0 5rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .MuiSelect-select {
        padding-left: 0 !important;
      }
    }

    ${theme.breakpoints.down('sm')} {
      padding: 0 2.5rem;
    }
  `
);

const MainSection = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.gray0_5};
    min-height: 25rem;
    padding: 3.75rem 0;
  `
);

const SelectContainer = styled('div')(
  ({ theme }) => css`
    border-right: 1px solid ${theme.colors.mifGray7};
    padding-right: 5rem;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 5rem;

    ${theme.breakpoints.down(1100)} {
      border-right: none;
      width: 100%;
      height: 50%;
    }
  `
);

const LoanDetails = (): JSX.Element => {
  const params = useParams();
  const { id = '0' } = params;
  const loanId = parseInt(id, 10);

  const [currentDate, setCurrentStartDate] = useState<string>(() =>
    DateTime.now().toFormat('yyyy-MM')
  );
  const [currentEndDate, setCurrentEndDate] = useState<string>(() =>
    DateTime.now().endOf('month').toISODate()
  );

  const setCurrentDate = useCallback((newValue: string, endValue?: string) => {
    setCurrentStartDate(newValue);
    setCurrentEndDate(endValue || getDateTime(newValue).endOf('month').toISODate());
  }, []);

  const { data: loansQueryData } = useQueryPortfolioSummary({
    variables: {
      input: {
        yearMonth: currentDate,
        endDate: currentEndDate,
        loanId: loanId,
        variant: DateRangeVariant.Month,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: allLoansQueryData } = useQueryPortfolioSummary({
    variables: {
      input: {
        yearMonth: currentDate,
        endDate: currentEndDate,
        variant: DateRangeVariant.Month,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const clientsList = allLoansQueryData?.portfolioSummary?.loansData?.loans?.map((loan) => {
    return loan?.loanInfo;
  });

  const datesList = useMemo(
    () => getDatesListForVariant(currentDate, DateRangeVariant.Month),
    [currentDate]
  );

  const details = useMemo<{ deployed: number; interest: number }[][]>(
    () => getDetails(loansQueryData?.portfolioSummary?.loansData, datesList),
    [loansQueryData?.portfolioSummary?.loansData, datesList]
  );

  const loanInfo = loansQueryData?.portfolioSummary?.loansData.loans[0].loanInfo;
  const currentInfo = loansQueryData?.portfolioSummary?.monthlySummary;

  const summaryLoanDetails = {
    initialRate: loanInfo?.interestRate,
    currentCommitted: currentInfo?.capitalData.capitalCommitment,
    currentDeployed: currentInfo?.capitalData.deployedCapital,
    currentRate: currentInfo?.interestData.blendedInterestRate,
  };

  return (
    <Layout>
      <HighlightSection>
        <SelectContainer>{<ClientSelect items={clientsList} selectValue={id} />}</SelectContainer>
      </HighlightSection>
      <MainSection>
        <Container>
          <SummaryLoanDetails summaryLoanDetails={summaryLoanDetails} />
        </Container>
        <Container>
          <InterestAccrualBlock
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            details={details[0]}
          />
        </Container>
        <Container>
          <UploadsAndActivity loanInfoId={loanId} />
        </Container>
      </MainSection>
    </Layout>
  );
};

export default LoanDetails;
