import React, { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMutationValidateUser from 'hooks/mutations/useMutationValidateUser/useMutationValidateUser';
import useMutationValidate2FACode from 'hooks/mutations/useMutationValidate2FACode/useMutationValidate2FACode';
import useMutationInitiateVerifyEmail from 'hooks/mutations/useMutationInitiateVerifyEmail/useMutationInitiateVerifyEmail';
import useMutationInitiateVerifyPhone from 'hooks/mutations/useMutationInitiateVerifyPhone/useMutationInitiateVerifyPhone';
import useMutationVerifyEmail from 'hooks/mutations/useMutationVerifyEmail/useMutationVerifyEmail';
import useMutationVerifyPhone from 'hooks/mutations/useMutationVerifyPhone/useMutationVerifyPhone';
import useMutationSet2FAMethod from 'hooks/mutations/useMutationSet2FAMethod/useMutationSet2FAMethod';
import { FormErrors, InitialFormValues, LoginState, MfaValues } from 'types';
import useAppContext from 'contexts/AppContext/AppContext';

const initialFormValues: InitialFormValues = {
  email: '',
  password: '',
  showPassword: false,
  errorMessage: null,
  success: false,
};

const initialMfaValue = {
  method: '',
  email: '',
  emailVerified: false,
  phone: '',
  phoneVerified: false,
  sid: '',
};

export interface UseLoginState {
  loginState: LoginState;

  //email/pw flow:
  handleFormSubmit: (event: React.FormEvent) => void;
  setErrors: React.Dispatch<SetStateAction<FormErrors>>;
  setValues: React.Dispatch<SetStateAction<InitialFormValues>>;
  errors: FormErrors;
  values: InitialFormValues; //email/pw control contents

  //2fa auth flow
  validate2FACode: (code: string) => void;
  mfaValue: MfaValues;
  mfaError: string | null;

  //2fa enrollment
  initiateValidateEmail: () => void;
  validateEmail: (email: string, code: string) => void;
  initiateValidatePhone: (phone: string) => void;
  validatePhone: (phone: string, code: string) => void;
  setMfaMethod: (method: string, save: boolean) => Promise<boolean>;
}

export const useLoginState = (): UseLoginState => {
  const navigate = useNavigate();

  const { setToken } = useAppContext();

  const [validateUser] = useMutationValidateUser();
  const [validateCode] = useMutationValidate2FACode();
  const [initiateVerifyEmail] = useMutationInitiateVerifyEmail();
  const [initiateVerifyPhone] = useMutationInitiateVerifyPhone();
  const [verifyEmail] = useMutationVerifyEmail();
  const [verifyPhone] = useMutationVerifyPhone();
  const [set2FAMethod] = useMutationSet2FAMethod();

  const [loginState, setLoginState] = useState<LoginState>(LoginState.Password);
  const [mfaValue, setMfaValue] = useState<MfaValues>(initialMfaValue);
  const [mfaSid, setMfaSid] = useState<string>('');
  const [interimToken, setInterimToken] = useState<string | null>(null);
  const [mfaError, setMfaError] = useState<string | null>(null);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as FormErrors);

  const loginAttempt = () => {
    validateUser({
      variables: {
        email: values?.email,
        password: values?.password,
      },
    }).then((data) => {
      const dataResponse = data?.data?.validateUser;
      setValues({
        ...values,
        errorMessage: dataResponse?.errorMessage,
        success: dataResponse?.success,
      });

      setInterimToken(dataResponse?.token ?? null);
      setMfaSid(dataResponse?.mfaSid ?? '');

      const newMfaValue: MfaValues = {
        method: dataResponse?.mfaMethod || 'none',
        email: values?.email,
        emailVerified: dataResponse?.emailVerified ?? false,
        phoneVerified: dataResponse?.phoneVerified ?? false,
        phone: dataResponse?.phoneNumber ?? undefined,
        sid: dataResponse?.mfaSid || '',
      };

      //unless enrollment is disabled by ENV var, this will never be sufficient auth;
      // we will either need to go through
      //the 2FA flow to validate a real code,
      //or we need to force the user through the 2FA enrollment flow.
      if (
        dataResponse?.success &&
        'none' === dataResponse?.mfaMethod &&
        'true' === process.env.REACT_APP_DISABLE_2FA_ENROLLMENT
      ) {
        setToken(dataResponse?.token || null);
        setLoginState(LoginState.Complete);
        navigate('/');
      } else if (
        dataResponse?.success &&
        dataResponse?.mfaMethod &&
        'none' !== dataResponse.mfaMethod
      ) {
        setLoginState(LoginState.MFA);
        setMfaValue(newMfaValue);
      } else if (dataResponse?.success) {
        setLoginState(LoginState.EnrollMFA);
        setMfaValue(newMfaValue);
      }
    });
  };

  const setMfaMethod = useCallback(
    async (method: string, save = true): Promise<boolean> => {
      if (!save) {
        setMfaValue({ ...mfaValue, method });
        return true;
      }
      const { data } = await set2FAMethod({ variables: { token: interimToken, method } });
      if (data?.set2FAMethod?.success) {
        const { emailVerified, phoneVerified } = data.set2FAMethod;
        setMfaValue({
          ...mfaValue,
          method,
          emailVerified: emailVerified ?? false,
          phoneVerified: phoneVerified ?? false,
        });
        setToken(data.set2FAMethod.token || null);
        return true;
      }
      return false;
    },
    [interimToken, mfaValue, set2FAMethod, setToken]
  );

  const validate2FACode = useCallback(
    async (code: string) => {
      validateCode({ variables: { code, sid: mfaSid, token: interimToken } }).then(
        ({ data, errors }) => {
          if (data?.validate2FACode?.success) {
            setLoginState(LoginState.Complete);
            const dataResponse = data?.validate2FACode ?? {};
            const token: string = dataResponse.token || '';
            setToken(token || null);
            navigate('/');
          } else {
            setMfaError(data?.validate2FACode?.errorMessage ?? null);
          }
        }
      );
    },
    [interimToken, mfaSid, navigate, setToken, validateCode]
  );

  const initiateValidateEmail = useCallback(async () => {
    const { data } = await initiateVerifyEmail({ variables: { token: interimToken } });
    setMfaValue({ ...mfaValue, sid: data?.initiateVerifyEmail || '' });
  }, [initiateVerifyEmail, interimToken, mfaValue]);

  const validateEmail = useCallback(
    (email: string, code: string) => {
      verifyEmail({ variables: { token: interimToken, sid: mfaValue.sid, code } }).then(
        (result) => {
          if (result?.data?.verifyEmail?.success) {
            setMfaValue({ ...mfaValue, emailVerified: true });
          }
        }
      );
    },
    [interimToken, mfaValue, verifyEmail]
  );

  const initiateValidatePhone = useCallback(
    async (phone: string) => {
      const { data } = await initiateVerifyPhone({ variables: { token: interimToken, phone } });
      setMfaValue({ ...mfaValue, phone, sid: data?.initiateVerifyPhone || '' });
    },
    [initiateVerifyPhone, interimToken, mfaValue]
  );

  const validatePhone = useCallback(
    (phone: string, code: string) => {
      verifyPhone({ variables: { token: interimToken, sid: mfaValue.sid, code } }).then(
        (result) => {
          if (result?.data?.verifyPhone?.success) {
            setMfaMethod('phone', true);
          } else {
            console.log('verify error');
          }
        }
      );
    },
    [interimToken, mfaValue, setMfaMethod, verifyPhone]
  );

  const handleFormError = () => {
    setValues({
      ...values,
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = values.email && values.password && Object.values(errors).every((x) => x === '');

    if (isValid) {
      loginAttempt();
    } else {
      handleFormError();
    }
  };

  return {
    loginState,

    handleFormSubmit,
    setErrors,
    setValues,
    errors,
    values,

    validate2FACode,
    mfaValue,
    mfaError,

    initiateValidateEmail,
    validateEmail,
    initiateValidatePhone,
    validatePhone,
    setMfaMethod,
  };
};
