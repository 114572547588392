import { styled } from '@mui/material/styles';
import FormLabel from '@mui/material/FormLabel';

const LoginFormLabel = styled(FormLabel)`
  font-family: 'Teko';
  color: ${({ theme }) => theme.colors.fadedBlack};
  font-size: 0.9375rem;
  line-height: 200%;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  text-align: left;
  align-self: flex-start;
`;

export default LoginFormLabel;
