import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  InitiateVerifyPhoneDocument,
  InitiateVerifyPhoneMutation,
  InitiateVerifyPhoneMutationVariables,
} from 'generated/graphql';

type Data = InitiateVerifyPhoneMutation;
type Variables = InitiateVerifyPhoneMutationVariables;

export const useMutationInitiateVerifyPhone = (
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> => {
  return useMutation<Data, Variables>(InitiateVerifyPhoneDocument, options);
};

export default useMutationInitiateVerifyPhone;
