import React from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { validateInputs } from 'utils/functions';
import { FormErrors, InitialFormValues } from 'types';
import Form from 'components/LoginForm/Controls/Form';
import FormGroup from 'components/LoginForm/Controls/FormGroup';
import LoginFormLabel from 'components/LoginForm/Controls/LoginFormLabel';
import Input from 'components/LoginForm/Controls/Input';
import LoginButton from 'components/LoginForm/Controls/LoginButton';
import ErrorText from 'components/LoginForm/Controls/ErrorText';

interface LoginFormProps {
  errors: FormErrors;
  handleFormSubmit: (event: React.FormEvent) => void;
  setErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
  setValues: React.Dispatch<React.SetStateAction<InitialFormValues>>;
  values: InitialFormValues;
}

type Props = LoginFormProps;

const LoginForm = ({
  errors,
  handleFormSubmit,
  setErrors,
  setValues,
  values,
}: Props): JSX.Element => {
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateInputValue = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    validateInputs({ [name]: value }, errors, setErrors);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup>
        <LoginFormLabel htmlFor='email'>Email</LoginFormLabel>
        <Input
          fullWidth
          id='email'
          name='email'
          type='email'
          placeholder='Email'
          error={!!errors.email}
          value={values.email}
          onChange={handleInputValue}
          onBlur={validateInputValue}
        />
        {errors?.email && <ErrorText>{errors.email}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <LoginFormLabel htmlFor='password'>Password</LoginFormLabel>
        <Input
          fullWidth
          id='password'
          name='password'
          type={values.showPassword ? 'text' : 'password'}
          placeholder='Password'
          error={!!errors.password}
          value={values.password}
          onChange={handleInputValue}
          onBlur={validateInputValue}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors?.password && <ErrorText>{errors.password}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <LoginButton variant='contained' color='primary' type='submit'>
          Login
        </LoginButton>
      </FormGroup>
    </Form>
  );
};

export default LoginForm;
