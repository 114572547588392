import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OverviewCard from 'components/LoanSummary/OverviewCard/OverviewCard';
import { getQuarterMonthRangeFromDate } from 'utils/functions';
import { MonthlySummaryReport } from 'types';
import { DateRangeVariant, getDateTime, getShortDateByVariant } from 'utils/dates';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';

interface InterestSummaryProps {
  variant: DateRangeVariant;
  data: MonthlySummaryReport | undefined;
  currentDate: string;
  setCurrentDate: (newValue: string, endValue: string) => void;
}

type Props = InterestSummaryProps;

const InterestSummary = ({ data, variant, currentDate, setCurrentDate }: Props): JSX.Element => {
  const timePeriod = useMemo(
    () => getShortDateByVariant(getDateTime(currentDate), variant),
    [currentDate, variant]
  );

  const displayDateSubheading =
    variant === 'quarter' ? getQuarterMonthRangeFromDate(currentDate) : undefined;

  return (
    <Container sx={{ mb: 8 }}>
      <DateRangeSelector
        variant={variant}
        value={currentDate}
        onChange={setCurrentDate}
        displayDateSubheading={displayDateSubheading}
      />
      <Grid sx={{ marginTop: '1rem' }} container spacing={0}>
        <Grid item xs={12} md={7}>
          <OverviewCard reportData={data?.interestData} timePeriod={timePeriod} />
        </Grid>
        <Grid item xs={12} md={5}>
          <OverviewCard divider reportData={data?.capitalData} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default InterestSummary;
