import React from 'react';
import { DateTime } from 'luxon';
import { css, styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormSelect from 'components/FormSelect/FormSelect';
import DatePicker from 'components/DatePicker/DatePicker';
import { ApolloQueryResult } from '@apollo/client/core/types';
import ReportButton from 'components/InvestorReports/ReportButton/ReportButton';
import { reportPeriodOptions } from 'utils/MockData/investors';
import { getReportStartDate, getReportEndDate } from 'utils/functions';
import {
  Exact,
  InvestorSummaryInput,
  InvestorSummaryQuery,
  PortfolioSummaryInput,
  PortfolioSummaryQuery,
} from 'generated/graphql';

const ReportsContainer = styled('div')(
  ({ theme }) => css`
    margin-top: -5rem;
    padding: 1.5rem 3.125rem 3.125rem;
    background-color: ${theme.palette.common.black};
    color: ${theme.palette.common.white};
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.down('md')} {
      align-items: flex-start;
      flex-direction: column;
    }
  `
);

const RunReportContainer = styled('div')(
  ({ theme }) => css`
    align-items: flex-end;
    display: flex;

    ${theme.breakpoints.down('md')} {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 1rem;
    }
  `
);

const ReportPeriodContainer = styled('div')(
  ({ theme }) => css`
    margin-right: 1.25rem;

    ${theme.breakpoints.down('md')} {
      margin-bottom: 1rem;
    }
  `
);

const DatePickerContainer = styled('div')(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    margin-right: 1.25rem;

    ${theme.breakpoints.down('md')} {
      margin-bottom: 1rem;
    }

    span {
      font-size: 0.8125rem;
    }

    .MuiFormControl-root {
      &:first-child {
        margin-right: 1rem;
      }

      &:last-child {
        margin-left: 1rem;
      }
    }
  `
);

const ReportPeriodHeading = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 1rem;
  letter-spacing: 0.125rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.875rem;
`;

const ToText = styled(Typography)`
  font-weight: 700;
  font-size: 0.8125rem;
`;

interface InvestorReportsProps {
  reportPeriod: string;
  setReportPeriod: React.Dispatch<React.SetStateAction<string>>;
  startDate: DateTime;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime>>;
  endDate: DateTime;
  setEndDate: React.Dispatch<React.SetStateAction<DateTime>>;
  refetchPortfolio: (
    variables?: Partial<Exact<{ input: PortfolioSummaryInput }>> | undefined
  ) => Promise<ApolloQueryResult<PortfolioSummaryQuery>>;
  refetchSummary: (
    variables?: Partial<Exact<{ input: InvestorSummaryInput }>> | undefined
  ) => Promise<ApolloQueryResult<InvestorSummaryQuery>>;
}

type Props = InvestorReportsProps;

const InvestorReports = ({
  reportPeriod,
  setReportPeriod,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  refetchPortfolio,
  refetchSummary,
}: Props): JSX.Element => {
  const handleStartDateChange = (date: DateTime | unknown) => {
    setStartDate(date as DateTime);
  };

  const handleEndDateChange = (date: DateTime | unknown) => {
    setEndDate(date as DateTime);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setReportPeriod(event.target.value as string);
    handleStartDateChange(getReportStartDate(event.target.value as string));
    handleEndDateChange(getReportEndDate(event.target.value as string));
  };

  const handleClick = () => {
    refetchSummary({
      input: {
        yearMonth: startDate.toFormat('yyyy-MM'),
        endDate: endDate.toISODate(),
      },
    });
    refetchPortfolio({
      input: {
        yearMonth: startDate.toFormat('yyyy-MM'),
        endDate: endDate.toISODate(),
      },
    });
  };

  return (
    <ReportsContainer>
      <RunReportContainer>
        <ReportPeriodContainer>
          <ReportPeriodHeading variant='h4'>Report Period</ReportPeriodHeading>
          <FormSelect
            handleChange={handleChange}
            id='report-period'
            menuItems={reportPeriodOptions}
            value={reportPeriod}
          />
        </ReportPeriodContainer>
        <DatePickerContainer>
          <DatePicker
            handleChange={handleStartDateChange}
            reportPeriod={reportPeriod}
            value={startDate}
          />
          <ToText>to</ToText>
          <DatePicker
            handleChange={handleEndDateChange}
            reportPeriod={reportPeriod}
            value={endDate}
          />
        </DatePickerContainer>
      </RunReportContainer>
      <ReportButton buttonText='Run Report' onClick={handleClick} />
    </ReportsContainer>
  );
};

export default InvestorReports;
