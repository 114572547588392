import React from 'react';
import { Link, To } from 'react-router-dom';
import { styled, css } from '@mui/material/styles';

const StyledLink = styled(Link)(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.colors.mifBlue1};
    font-family: 'Teko', 'sans-serif';
    font-size: 1.125rem;
    font-weight: 600;
    margin: 1rem 0;
    padding: 0.75em;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:active {
      color: ${theme.colors.mifBlue1};
    }
  `
);

interface NavigationLinkProps {
  linkTo: To;
  linkText: String;
}

type Props = NavigationLinkProps;

const NavigationLink = ({ linkTo, linkText }: Props) => {
  return <StyledLink to={linkTo}>{linkText}</StyledLink>;
};

export default NavigationLink;
