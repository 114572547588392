import React from 'react';
import Select from '@mui/material/Select';
import { css, styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MenuItemType } from 'types';

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    min-width: 16.125rem;

    .MuiSelect-select {
      font-size: 0.9375rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      color: ${theme.colors.fadedGray60};
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    svg {
      color: ${theme.colors.gray7};
    }
  `
);

interface FormSelectProps {
  id?: string;
  handleChange: any;
  label?: string;
  menuItems: Array<MenuItemType>;
  value?: string;
}

type Props = FormSelectProps;

const FormSelect = ({ handleChange, id, label, menuItems, value }: Props): JSX.Element => {
  return (
    <FormControl>
      <StyledSelect id={id} value={value} label={label} onChange={handleChange}>
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default FormSelect;
