import React, { useCallback, useState } from 'react';
import Form from 'components/LoginForm/Controls/Form';
import Typography from '@mui/material/Typography';
import FormGroup from 'components/LoginForm/Controls/FormGroup';
import LoginFormLabel from 'components/LoginForm/Controls/LoginFormLabel';
import Input from 'components/LoginForm/Controls/Input';
import LoginButton from 'components/LoginForm/Controls/LoginButton';
import ErrorText from 'components/LoginForm/Controls/ErrorText';
import { MfaValues } from 'types';

export interface MfaFormProps {
  handleFormSubmit: (code: string) => void;
  mfaValue: MfaValues;
  mfaContact: string;
  error: string | null;
}
export const MfaForm = ({
  error: submissionError,
  handleFormSubmit,
  mfaValue,
  mfaContact,
}: MfaFormProps) => {
  const { method: mfaMethod } = mfaValue;
  const [codeValue, setCodeValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const validateForm = useCallback(() => {
    if (!codeValue) {
      setError('Code is required');
      return false;
    } else {
      setError('');
      return true;
    }
  }, [codeValue]);

  const internalHandleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      const valid = validateForm();
      if (valid) {
        handleFormSubmit(codeValue);
      }
    },
    [codeValue, handleFormSubmit, validateForm]
  );

  return (
    <Form onSubmit={internalHandleFormSubmit}>
      <FormGroup sx={{ maxWidth: '100%' }}>
        <Typography variant='h3'>Additional step required</Typography>
        <Typography>
          We&apos;ve sent a code via {mfaMethod} to {mfaContact}, please enter it below:
        </Typography>
      </FormGroup>
      <FormGroup>
        <LoginFormLabel htmlFor={'code'}>Code</LoginFormLabel>
        <Input
          fullWidth
          id='code'
          name='code'
          type='number'
          placeholder='Code'
          error={!!error}
          onChange={(event) => setCodeValue(event.target.value)}
        />
        {submissionError && <ErrorText>{submissionError}</ErrorText>}
        {error && <ErrorText>{error}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <LoginButton variant='contained' color='primary' type='submit'>
          Login
        </LoginButton>
      </FormGroup>
    </Form>
  );
};

export default MfaForm;
