import React from 'react';
import { DateTime } from 'luxon';
import { css, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const StyledDesktopDatePicker = styled(DesktopDatePicker)(
  ({ theme }) => css`
    .MuiInputBase-formControl {
      background-color: ${theme.palette.common.white};
    }

    .MuiOutlinedInput-input {
      max-width: 7rem;
      padding-bottom: 0.625rem;
      padding-top: 0.625rem;
      color: ${theme.colors.fadedGray60};
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  `
);

interface DatePickerProps {
  handleChange: (date: DateTime | unknown) => void;
  reportPeriod: string;
  value: DateTime;
}

type Props = DatePickerProps;

const DatePicker = ({ handleChange, reportPeriod, value }: Props): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StyledDesktopDatePicker
        inputFormat={value?.toLocaleString()}
        value={value}
        onChange={handleChange}
        // Take InputProps out to remove the calendar icon
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        readOnly={reportPeriod !== 'Custom'}
        renderInput={
          reportPeriod === 'Custom'
            ? (params) => <TextField {...params} />
            : ({ InputProps, ...props }) => <TextField {...props} />
        }
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
