import { DateTime, DateTimeOptions, DurationLike } from 'luxon';

export type DateValue = DateTime | Date | string | number;

export type DateRangeVariant = 'month' | 'quarter';

const YEAR_MONTH_REGEX = /^\d{4}-\d{2}$/;

export function getDateTime(date?: DateValue, opts?: DateTimeOptions): DateTime {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date, opts);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date, opts);
  }

  if (date) {
    // Check for year-month string
    if (YEAR_MONTH_REGEX.test(date)) {
      return DateTime.fromFormat(date, 'yyyy-MM');
    }

    return DateTime.fromISO(date, opts);
  }

  return DateTime.now();
}

export const VARIANT_FORMATTER: Record<DateRangeVariant, string> = {
  month: 'MMMM yyyy',
  // eslint-disable-next-line quotes
  quarter: "'Q'q yyyy",
};

export const VARIANT_FORMATTER_SHORT: Record<DateRangeVariant, string> = {
  month: 'MMMM',
  // eslint-disable-next-line quotes
  quarter: "'Q'q",
};

export function getDisplayDateByVariant(dateTime: DateTime, variant: DateRangeVariant): string {
  return dateTime.toFormat(VARIANT_FORMATTER[variant] || 'MMMM yyyy');
}

export function getShortDateByVariant(dateTime: DateTime, variant: DateRangeVariant): string {
  return dateTime.toFormat(VARIANT_FORMATTER_SHORT[variant] || 'MMMM');
}
