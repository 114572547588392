import React from 'react';
import { ifProp } from 'styled-tools';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { css, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormLabel from '@mui/material/FormLabel';

export type PasswordResetType = 'create' | 'reset';

export type PasswordResetFormErrors = {
  newPassword: string;
  confirmPassword: string;
};

export type PasswordResetInitialFormValues = {
  newPassword: string;
  confirmPassword: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  errorMessage?: string | null;
};

const Form = styled('form')`
  padding: 0 1.5rem;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: -2rem;
  }
`;

const FormGroup = styled(FormControl)`
  align-items: center;
  display: flex;
  height: 4.0625rem;
  padding-top: 2rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 1.2rem;
  }
`;

const Input = styled(InputBase)<{ error: boolean }>(
  ({ theme }) => css`
    background-color: white;
    border: 1px solid ${theme.colors.gray7};
    width: 21.25rem;
    max-width: 100%;

    ${ifProp(
      'error',
      css`
        border: 1px solid ${theme.palette.error.main};
      `
    )}

    .MuiInputBase-input {
      padding: 10px 12px;
      position: relative;
    }

    svg {
      height: 0.75em;
      width: 0.75em;
    }
  `
);

const ErrorText = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    font-size: 0.75rem;
    padding-top: 0.25rem;
    text-align: left;
    width: 100%;
  `
);

const LoginButton = styled(Button)(
  ({ theme }) => css`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 21.25rem;
    max-width: 100%;
    background-color: ${theme.colors.red};
  `
);

const PasswordResetFormLabel = styled(FormLabel)`
  font-family: 'Teko';
  color: ${({ theme }) => theme.colors.fadedBlack};
  font-size: 0.9375rem;
  line-height: 200%;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  text-align: left;
  align-self: flex-start;
`;

interface PasswordResetFormProps {
  errors: PasswordResetFormErrors;
  handleFormSubmit: (event: React.FormEvent) => void;
  setErrors: React.Dispatch<React.SetStateAction<PasswordResetFormErrors>>;
  setValues: React.Dispatch<React.SetStateAction<PasswordResetInitialFormValues>>;
  values: PasswordResetInitialFormValues;
  type: PasswordResetType;
}

type Props = PasswordResetFormProps;

export function validateInputs(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  errors: PasswordResetFormErrors,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrors: React.SetStateAction<any>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const temp: any = { ...errors };

  if ('newPassword' in values)
    temp.newPassword = values.newPassword ? '' : 'This field is required.';
  if ('confirmpassword' in values)
    temp.confirmPassword = values.confirmPassword ? '' : 'This field is required.';
  if ('newPassword' in values && 'confirmPassword' in values)
    temp.confirmPassword =
      values.newPassword && values.newPassword === values.confirmPassword
        ? ''
        : 'Passwords do not match.';

  setErrors({
    ...temp,
  });
}

const PasswordResetForm = ({
  errors,
  handleFormSubmit,
  setErrors,
  setValues,
  values,
  type,
}: Props): JSX.Element => {
  const handleClickShowPassword = (name: 'showNewPassword' | 'showConfirmPassword') => {
    setValues({
      ...values,
      [name]: !values[name],
    });
  };

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateInputValue = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // If both passwords have values, validate both fields and confirm they match
    // This way, we can update the `confirmPassword` error if the user updates
    // the `newPassword` causing a mismatch.
    if (values.newPassword && values.confirmPassword) {
      validateInputs(values, errors, setErrors);
    } else {
      validateInputs({ [name]: value }, errors, setErrors);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup>
        <PasswordResetFormLabel htmlFor='new-password'>New password</PasswordResetFormLabel>
        <Input
          fullWidth
          id='new-password'
          name='newPassword'
          type={values.showNewPassword ? 'text' : 'password'}
          placeholder='Password'
          error={!!errors.newPassword}
          value={values.newPassword}
          onChange={handleInputValue}
          onBlur={validateInputValue}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => handleClickShowPassword('showNewPassword')}
              >
                {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors?.newPassword && <ErrorText>{errors.newPassword}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <PasswordResetFormLabel htmlFor='confirm-password'>Confirm password</PasswordResetFormLabel>
        <Input
          fullWidth
          id='confirm-password'
          name='confirmPassword'
          type={values.showConfirmPassword ? 'text' : 'password'}
          placeholder='Password'
          error={!!errors.confirmPassword}
          value={values.confirmPassword}
          onChange={handleInputValue}
          onBlur={validateInputValue}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => handleClickShowPassword('showConfirmPassword')}
              >
                {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors?.confirmPassword && <ErrorText>{errors.confirmPassword}</ErrorText>}
      </FormGroup>
      <FormGroup>
        <LoginButton variant='contained' color='primary' type='submit'>
          {type === 'create' ? 'Create Account' : 'Reset Password'}
        </LoginButton>
      </FormGroup>
    </Form>
  );
};

export default PasswordResetForm;
