import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import Grid from '@mui/material/Grid';
import FormInput from 'components/InvestorProfile/FormInput/FormInput';
import { Investor, Maybe } from 'generated/graphql';
import { css, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { AddressInfo } from 'types';

const GridItem = styled(Grid)`
  margin-top: 10px;
  min-height: 3.3125rem;
`;

const DetailsContainer = styled('div')(
  ({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.palette.common.white};
    display: flex;
    height: 1.9375rem;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  `
);

const DetailsHeading = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.125rem;
    font-size: 1.3125rem;
  `
);

interface DetailsFormProps {
  investor: Maybe<Investor> | undefined;
  title: Maybe<string> | undefined;
}

type Props = DetailsFormProps;

const DetailsForm = ({ investor, title = ' ' }: Props): JSX.Element => {
  const [address, setAddress] = useState<AddressInfo>();
  const addressInfo = get(investor, 'addressInfos.[0]');

  useEffect(() => {
    if (investor) {
      setAddress(addressInfo);
    }
  }, [investor, addressInfo]);

  return (
    <Grid item md={7}>
      <Grid container>
        <GridItem item md={12}>
          <DetailsContainer>
            <DetailsHeading variant='h4'>Client Details</DetailsHeading>
          </DetailsContainer>
        </GridItem>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          {title && (
            <FormInput
              bold={true}
              id='name'
              label='Name'
              readOnly={false}
              type='text'
              value={title}
            />
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <FormInput
            id='address'
            label='Street Address'
            readOnly={false}
            type='text'
            value={address?.line1 ?? '-'}
          />
        </Grid>
        <Grid item md={6}>
          <FormInput id='po-suite' label='PO / Suite' readOnly={false} type='text' value='-' />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <FormInput
            id='city'
            label='City'
            readOnly={false}
            type='text'
            value={address?.city ?? '-'}
          />
        </Grid>
        <Grid item md={3}>
          <FormInput
            id='state'
            label='State'
            readOnly={false}
            type='text'
            value={address?.state ?? '-'}
          />
        </Grid>
        <Grid item md={3}>
          <FormInput
            id='zip-code'
            label='Zip Code'
            readOnly={false}
            type='text'
            value={address?.postalCode ?? '-'}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <FormInput id='phone-number' label='Phone Number' readOnly={false} value='-' />
        </Grid>
        <Grid item md={6}>
          <FormInput
            id='email'
            label='Email'
            readOnly={false}
            type='email'
            value={investor?.email ?? '-'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsForm;
