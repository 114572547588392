import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  UpdatePasswordWithTokenDocument,
  UpdatePasswordWithTokenMutation,
  UpdatePasswordWithTokenMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = UpdatePasswordWithTokenMutation;
type Variables = UpdatePasswordWithTokenMutationVariables;

function useMutationUpdatePassword(
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(UpdatePasswordWithTokenDocument, opts);
}

export default useMutationUpdatePassword;
