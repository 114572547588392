import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Form from 'components/LoginForm/Controls/Form';
import FormGroup from 'components/LoginForm/Controls/FormGroup';
import Input from 'components/LoginForm/Controls/Input';
import LoginFormLabel from 'components/LoginForm/Controls/LoginFormLabel';
import LoginButton from 'components/LoginForm/Controls/LoginButton';
import Select from 'components/LoginForm/Controls/Select';

export interface EnrollmentFormProps {
  email: string;
  emailVerified: boolean;
  phone?: string;
  phoneVerified?: boolean;

  initiateValidateEmail: (email: string) => void;
  validateEmail: (email: string, code: string) => void;
  initiateValidatePhone: (email: string) => void;
  validatePhone: (phone: string, code: string) => void;
  mfaMethod: string;
  setMfaMethod: (method: string, save: boolean) => void;
}

enum EnrollmentState {
  Unknown,
  InitiateVerifyEmail,
  VerifyEmail,
  ChooseMethod,
  InitiateVerifyPhone,
  VerifyPhone,
  Complete,
}

interface CodeTuple {
  email: string;
  phone: string;
}

const newEnrollmentState = (
  old: EnrollmentState,
  emailVerified: boolean,
  phoneVerified: boolean,
  mfaMethod: string
) => {
  if (emailVerified && phoneVerified && mfaMethod) {
    return EnrollmentState.Complete;
  } else if (old !== EnrollmentState.VerifyEmail && !emailVerified) {
    return EnrollmentState.InitiateVerifyEmail;
  } else if (!emailVerified) {
    return EnrollmentState.VerifyEmail;
  } else if (old !== EnrollmentState.VerifyPhone && mfaMethod === 'phone' && !phoneVerified) {
    return EnrollmentState.InitiateVerifyPhone;
  } else if (mfaMethod === 'phone' && !phoneVerified) {
    return EnrollmentState.VerifyPhone;
  } else if (mfaMethod === 'email' && emailVerified) {
    return EnrollmentState.Complete;
  } else if (!mfaMethod || 'none' === mfaMethod) {
    return EnrollmentState.ChooseMethod;
  }

  return EnrollmentState.Unknown;
};

export const EnrollmentForm = ({
  email,
  emailVerified,
  phone,
  phoneVerified,
  initiateValidateEmail,
  initiateValidatePhone,
  validateEmail,
  validatePhone,
  setMfaMethod,
  mfaMethod,
}: EnrollmentFormProps) => {
  const navigate = useNavigate();
  const [enrollmentState, setEnrollmentState] = useState<EnrollmentState>(EnrollmentState.Unknown);
  const [country, setCountry] = useState<string>('+1');
  const [number, setNumber] = useState<string>('');
  const [codes, setCodes] = useState<CodeTuple>({ email: '', phone: '' });
  const [codeErrors, setCodeErrors] = useState<CodeTuple>({ email: '', phone: '' });

  const phoneNumber = useMemo(() => (number ? `${country}${number}` : null), [country, number]);

  useEffect(() => {
    setEnrollmentState(
      newEnrollmentState(enrollmentState, emailVerified, phoneVerified ?? false, mfaMethod)
    );
  }, [enrollmentState, emailVerified, phoneVerified, mfaMethod]);

  const handleEmailInitiateClick = useCallback(() => {
    initiateValidateEmail(email);
    setEnrollmentState(EnrollmentState.VerifyEmail);
  }, [email, initiateValidateEmail]);

  const handleEmailVerifyClick = useCallback(() => {
    validateEmail(email, codes.email);
    setEnrollmentState(EnrollmentState.Complete);
  }, [codes.email, email, validateEmail]);

  const handlePhoneVerifyClick = useCallback(() => {
    validatePhone(phone ?? '', codes.phone);
    setEnrollmentState(EnrollmentState.Complete);
  }, [codes.phone, phone, validatePhone]);

  const handleCountrySelect = useCallback((countryCode: string) => {
    setCountry(countryCode);
  }, []);

  const handleNumberChange = useCallback((event: React.BaseSyntheticEvent) => {
    setNumber(event?.target?.value);
  }, []);

  const handlePhoneEntry = useCallback(() => {
    if (phoneNumber) {
      initiateValidatePhone(phoneNumber);
      setEnrollmentState(EnrollmentState.VerifyPhone);
    } else {
      setCodeErrors({ ...codeErrors, phone: 'Please enter a number' });
    }
  }, [codeErrors, initiateValidatePhone, phoneNumber]);

  const getCodeEntryHandler = useCallback(
    (which: string) => {
      return (event: React.BaseSyntheticEvent) => {
        setCodes({ ...codes, [which]: event.target.value });
      };
    },
    [codes]
  );

  return (
    <Form>
      <FormGroup>
        <Typography variant={'h3'}>Lets keep your account secure</Typography>
      </FormGroup>
      {enrollmentState === EnrollmentState.InitiateVerifyEmail && (
        <Box>
          <FormGroup>
            <Typography variant={'h4'}>Verify your Email Address</Typography>
            <LoginButton
              onClick={handleEmailInitiateClick}
              variant='contained'
              color='primary'
              type='submit'
            >
              Send Email
            </LoginButton>
          </FormGroup>
        </Box>
      )}
      {enrollmentState === EnrollmentState.VerifyEmail && (
        <Box>
          <Typography variant={'h4'}>Verify your Email Address</Typography>
          <Typography>Enter the code you just received:</Typography>
          <FormGroup>
            <LoginFormLabel htmlFor='code'>Code</LoginFormLabel>
            <Input
              value={codes.email}
              onChange={getCodeEntryHandler('email')}
              fullWidth
              id='code'
              name='code'
              type='number'
              placeholder='Code'
              error={!!codeErrors.email}
            />
          </FormGroup>
          <FormGroup>
            <LoginButton
              onClick={handleEmailVerifyClick}
              variant='contained'
              color='primary'
              type='submit'
            >
              Verify Code
            </LoginButton>
          </FormGroup>
        </Box>
      )}
      {enrollmentState === EnrollmentState.ChooseMethod && (
        <Box>
          <FormGroup>
            <Typography variant={'h4'}>Choose a 2FA Option</Typography>
          </FormGroup>
          <FormGroup>
            <LoginButton
              onClick={() => setMfaMethod('email', true)}
              variant='contained'
              color='primary'
            >
              Email
            </LoginButton>
          </FormGroup>
          <FormGroup>
            <LoginButton
              onClick={() => setMfaMethod('phone', false)}
              variant='contained'
              color='primary'
            >
              Phone
            </LoginButton>
          </FormGroup>
        </Box>
      )}
      {enrollmentState === EnrollmentState.InitiateVerifyPhone && (
        <Box>
          <FormGroup>
            <Typography variant={'h4'}>Verify your Phone</Typography>
          </FormGroup>
          <Stack direction={'column'}>
            <Box>
              <LoginFormLabel htmlFor={'country'}>Country</LoginFormLabel>
              <Select
                value={country}
                onChange={(e) => handleCountrySelect(`${e.target.value}`)}
                id={'country'}
                name={'country'}
                error={false}
              >
                <MenuItem value={'+1'}>+1 (US)</MenuItem>
                <MenuItem value={'+44'}>+44 (UK)</MenuItem>
              </Select>
            </Box>
            <Box>
              <LoginFormLabel htmlFor={'phone'}>Number</LoginFormLabel>
              <Input
                value={number}
                onChange={handleNumberChange}
                type='tel'
                name={'phone'}
                id={'phone'}
                error={false}
              />
            </Box>
          </Stack>
          <FormGroup>
            <LoginButton
              onClick={handlePhoneEntry}
              variant='contained'
              color='primary'
              type='submit'
            >
              Send Verification Code
            </LoginButton>
          </FormGroup>
        </Box>
      )}
      {enrollmentState === EnrollmentState.VerifyPhone && (
        <Box>
          <FormGroup>
            <Typography variant={'h4'}>Verify your Phone</Typography>
          </FormGroup>
          <Typography>Enter the code you just received at {phone}:</Typography>
          <FormGroup>
            <LoginFormLabel htmlFor='code'>Code</LoginFormLabel>
            <Input
              value={codes.phone}
              onChange={getCodeEntryHandler('phone')}
              fullWidth
              id='code'
              name='code'
              type='number'
              placeholder='Code'
              error={!!codeErrors.phone}
            />
          </FormGroup>
          <FormGroup>
            <LoginButton
              onClick={handlePhoneVerifyClick}
              variant='contained'
              color='primary'
              type='submit'
            >
              Verify Code
            </LoginButton>
          </FormGroup>
        </Box>
      )}
      {enrollmentState === EnrollmentState.Complete && (
        <Box>
          <FormGroup>
            <Typography variant={'h4'}>All set!</Typography>
            <Typography>
              Next time you log in, you&apos;ll be sent a code via {mfaMethod}.
            </Typography>
            <LoginButton
              onClick={() => navigate('/')}
              variant='contained'
              color='primary'
              type='submit'
            >
              Continue to the App
            </LoginButton>
          </FormGroup>
        </Box>
      )}
    </Form>
  );
};

export default EnrollmentForm;
