import React from 'react';
import { styled } from '@mui/material/styles';
import logo from 'assets/images/logo/MIF-logo.svg';
import LoginForm from 'components/LoginForm/LoginForm';
import AlertBar from 'components/AlertBar/AlertBar';
import SplashLayout from 'components/SplashLayout/SplashLayout';
import ResponsiveText36 from 'components/ResponsiveText36/ResponsiveText36';
import loginSplash from 'assets/images/Login/login-splash.webp';
import useQueryParams from 'hooks/useQueryParams';
import MfaForm from 'components/LoginForm/MfaForm';
import EnrollmentForm from 'components/LoginForm/EnrollmentForm';
import { useLoginState } from 'hooks/login/useLoginState';
import { formatUSPhone } from 'utils/functions';
import { LoginState } from 'types';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  height: calc(100% - 10rem);
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1.5rem;
    height: calc(100% - 3rem);
  }
`;

const Logo = styled('img')`
  margin: 1.5rem 0 3rem;
`;

const Login = (): JSX.Element => {
  const queryParams = useQueryParams();
  const successMessage = queryParams.get('success');
  const {
    errors,
    handleFormSubmit,
    initiateValidateEmail,
    initiateValidatePhone,
    loginState,
    mfaError,
    mfaValue,
    setErrors,
    setMfaMethod,
    setValues,
    validate2FACode,
    validateEmail,
    validatePhone,
    values,
  } = useLoginState();

  return (
    <SplashLayout img={loginSplash}>
      <Container>
        <Logo src={logo} alt='Millennium Fund Logo' />
        <div>
          {values.errorMessage ? (
            <AlertBar message={values.errorMessage} type='error' />
          ) : successMessage ? (
            <AlertBar message={successMessage} type='success' />
          ) : null}
        </div>
        <ResponsiveText36>Log In</ResponsiveText36>
        {loginState === LoginState.Password && (
          <LoginForm
            errors={errors}
            handleFormSubmit={handleFormSubmit}
            setErrors={setErrors}
            setValues={setValues}
            values={values}
          />
        )}
        {loginState === LoginState.MFA && (
          <MfaForm
            handleFormSubmit={validate2FACode}
            mfaValue={mfaValue}
            mfaContact={mfaValue.phone ? formatUSPhone(mfaValue.phone) : mfaValue.email}
            error={mfaError}
          />
        )}
        {loginState === LoginState.EnrollMFA && (
          <EnrollmentForm
            mfaMethod={mfaValue.method}
            emailVerified={mfaValue.emailVerified}
            email={mfaValue.email}
            phone={mfaValue.phone}
            phoneVerified={mfaValue.phoneVerified}
            initiateValidateEmail={initiateValidateEmail}
            validateEmail={validateEmail}
            initiateValidatePhone={initiateValidatePhone}
            validatePhone={validatePhone}
            setMfaMethod={setMfaMethod}
          />
        )}
      </Container>
    </SplashLayout>
  );
};

export default Login;
