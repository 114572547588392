import React, { useMemo } from 'react';
import { DEFAULT_LOCALE } from 'utils/constants';

interface FormattedNumberProps {
  value: number;
  component?: React.ElementType;
  style?: string | undefined;
  currency?: string | undefined;
  currencySign?: string | undefined;
  minimumIntegerDigits?: number | undefined;
  minimumFractionDigits?: number | undefined;
  maximumFractionDigits?: number | undefined;
  minimumSignificantDigits?: number | undefined;
  maximumSignificantDigits?: number | undefined;
  localeMatcher?: string | undefined;
  useGrouping?: boolean | undefined;
}

type Props = FormattedNumberProps;

const FormattedNumber = ({ value, component: Component = 'span', ...formatOptions }: Props) => {
  const numberFormat = useMemo(
    () => new Intl.NumberFormat(DEFAULT_LOCALE, formatOptions),
    [formatOptions]
  );
  const formattedValue = useMemo(() => numberFormat.format(value), [numberFormat, value]);

  return <Component>{formattedValue}</Component>;
};

export default FormattedNumber;
