import React from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

export const HEADER_HEIGHT = 80;

interface ThemeColors {
  secondary: string;
  gray0_25: string;
  gray0_5: string;
  gray1: string;
  gray1_5: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray5_5: string;
  gray6: string;
  gray7: string;
  mifGray1: string;
  mifGray2: string;
  mifGray3: string;
  mifGray4: string;
  mifGray5: string;
  mifGray6: string;
  mifGray7: string;
  mifGray8: string;
  mifGray9: string;
  mifGray10: string;
  mifGray11: string;
  mifGray12: string;
  mifGray13: string;
  mifGray14: string;
  mifGray15: string;
  mifGray16: string;
  mifBlue1: string;
  mifBlue2: string;
  mifBlue3: string;
  fadedGray40: string;
  fadedGray60: string;
  fadedBlack30: string;
  fadedBlack50: string;
  fadedBlack: string;
  fadedWhite60: string;
  white: string;
  black: string;
  limeHighlight: string;
  red: string;
  redShade: string;
  purpleHighlight: string;
}

declare module '@mui/material/styles' {
  // Add custom theme variables.
  interface Theme {
    colors: ThemeColors;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    headerHeight: number;
  }

  // Allow configuration using `createMuiTheme`.
  interface ThemeOptions {
    colors: ThemeColors;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    headerHeight: number;
  }

  // Add custom typography variants.
  // Docs: https://mui.com/customization/typography/#adding-amp-disabling-variants
  interface TypographyVariants {
    caption2: React.CSSProperties;
    xlarge: React.CSSProperties;
    large: React.CSSProperties;
    medium: React.CSSProperties;
    small: React.CSSProperties;
  }

  // Allow configuration using `createTheme`.
  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties;
    xlarge: React.CSSProperties;
    large: React.CSSProperties;
    medium: React.CSSProperties;
    small: React.CSSProperties;
  }

  // Add custom palette options.
  interface Palette {
    neutral: Palette['primary'];
  }

  // Allow configuration using `createTheme`.
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

// Update the Typography's variant prop options.
// Docs: https://mui.com/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: true;
    xlarge: true;
    large: true;
    medium: true;
    small: true;
  }
}

// Update the Chip's color prop options.
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true;
  }
}

const fontFamily = '"Roboto", "Helvetica", "Arial", "Teko", sans-serif';

const colors: ThemeColors = {
  secondary: '#ffffff',
  gray0_25: '#faf8f6',
  gray0_5: '#f2f2f2',
  gray1: '#e5e3df',
  gray1_5: '#ddd9d5',
  gray2: '#d5d0ca',
  gray3: '#ada39d',
  gray4: '#7d7470',
  gray5: '#615753',
  gray5_5: '#4a4644',
  gray6: '#353435',
  gray7: '#303030',
  mifGray1: '#caccd2',
  mifGray2: '#a4a7b1',
  mifGray3: '#d3d3d3',
  mifGray4: '#ada39d',
  mifGray5: '#7d7470',
  mifGray6: '#615753',
  mifGray7: 'rgba(226, 226, 226, 0.4)',
  mifGray8: '#e2e2e2',
  mifGray9: 'rgba(216, 216, 216, 0.8)',
  mifGray10: 'rgba(48, 48, 48, 0.05)',
  mifGray11: '#d8d8d8',
  mifGray12: '#dfdfdf',
  mifGray13: 'rgba(48, 48, 48, 0.1)',
  mifGray14: '#7e7c7c',
  mifGray15: '#979797',
  mifGray16: '#e9e9e9',
  mifBlue1: '#454b60',
  mifBlue2: '#2a2c34',
  mifBlue3: '#1b2638',
  fadedGray40: 'rgba(48, 48, 48, 0.4)',
  fadedGray60: 'rgba(48, 48, 48, 0.6)',
  fadedBlack30: 'rgba(0, 0, 0, 0.3)',
  fadedBlack50: 'rgba(0, 0, 0, 0.5)',
  fadedBlack: 'rgba(0, 0, 0, 0.6)',
  fadedWhite60: 'rgba(255, 255, 255, 0.6)',
  black: '#000000',
  white: '#ffffff',
  limeHighlight: '#e7e9af',
  red: '#a63130',
  redShade: '#7c0016',
  purpleHighlight: '#cdc3cd',
};

const defaultTheme = createTheme();

// Override some default shadows.
// See https://mui.com/system/shadows/ or to see default values: https://mui.com/customization/default-theme/
const customShadows: Shadows = defaultTheme.shadows.slice() as Shadows;
customShadows[1] = '0 0.125rem 0.125rem rgba(0, 0, 0, 0.25)';

export const themeOptions: ThemeOptions = {
  shape: {
    borderRadius: 0,
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.mifBlue1,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: '#353435',
      secondary: '#117da1',
      disabled: 'rgba(17, 125, 162, 0.5)',
    },
    background: {
      default: '#f2f1ef',
    },
    success: {
      main: '#306b34',
      light: '#83a685',
      dark: '#1d401f',
    },
    warning: {
      main: '#f4b920',
      light: '#f8d579',
      dark: '#926f13',
    },
    error: {
      main: '#cf0025',
      light: '#e78092',
      dark: colors.redShade,
    },
    info: {
      main: '#117da2',
    },
    neutral: {
      main: colors.mifGray2,
      contrastText: colors.mifGray6,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#ffffff',
          color: '#353435',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: `${HEADER_HEIGHT / 16}rem`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '0.375rem 1rem',
        },
        message: {
          padding: '0.5rem 0',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '0.375rem 1rem',
        },
        sizeSmall: {
          fontSize: '0.75rem',
          lineHeight: '1.25rem',
          padding: '0.375rem 0.75rem',
          borderRadius: '0.0625rem',
          '& .MuiButton-endIcon.MuiButton-iconSizeSmall': {
            marginLeft: '0.125rem',
          },
        },
        sizeMedium: {
          fontSize: '1.125rem',
          fontWeight: 500,
          lineHeight: '1.25rem',
          letterSpacing: '0.015625rem',
          padding: '0.75rem',
          borderRadius: '0.0625rem',
          '&.MuiButton-outlined': {
            padding: '0.625rem',
            borderWidth: '0.125rem',
          },
          '& .MuiButton-startIcon > *:nth-of-type(1)': {
            fontSize: '1.5rem',
          },
          '& .MuiButton-endIcon > *:nth-of-type(1)': {
            fontSize: '1.5rem',
          },
          '& .MuiButton-startIcon': {
            marginRight: '0.5rem',
            marginLeft: '-0.25rem',
          },
        },
        textPrimary: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
        outlinedSecondary: {
          borderColor: colors.mifBlue1,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        determinate: {
          backgroundColor: colors.gray1_5,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.mifGray6,
          borderRadius: '0.0625rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          padding: '0.5rem 1rem',
        },
        arrow: {
          color: colors.mifGray6,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '2rem',
        },
        sizeSmall: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 400,
          letterSpacing: '0.015625rem',
          height: '1.5rem',
        },
        icon: {
          marginLeft: '0.3125rem',
          marginRight: '-0.375rem',
        },
        label: {
          paddingLeft: '0.75rem',
          paddingRight: '0.75rem',
        },
        labelSmall: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
      },
      variants: [
        {
          props: { color: 'neutral' },
          style: {
            backgroundColor: colors.mifGray2,
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.mifBlue1,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        padding: {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: '0.25rem',
          marginBottom: '0.25rem',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.red,
          height: '0.25rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: 'rgba(256, 256, 256, 0.5)',
          fontFamily: 'Teko',
          fontWeight: 600,
          fontSize: '1.25rem',
          lineHeight: 1.45,
          letterSpacing: '0.1875rem',
          '&.Mui-selected': {
            color: 'white',
          },
        },
      },
    },
  },
  typography: {
    fontFamily,
    fontSize: 16,
    h1: {
      fontFamily: 'Teko',
      fontSize: '4rem',
      fontWeight: 600,
      lineHeight: 1,
    },
    h2: {
      fontFamily: 'Teko',
      fontSize: '3.125rem',
      fontWeight: 500,
      lineHeight: 1.08,
    },
    h3: {
      fontFamily: 'Teko',
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.125,
    },
    h4: {
      fontFamily: 'Teko',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h5: {
      fontFamily: 'Teko',
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.22,
    },
    h6: {
      fontFamily: 'Teko',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.29,
    },
    subtitle1: {
      fontSize: '1.25rem',
      letterSpacing: '0.0075em',
    },
    subtitle2: {
      fontSize: '1.125rem',
      lineHeight: 1.1111111111,
      fontWeight: 500,
      letterSpacing: '0.00625rem',
    },
    body1: {
      letterSpacing: '0em',
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    button: {
      fontFamily: 'Teko',
      fontSize: '1rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      lineHeight: 1.25,
    },
    caption: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 300,
      letterSpacing: '0',
    },
    overline: {
      fontSize: '0.875rem',
      fontWeight: 700,
      letterSpacing: '0.8px',
      lineHeight: 1.7,
    },
    // Custom typography variants
    caption2: {
      // Need to define fontFamily here unlike others because this is a custom
      // variant and therefore doesn't have default values in the theme.
      fontFamily,
      fontSize: '0.75rem',
      lineHeight: 1.3333333333,
      fontWeight: 400,
      letterSpacing: '0.015625rem',
    },
    xlarge: {
      fontFamily: 'Roboto',
      fontSize: '1.5rem',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    large: {
      fontFamily: 'Roboto',
      fontSize: '1.3125rem',
      lineHeight: 1.24,
      fontWeight: 500,
    },
    medium: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    small: {
      fontFamily: 'Roboto',
      fontSize: '0.8125rem',
      lineHeight: 1.38,
      fontWeight: 500,
    },
  },
  shadows: customShadows,
  spacing: (factor: number) => `${0.5 * factor}rem`,

  // Custom-added properties below.
  colors,
  brand: '#ec712e',
  footerHeight: 72,
  mobileFooterHeight: 56,
  mobileTopBarHeight: 52,
  headerHeight: HEADER_HEIGHT,
};

export default createTheme(themeOptions);
