import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  PortfolioSummaryDocument,
  PortfolioSummaryQuery,
  PortfolioSummaryQueryVariables,
} from 'generated/graphql';

type Data = PortfolioSummaryQuery;
type Variables = PortfolioSummaryQueryVariables;

function useQueryPortfolioSummary(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(PortfolioSummaryDocument, options);
}

export default useQueryPortfolioSummary;
