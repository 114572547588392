import React, { useEffect, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import UploadedDoc from 'components/UploadedDocuments//UploadedDoc';
import Box from '@mui/material/Box';
import TitledContainer from 'components/TitledContainer/TitledContainer';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { MILLENNIUM_USER } from 'utils/constants';
import useQueryUploadedDocuments from 'hooks/queries/useQueryUploadedDocuments/useQueryUploadedDocuments';
import useMutationAddLoanDocument from 'hooks/mutations/useMutationUploadDocument/useMutationAddLoanDocument';
import { UploadedDocumentsQuery } from 'generated/graphql';

const StyledButton = styled(Button)`
  color: white;
  border-radius: 5rem;
  border-color: white;

  &:hover {
    border-color: white;
  }
`;

const UploadsList = styled(Box)`
  overflow-y: scroll;
`;

const ErrorMessage = styled('p')(
  ({ theme }) => css`
    margin-top: 0.35rem;
    color: ${theme.colors.red};
    font-size: 0.9rem;
  `
);

const TextNotice = styled('p')`
  text-align: center;
`;

interface UploadedDocumentsProps {
  loanInfoId: number;
}

const UploadedDocuments = ({ loanInfoId }: UploadedDocumentsProps): JSX.Element => {
  const { role } = useUserContext();

  const [documents, setDocuments] = useState<UploadedDocumentsQuery | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const uploadInputRef = React.useRef<HTMLInputElement>(null);

  // Send query for the uploaded documents data
  const { data: loanDocuments } = useQueryUploadedDocuments({
    variables: {
      loanInfoId: loanInfoId,
    },
  });

  useEffect(() => {
    setDocuments(loanDocuments);
  }, [loanDocuments, setDocuments]);

  // Mutation for uploading the new document to the database
  const [addLoanDocument] = useMutationAddLoanDocument();

  const convertToBase64 = async (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          resolve(fileReader.result);
        } else {
          resolve('');
        }
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getFileType = (blob: string): string => {
    return blob.substring(blob.indexOf(':') + 1, blob.indexOf(';'));
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    const blob = files && files[0] ? await convertToBase64(files[0]) : '';
    const type = getFileType(blob);
    const name = files && files[0] ? files[0].name : '';

    const { data } = await addLoanDocument({
      variables: {
        loanInfoId: loanInfoId,
        documentName: name,
        documentBlob: blob,
        documentType: type,
      },
    });

    if (!data || !data.addLoanDocument) {
      setError('There was an issue uploading your document.');
      return;
    }

    const { success, errorMessage, documents: docs } = data.addLoanDocument;

    if (!success || !docs || docs.length < 1) {
      // Notify user of error with upload: errorMessage
      setError(
        errorMessage ? errorMessage : 'There was an unexpected error when handling your request.'
      );
    } else {
      setDocuments({
        uploadedDocuments: docs,
      });
      setError(undefined);
    }
  };

  return (
    <TitledContainer
      title='Uploads'
      headerChildren={
        role === MILLENNIUM_USER ? (
          <>
            <input
              ref={uploadInputRef}
              type='file'
              name='myFile'
              accept='.csv, .pdf'
              onChange={(e) => handleFileUpload(e)}
              hidden
            />
            <StyledButton
              onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
              variant='outlined'
              disableElevation
              sx={{ maxHeight: 32 }}
            >
              + Document
            </StyledButton>
          </>
        ) : (
          <></>
        )
      }
    >
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <UploadsList>
        {documents?.uploadedDocuments && documents?.uploadedDocuments.length > 0 ? (
          documents.uploadedDocuments.map((doc) => (
            <>
              {doc && (
                <UploadedDoc
                  key={doc.filename}
                  filename={doc.filename}
                  uploadDate={doc.uploadDate}
                  downloadUrl={doc.s3Url}
                />
              )}
            </>
          ))
        ) : (
          <TextNotice>No uploaded documents for this loan</TextNotice>
        )}
      </UploadsList>
    </TitledContainer>
  );
};

export default UploadedDocuments;
