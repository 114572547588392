import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { Maybe, LoanInfo } from 'generated/graphql';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const SelectFormControl = styled(FormControl)`
  min-width: 7.5rem;
  padding: 1.5rem 0;
`;

const StyledSelect = styled(Select)`
  color: white;
  font-family: 'Teko';
  font-size: 3.75rem;
  line-height: 1.43;
  font-weight: 600;
  text-transform: capitalize;

  .MuiSelect-select {
    padding-right: 5rem !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSvgIcon-root {
    color: white;
    right: 0;
  }
`;

interface ClientSelectProps {
  items?: Maybe<Array<LoanInfo>>;
  selectValue?: string | null;
}

type Props = ClientSelectProps;

const ClientSelect = ({ selectValue, items }: Props): JSX.Element => {
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    navigate(`/loan-details/${event.target.value}`);
  };

  return (
    <SelectFormControl>
      <StyledSelect
        id='large-select-id'
        labelId='large-select-label'
        onChange={handleChange}
        value={selectValue}
      >
        {items?.map((item, index) => {
          const { client, id } = item;
          return (
            <MenuItem key={index} value={id}>
              {client?.clientName}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </SelectFormControl>
  );
};

export default ClientSelect;
