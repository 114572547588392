import React from 'react';
import AlertBar from 'components/AlertBar/AlertBar';
import { css, styled } from '@mui/material/styles';
import { FormControl, FormLabel, InputBase } from '@mui/material';
import BasicFormDialog from 'components/BasicFormDialog/BasicFormDialog';
import { FormValues } from 'components/InvestorProfile/types';
import { Investor, Maybe } from 'generated/graphql';

const FormContainer = styled('div')`
  margin-bottom: 1rem;
`;

const FormGroup = styled(FormControl)`
  align-items: center;
  display: flex;
  height: 4.0625rem;
  width: 100%;

  &:not(:first-child) {
    padding-top: 2rem;
  }
`;

const Input = styled(InputBase)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.colors.gray7};
    width: 100%;
    max-width: 100%;

    .MuiInputBase-input {
      padding: 0.625rem 0.75rem;
      position: relative;
    }
  `
);

const InputLabel = styled(FormLabel)`
  font-family: 'Teko';
  color: ${({ theme }) => theme.colors.fadedBlack};
  font-size: 0.9375rem;
  line-height: 200%;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  align-self: flex-start;
`;

interface EditDetailsModalProps {
  open: boolean;
  errorMessage: string | null;
  submitForm: (event: React.FormEvent) => Promise<void>;
  toggleModal: () => void;
  handleInputValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: FormValues;
}

type Props = EditDetailsModalProps;

const EditDetailsModal = ({
  open,
  submitForm,
  errorMessage,
  toggleModal,
  handleInputValue,
  values,
}: Props): JSX.Element => {
  return (
    <BasicFormDialog
      open={open}
      toggleModal={toggleModal}
      formTitle='Edit Details'
      handleSubmit={submitForm}
    >
      <div>{errorMessage && <AlertBar message={errorMessage} type='error' />}</div>
      <FormContainer>
        <FormGroup>
          <InputLabel htmlFor='bank-edit'>Bank</InputLabel>
          <Input
            id='bank-edit'
            name='bankName'
            onChange={handleInputValue}
            value={values?.bankName}
            type='text'
          />
        </FormGroup>
        <FormGroup>
          <InputLabel htmlFor='routing-number-edit'>Routing #</InputLabel>
          <Input
            id='routing-number-edit'
            name='routingNumber'
            onChange={handleInputValue}
            value={values?.routingNumber}
            type='number'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        </FormGroup>
        <FormGroup>
          <InputLabel htmlFor='account-number-edit'>Account #</InputLabel>
          <Input
            id='account-number-edit'
            name='accountNumber'
            onChange={handleInputValue}
            value={values?.accountNumber}
            type='number'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        </FormGroup>
      </FormContainer>
    </BasicFormDialog>
  );
};

export default EditDetailsModal;
