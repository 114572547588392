import React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { MILLENNIUM_USER } from 'utils/constants';
import theme from '../../../theme';
import useAppContext from 'contexts/AppContext/AppContext';

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    elevation: 0;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    overflow: visible;
    margin-top: 0.75rem;

    &:before {
      background-color: white;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 14px;
      width: 10px;
      height: 10px;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;

const StyledAccountName = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

const AccountMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const { setToken } = useAppContext();
  const { role } = useUserContext();
  const [anchorElAccountNav, setAnchorElAccountNav] = React.useState<null | HTMLElement>(null);
  const accountNavOpen = Boolean(anchorElAccountNav);

  const handleOpenAccountNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountNav(event.currentTarget);
  };

  const handleCloseAccountNavMenu = () => {
    setToken(null);
    navigate('/login');
  };

  return (
    <>
      <Avatar />
      <Button
        variant='text'
        fullWidth={true}
        sx={{
          transition: 'width 300ms',
          height: '40px',
          whiteSpace: 'nowrap',
          color: theme.colors.mifBlue1,
          fontWeight: 600,
        }}
        onClick={handleOpenAccountNavMenu}
        aria-controls={accountNavOpen ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={accountNavOpen ? 'true' : undefined}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <StyledAccountName>My Account</StyledAccountName>
      </Button>
      <StyledMenu
        anchorEl={anchorElAccountNav}
        id='account-menu'
        open={accountNavOpen}
        onClose={() => setAnchorElAccountNav(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleCloseAccountNavMenu}>Logout</MenuItem>
        {role === MILLENNIUM_USER && (
          <MenuItem component='a' href={process.env.REACT_APP_ADMIN_URL || '/admin'}>
            Access Admin
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default AccountMenu;
