import { css, styled } from '@mui/material/styles';

const ErrorText = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    font-size: 0.75rem;
    padding-top: 0.25rem;
    text-align: left;
    width: 100%;
  `
);

export default ErrorText;
