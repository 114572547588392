import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';

const FormGroup = styled(FormControl)`
  align-items: center;
  display: flex;
  height: 4.0625rem;
  padding-top: 2rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 1.2rem;
  }
`;

export default FormGroup;
