import React from 'react';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import { css, styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';

const InputWrapper = styled(FormControl)`
  margin-top: 0.625rem;
  width: 100%;
`;

const StyledInput = styled(Input)<{
  $bold: boolean;
  readOnly: boolean;
}>(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    font-size: 1.25rem;

    &::after {
      border-bottom: inherit;
    }

    && {
      &:hover {
        &::before {
          border-bottom: 1px solid ${theme.palette.common.white};
        }
      }
    }

    &::before {
      border-bottom: 1px solid ${theme.palette.common.white};
    }
  `
);

const Label = styled(FormHelperText)(
  ({ theme }) => css`
    color: ${theme.colors.fadedWhite60};
    font-size: 0.8125rem;
  `
);

interface FormInputProps {
  bold?: boolean;
  name?: string;
  readOnly?: boolean;
  defaultValue?: string | number;
  id?: string;
  label?: string;
  type?: string;
  value?: string | number;
  endAdornment?: JSX.Element | undefined;
}

type Props = FormInputProps;

const FormInput = ({
  bold = false,
  endAdornment,
  defaultValue,
  readOnly = true,
  name,
  id,
  label,
  type,
  value,
}: Props): JSX.Element => {
  return (
    <InputWrapper variant='standard'>
      <Label id={id}>{label}</Label>
      <StyledInput
        $bold={bold}
        defaultValue={defaultValue}
        id={id}
        name={name}
        type={type}
        value={value}
        readOnly={readOnly}
        endAdornment={endAdornment}
      />
    </InputWrapper>
  );
};

export default FormInput;
