import React, { useMemo } from 'react';
import { loansHeaders, loansInfo } from 'utils/MockData/loans';
import ExpandableList from 'components/ExpandableList/ExpandableList';
import {
  getDatesListForVariant,
  getDaysList,
  getDaysListForQuarter,
  getItems,
  getDetails,
  getDetailsTotal,
} from 'utils/functions';
import { AllLoansData } from 'generated/graphql';
import { Loan } from 'types';
import { DateRangeVariant, getDateTime, getDisplayDateByVariant } from 'utils/dates';

interface LoansExpandableListProps {
  currentDate: string;
  allLoansData?: AllLoansData;
  variant: DateRangeVariant;
}

type Props = LoansExpandableListProps;

const LoanExpandableList = ({ currentDate, allLoansData, variant }: Props) => {
  const daysList = useMemo(
    () =>
      variant === 'quarter'
        ? getDaysListForQuarter(getDisplayDateByVariant(getDateTime(currentDate), 'quarter'))
        : getDaysList(currentDate, 'yyyy-MM'),
    [currentDate, variant]
  );

  const datesList = useMemo(
    () => getDatesListForVariant(currentDate, variant),
    [currentDate, variant]
  );

  const items = useMemo<Loan[]>(
    () => (allLoansData && getItems(allLoansData)) || [],
    [allLoansData]
  );

  const details = useMemo<{ deployed: number; interest: number }[][]>(
    () => getDetails(allLoansData, datesList),
    [allLoansData, datesList]
  );

  const detailsTotal = useMemo<{ deployed: number; interest: number }[]>(
    () => getDetailsTotal(allLoansData, datesList),
    [allLoansData, datesList]
  );

  const totalItem = useMemo(
    () => ({
      name: 'Totals',
      totalCapitalAtWork: allLoansData?.total.totalCapitalAtWork || 0,
      interestTotal: allLoansData?.total.interestTotal || 0,
    }),
    [allLoansData?.total.interestTotal, allLoansData?.total.totalCapitalAtWork]
  );

  const totalDetails = detailsTotal.length > 0 ? detailsTotal : loansInfo;

  return (
    <ExpandableList
      days={daysList}
      items={items}
      headers={loansHeaders}
      totalHeaders={loansHeaders}
      details={details}
      detailsTotal={totalDetails}
      title='Loans'
      tallyHeaders={['Interest Total', 'Total Capital at Work']}
      rowHeaders={['rate', 'committed', 'deployed', 'Interest Total']}
      totalItem={totalItem}
      fixOnScroll
    />
  );
};

export default LoanExpandableList;
