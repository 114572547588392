import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { InvestorsDocument, InvestorsQuery, InvestorsQueryVariables } from 'generated/graphql';

type Data = InvestorsQuery;
type Variables = InvestorsQueryVariables;

function useQueryInvestors(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(InvestorsDocument, {
    ...options,
  });
}

export default useQueryInvestors;
