import React from 'react';
import { css, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { DateRangeVariant } from 'generated/graphql';
import DateRangeSelector from 'components/LoanSummary/DateRangeSelector/DateRangeSelector';
import ExpandedDetails from 'components/ExpandableList/ExpandedDetails/ExpandedDetails';
import { getDaysList } from 'utils/functions';
import { getDisplayDateByVariant, getDateTime } from 'utils/dates';
import { loansHeaders } from 'utils/MockData/loans';

const BlockContainer = styled(Container)`
  margin-bottom: 3rem;
`;

const AccrualBlockContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    border: 0.0625rem solid ${theme.colors.mifGray8};
  `
);

const ExpandedWrapper = styled('div')(
  ({ theme }) => css`
    border-top: 0.0625rem solid ${theme.colors.mifGray8};
  `
);

type Props = {
  details: object[] | object[][] | undefined;
  setCurrentDate: (newValue: string, endValue: string) => void;
  currentDate: string;
};

const InterestAccrualBlock: React.FC<Props> = ({
  details,
  setCurrentDate,
  currentDate,
}): JSX.Element => {
  const daysList = getDaysList(
    getDisplayDateByVariant(getDateTime(currentDate), DateRangeVariant.Month)
  );

  return (
    <BlockContainer>
      <AccrualBlockContainer>
        <DateRangeSelector
          value={currentDate}
          variant={DateRangeVariant.Month}
          onChange={setCurrentDate}
        />
        <ExpandedWrapper>
          <ExpandedDetails
            days={daysList}
            details={details}
            headers={loansHeaders}
            borderRadius={true}
          />
        </ExpandedWrapper>
      </AccrualBlockContainer>
    </BlockContainer>
  );
};

export default InterestAccrualBlock;
