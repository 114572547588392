import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'components/BackButton/BackButton';
import InvestorProfile from 'components/InvestorProfile/InvestorProfile';
import InvestorReports from 'components/InvestorReports/InvestorReports';
import InvestorDocuments from 'components/InvestorDocuments/InvestorDocuments';
import ReportSummaryCards from 'components/InvestorReports/ReportSummaryCards/ReportSummaryCards';
import CapitalSummarySection from 'components/InvestorReports/CapitalSummarySection/CapitalSummarySection';
import useQueryGetInvestorById from 'hooks/queries/useQueryGetInvestorById/useQueryGetInvestorById';
import useQueryInvestorSummary from 'hooks/queries/useQueryInvestorSummary/useQueryInvestorSummary';
import useQueryPortfolioSummary from 'hooks/queries/useQueryPortfolioSummary/useQueryPortfolioSummary';
import ReportButton from 'components/InvestorReports/ReportButton/ReportButton';
import { getReportEndDate, getReportStartDate } from 'utils/functions';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { reportPeriodOptions } from 'utils/MockData/investors';
import { DateRangeVariant } from 'generated/graphql';
import Layout from 'components/Layout/Layout';
import { INVESTOR } from 'utils/constants';

const ProfileSection = styled(Container)`
  padding-bottom: 3rem;
`;

const PageHeader = styled(Container)(
  ({ theme }) => css`
    color: ${theme.colors.mifBlue1};
    padding-bottom: 3rem;
    padding-top: 1rem;

    h1 {
      font-size: 3.125rem;
      font-weight: 700;
      margin-top: 1.75rem;
    }
  `
);

const ReportSectionSummaryContainer = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.colors.gray0_5};
    padding-bottom: 6rem;
  `
);

const InvestorProfileContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.gray7};
    padding-bottom: 2.25rem;
  `
);

const InvestorDocumentContainer = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.colors.gray7};
    padding-bottom: 12rem;
  `
);

const InvestorInfoHeader = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    size: 3.125rem;
    font-weight: 400 !important;
    line-height: 4.5rem;
    letter-spacing: 0.125rem;
  `
);

const InvestorReportsContainer = styled(Box)(
  ({ theme }) => css`
    background: linear-gradient(${theme.colors.gray7} 50%, ${theme.colors.gray0_5} 50%);
  `
);

const WideContainer = styled(Container)`
  max-width: 1400px;
`;

const InvestorDetails = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const { id = '0' } = params;
  const investorId = parseInt(id, 10);
  const { role, user } = useUserContext();

  useEffect(() => {
    const userInvestorIds = user?.investors?.map((x) => x.id) || [];
    if (user && role === INVESTOR && !userInvestorIds.includes(investorId)) {
      navigate('/');
    }
  }, [role, investorId, navigate, user]);

  const { refetch: refetchInvestor, data: investor } = useQueryGetInvestorById({
    variables: {
      id: investorId,
    },
  });

  const [reportPeriod, setReportPeriod] = useState(reportPeriodOptions[0].value);
  const [startDate, setStartDate] = useState(getReportStartDate(reportPeriod));
  const [endDate, setEndDate] = useState(getReportEndDate(reportPeriod));

  const reportUrl = useMemo(() => {
    // TODO: Update UI so we don't have to do this.
    // Since we only support quarterly reports, we're just using the end date.
    const start = endDate.startOf('quarter');
    const end = endDate.endOf('quarter');

    return `${
      process.env.REACT_APP_WEB_API
    }/api/investor/reports/summary?startDate=${start}&endDate=${end}&investorId=${
      investor?.investorById?.id ?? 0
    }`;
  }, [endDate, investor]);

  const { data: investorSummaryData, refetch: refetchSummaryData } = useQueryInvestorSummary({
    variables: {
      input: {
        yearMonth: getReportStartDate(reportPeriodOptions[0].value).toFormat('yyyy-MM'),
        endDate: getReportEndDate(reportPeriodOptions[0].value).toISODate(),
        variant: DateRangeVariant.Month,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const { data: portfolioSummaryData, refetch: refetchPortfolioData } = useQueryPortfolioSummary({
    variables: {
      input: {
        yearMonth: getReportStartDate(reportPeriodOptions[0].value).toFormat('yyyy-MM'),
        endDate: getReportEndDate(reportPeriodOptions[0].value).toISODate(),
        variant: DateRangeVariant.Month,
      },
    },
  });

  return (
    <Layout>
      <InvestorProfileContainer>
        <BackButton text='Back to Main' />
        <ProfileSection>
          <PageHeader>
            <InvestorInfoHeader variant='h1'>Investor Info</InvestorInfoHeader>
          </PageHeader>
          <InvestorProfile investor={investor?.investorById} refetchInvestor={refetchInvestor} />
        </ProfileSection>
      </InvestorProfileContainer>
      <InvestorDocumentContainer>
        <InvestorDocuments investor={investor?.investorById} />
      </InvestorDocumentContainer>
      <InvestorReportsContainer>
        <WideContainer>
          <InvestorReports
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            refetchSummary={refetchSummaryData}
            refetchPortfolio={refetchPortfolioData}
          />
        </WideContainer>
      </InvestorReportsContainer>
      <ReportSectionSummaryContainer>
        <WideContainer>
          <ReportSummaryCards
            investorId={investor?.investorById?.id}
            investorSummary={investorSummaryData}
            portfolioSummary={portfolioSummaryData}
          />
          <CapitalSummarySection
            reportPeriod={reportPeriod}
            startDate={startDate}
            endDate={endDate}
            investorName={investor?.investorById?.memberName}
            investorSummary={investorSummaryData}
          />
        </WideContainer>
        <Grid container alignItems='center' flexDirection='column' sx={{ mt: '1rem' }}>
          <ReportButton buttonText='Export Report' type='download' href={reportUrl} />
        </Grid>
      </ReportSectionSummaryContainer>
    </Layout>
  );
};

export default InvestorDetails;
