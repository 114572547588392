import React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import { ifNotProp } from 'styled-tools';
import AccordionDetails from '@mui/material/AccordionDetails';
import { css, styled as styledMui } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandedDetails from 'components/ExpandableList/ExpandedDetails/ExpandedDetails';
import { UpdatedInvestor, UpdatedLoan, ObjectKey } from 'types';
import { columnMapping, LOANS } from 'utils/constants';
import { formatValue } from 'utils/functions';

export const StyledAccordion = styledMui(Accordion)(
  ({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.mifGray16};
    &.MuiAccordion-rounded {
      box-shadow: none;
      width: 100%;

      &:before {
        background-color: transparent;
      }
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      &.Mui-expanded {
        border-bottom: 0.0625rem solid ${theme.colors.mifGray1};
        min-height: 48px;
      }
    }
  `
);

export const Summary = styled(AccordionSummary)(
  ({ theme }) => css`
    h5 {
      margin: 0;
      color: ${theme.colors.gray7};
      font-family: 'Teko';
      font-size: 1.625rem;
      font-weight: 600;
    }

    .MuiAccordionSummary-content {
      ${theme.breakpoints.down('md')} {
        flex-direction: column;
      }

      &.Mui-expanded {
        margin: 12px 0;
      }
    }
  `
);

export const NameColumn = styled(Link)<{ $final: boolean }>(
  ({ theme }) => css`
    text-decoration: none;
    ${theme.breakpoints.up('md')} {
      width: 16rem;
    }

    ${ifNotProp(
      '$final',
      css`
        color: ${theme.colors.mifBlue1};

        &:hover {
          text-decoration: underline;
        }
      `
    )}
  `
);

export const DataColumns = styled('div')`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;

export const Label = styledMui(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    font-size: 0.625rem;
    letter-spacing: 0.5px;
    margin-right: 0.75rem;
    opacity: 0.5;
    text-transform: uppercase;
  `
);

export const ColumnLabel = styledMui(Label)(
  ({ theme }) => css`
    color: ${theme.colors.gray7};
    opacity: 0.5;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.75rem;
  `
);

const ValueContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${theme.breakpoints.up('md')} {
      width: 9rem;

      &:nth-child(2) {
        width: 5rem;
      }
    }
  `
);

export const Value = styledMui(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.gray7};
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1rem;
  `
);

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;

interface ListAccordionProps {
  name: string;
  headers: Array<string>;
  item: UpdatedLoan | UpdatedInvestor;
  id?: number;
  days?: Array<number | string>;
  endOfList: boolean;
  expandedList: Array<string>;
  handleChange: (panel: string) => (event: React.SyntheticEvent) => void;
  totalHeaders: Array<string>;
  details?: object | object[];
  detailsTotal?: object | object[];
  type: string;
  rowHeaders: Array<string>;
  tallyHeaders: Array<string>;
  expandedDetailsComponent?: React.ElementType;
}

type Props = ListAccordionProps;

const ListAccordion = ({
  days,
  details,
  detailsTotal,
  endOfList,
  expandedList,
  headers,
  name,
  handleChange,
  item,
  totalHeaders,
  type,
  rowHeaders,
  tallyHeaders,
  id,
}: Props): JSX.Element => {
  const detailUrl = type === LOANS ? `/loan-details/${id}` : `/investors/${id}`;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledAccordion
      expanded={expandedList?.includes(name)}
      onChange={handleChange(name)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Summary expandIcon={<ExpandMoreIcon />}>
        {id && (
          <NameColumn as={endOfList ? 'div' : Link} $final={endOfList} to={detailUrl}>
            <h5>{name}</h5>
          </NameColumn>
        )}
        {!id && (
          <NameColumn as={endOfList ? 'div' : Link} $final={endOfList}>
            <h5>{name}</h5>
          </NameColumn>
        )}

        {Object.entries(item).map(([label, value]) => {
          const labelInfo = label as ObjectKey;
          let textLabel = label;

          if (label === 'interestTotal') {
            textLabel = 'Interest Total';
          } else if (label === 'interestEarned') {
            textLabel = 'Interest Earned';
          } else if (label === 'totalCapitalAtWork') {
            textLabel = 'Total Capital at Work';
          }

          if (!endOfList && !rowHeaders.includes(textLabel)) {
            return;
          }

          if (endOfList) {
            return (
              <DataColumns key={label}>
                <Grid
                  item
                  xs={12}
                  md={columnMapping[labelInfo]}
                  order={endOfList && textLabel === 'Total Capital at Work' ? { md: 1 } : { md: 2 }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                    <ColumnLabel>{textLabel}</ColumnLabel>
                    <Value>{formatValue(label, value)}</Value>
                  </Box>
                </Grid>
              </DataColumns>
            );
          }

          if (!isMobile) {
            return (
              <ValueContainer key={label}>
                <Value>{formatValue(label, value)}</Value>
              </ValueContainer>
            );
          }

          return (
            <ValueContainer key={label}>
              <ColumnLabel>{textLabel}</ColumnLabel>
              <Value>{formatValue(label, value)}</Value>
            </ValueContainer>
          );
        })}
      </Summary>
      <StyledAccordionDetails>
        {endOfList ? (
          <ExpandedDetails headers={totalHeaders} days={days} details={detailsTotal} />
        ) : (
          <ExpandedDetails headers={headers} days={days} details={details} />
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ListAccordion;
