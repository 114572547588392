import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AUTH_TOKEN } from 'utils/constants';

export interface AppContextType {
  token: string | null;
  setToken: (token: string | null) => void;
}

const defaultContext: Partial<AppContextType> = {
  token: null,
};

const AppContext = createContext<AppContextType>(defaultContext as AppContextType);

const getToken = () => {
  return localStorage?.getItem(AUTH_TOKEN) || null;
};

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const [token, setTokenState] = useState<string | null>(getToken());

  const setToken = useCallback((newToken: string | null) => {
    setTokenState(newToken);
    if (newToken) {
      localStorage.setItem(AUTH_TOKEN, newToken);
    } else {
      localStorage.removeItem(AUTH_TOKEN);
    }
  }, []);

  const memoizedState = useMemo(() => {
    return {
      token: token,
      setToken: setToken,
    };
  }, [setToken, token]);

  return <AppContext.Provider value={memoizedState}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }

  return context;
};

export default useAppContext;
