import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import ListAccordion from './ListAccordion/ListAccordion';
import TitledContainer from 'components/TitledContainer/TitledContainer';
import { Investor, Loan, UpdatedInvestor, UpdatedLoan } from 'types';
import omit from 'lodash.omit';

const ListContainer = styled(Container)`
  margin-bottom: 8rem;
`;

export const AccordionContainer = styled('div')`
  width: 100%;
`;

export const ButtonContainer = styled('div')`
  color: white;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;

  span {
    padding: 0 0.5rem;
  }
`;

export const ToggleButton = styled(Button)`
  color: white;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;
  text-transform: capitalize;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

interface ExpandableListProps {
  title: string;
  days: Array<number | string>;
  details: object[] | object[][] | undefined;
  detailsTotal?: object | object[];
  headers: Array<string>;
  items: Array<Loan> | Array<Investor> | undefined;
  totalHeaders: Array<string>;
  rowHeaders: Array<string>;
  tallyHeaders: Array<string>;
  totalItem?: Loan | Investor;
  fixOnScroll?: boolean;
}

type Props = ExpandableListProps;

const ExpandableList = ({
  days,
  details,
  detailsTotal,
  headers,
  items,
  title,
  totalHeaders,
  rowHeaders,
  tallyHeaders,
  totalItem,
  fixOnScroll,
}: Props): JSX.Element => {
  const [expandedList, setExpandedList] = useState<Array<string>>([]);

  const listItems = useMemo(
    () => (totalItem ? [...(items || []), totalItem] : items),
    [totalItem, items]
  );

  const namesArray = listItems
    ? listItems.map((item) => {
        return item?.name;
      })
    : [];

  const handleChange = (panel: string) => () => {
    if (expandedList.includes(panel)) {
      const updatedList = expandedList.filter((name) => name.indexOf(panel) === -1);
      setExpandedList(updatedList);
    } else {
      setExpandedList([...expandedList, panel]);
    }
  };

  return (
    <ListContainer>
      <TitledContainer
        noPadding
        bigTitle
        title={title}
        subtitles={rowHeaders}
        fixOnScroll={fixOnScroll}
        headerChildren={
          <ButtonContainer>
            <ToggleButton disableRipple={true} onClick={() => setExpandedList([])} variant='text'>
              Close All
            </ToggleButton>
            <span>|</span>
            <ToggleButton
              disableRipple={true}
              onClick={() => setExpandedList(namesArray)}
              variant='text'
            >
              Open All
            </ToggleButton>
          </ButtonContainer>
        }
      >
        <AccordionContainer>
          {listItems && details
            ? listItems.map((item, index) => {
                const { name, ...updatedItem } = item;
                const finalItem = listItems.at(-1);

                return (
                  <ListAccordion
                    key={index}
                    name={name}
                    item={
                      omit(updatedItem, ['isGP', 'capital', '__typename']) as
                        | UpdatedInvestor
                        | UpdatedLoan
                    }
                    days={days}
                    headers={headers}
                    details={details[index]}
                    detailsTotal={detailsTotal}
                    endOfList={item === finalItem}
                    expandedList={expandedList}
                    handleChange={handleChange}
                    totalHeaders={totalHeaders}
                    id={item.id}
                    type={title}
                    tallyHeaders={tallyHeaders}
                    rowHeaders={rowHeaders}
                  />
                );
              })
            : '-'}
        </AccordionContainer>
      </TitledContainer>
    </ListContainer>
  );
};

export default ExpandableList;
