import React, { useState, useEffect } from 'react';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Wrapper = styled('div')<{ borderless?: boolean; dark?: boolean }>(
  ({ theme, borderless, dark }) => css`
    background-color: ${dark ? theme.colors.gray7 : theme.palette.common.white};
    border: ${borderless ? 'transparent' : `0.0625rem solid ${theme.colors.mifGray8}`};
    height: 100%;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
  `
);

const Header = styled('div')<{ bigTitle?: boolean; fixed?: boolean }>(
  ({ bigTitle, fixed }) => css`
    position: ${fixed ? 'sticky' : 'absolute'};
    top: ${fixed ? '5rem' : '0'};
    color: white;
    z-index: 10;
    background-color: black;
    font-family: 'Teko';
    font-size: ${bigTitle ? '2rem' : '1.125rem'};
    line-height: 1.44;
    letter-spacing: 0.1875rem;
    font-weight: ${bigTitle ? '600' : '400'};
    text-transform: uppercase;
    padding: 0 1rem;
    width: calc(100% - 2rem);
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 4rem;
  `
);

const Title = styled('div')`
  width: 16rem;
`;

const BodyContainer = styled('div')<{
  border?: boolean;
  noPadding?: boolean;
  fixedHeader?: boolean;
}>(
  ({ border, noPadding, fixedHeader }) => css`
    max-width: 100%;
    display: flex;
    position: relative;
    top: ${fixedHeader ? '0' : '4rem'};
    padding: ${noPadding ? '0' : border ? '2rem 2rem 2rem 0' : '2rem'};
    background: inherit;
    height: calc(100% - 8rem);
  `
);

const Border = styled('div')<{ border?: boolean; noPadding?: boolean }>(
  ({ theme, border, noPadding }) => css`
    border-left: ${border ? `0.125rem solid ${theme.colors.mifGray15}` : 'none'};
    padding-left: ${noPadding ? '0' : border ? '3rem' : '0'};
    width: ${border ? 'calc(100% - 3rem)' : '100%'};
  `
);

const SubtitleContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;

const Subtitle = styled('p')(
  ({ theme }) => css`
    margin: 0;
    font-size: 1rem;
    font-weight: 400;

    ${theme.breakpoints.up('md')} {
      width: 9rem;
      &:first-child {
        width: 5rem;
      }
    }
  `
);

type Props = {
  title?: string;
  subtitles?: string[];
  children?: React.ReactNode;
  headerChildren?: React.ReactNode;
  borderless?: boolean;
  dark?: boolean;
  divider?: boolean;
  noPadding?: boolean;
  bigTitle?: boolean;
  fixOnScroll?: boolean;
};

const TitledContainer: React.FC<Props> = ({
  title,
  children,
  subtitles,
  headerChildren,
  borderless,
  dark,
  divider,
  noPadding,
  bigTitle,
  fixOnScroll,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Wrapper borderless={borderless} dark={dark}>
      <Header bigTitle={bigTitle} fixed={fixOnScroll}>
        <Title>{title}</Title>
        {subtitles && !isMobile && (
          <SubtitleContainer>
            {subtitles.map((subtitle) => (
              <Subtitle key={subtitle}>{subtitle}</Subtitle>
            ))}
          </SubtitleContainer>
        )}
        {headerChildren}
      </Header>
      <BodyContainer noPadding={noPadding} border={divider} fixedHeader={fixOnScroll}>
        <Border noPadding={noPadding} border={divider}>
          {children}
        </Border>
      </BodyContainer>
    </Wrapper>
  );
};

export default TitledContainer;
