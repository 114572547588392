import {
  VerifyEmailDocument,
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from 'generated/graphql';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';

type Data = VerifyEmailMutation;
type Variables = VerifyEmailMutationVariables;

export const useMutationVerifyEmail = (
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> => {
  return useMutation<Data, Variables>(VerifyEmailDocument, options);
};

export default useMutationVerifyEmail;
