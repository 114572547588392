import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Navigate } from 'react-router-dom';
import { UserContextProvider } from 'contexts/UserContext/UserContext';
import { currentDate } from 'utils/functions';
import jwt_decode from 'jwt-decode';
import { JwtToken } from 'types';
import useAppContext from 'contexts/AppContext/AppContext';

interface ProtectedRouteProps {
  children: JSX.Element;
}

type Props = ProtectedRouteProps;

const ProtectedRoute = ({ children }: Props): JSX.Element => {
  const [expired, setExpired] = useState(false);
  const { token } = useAppContext();
  const authenticated = !!token;

  useEffect(() => {
    if (authenticated) {
      const decodedToken: JwtToken = jwt_decode(token);
      const expirationDate = decodedToken?.exp; // will be in seconds

      if (expirationDate && expirationDate <= currentDate.toSeconds()) {
        setExpired(true);
      }
    }
  }, [authenticated, expired, token]);

  if (!authenticated || expired) {
    return <Navigate to='/login' />;
  }

  return <UserContextProvider>{children}</UserContextProvider>;
};

export default ProtectedRoute;
