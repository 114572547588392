import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  InitiateVerifyEmailDocument,
  InitiateVerifyEmailMutation,
  InitiateVerifyEmailMutationVariables,
} from 'generated/graphql';

type Data = InitiateVerifyEmailMutation;
type Variables = InitiateVerifyEmailMutationVariables;

export const useMutationInitiateVerifyEmail = (
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> => {
  return useMutation<Data, Variables>(InitiateVerifyEmailDocument, options);
};

export default useMutationInitiateVerifyEmail;
