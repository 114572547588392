import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import imgLogo from 'assets/images/logo/mif-temp-logo-small.png';
import NavigationLink from 'components/NavigationLink/NavigationLink';
import AccountMenu from './AccountMenu/AccountMenu';
import FundsMenu from './FundsMenu/FundsMenu';
import { MenuItemDef } from './types';

const MenuContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  max-width: 40rem;
`;

const StyledMenu = styled(Menu)`
  a,
  button {
    margin: 0;
  }
`;

const mainMenuItems: MenuItemDef[] = [
  { id: 'portfolio', to: '/', label: 'Portfolio Summary' },
  { id: 'loan-clients', to: '/loan-details/4', label: 'Loan Details' },
  //TODO - get first item from loanDetails list instead of hardcoded clear-networks
];

const Header = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position='sticky' color='inherit'>
      <Toolbar
        disableGutters
        variant='dense'
        sx={{ justifyContent: 'space-between', paddingRight: '1.5rem' }}
      >
        <Typography variant='h6' component='div' sx={{ marginRight: '3rem' }}>
          <Link to={'/'}>
            <img src={imgLogo} alt='MILLENNIUM INFRASTRUCTURE FUND' />
          </Link>
        </Typography>
        {!isMobile && (
          <>
            {' '}
            <MenuContainer>
              {mainMenuItems.map((menuItem) => (
                <NavigationLink key={menuItem.id} linkTo={menuItem.to} linkText={menuItem.label} />
              ))}
              <FundsMenu />
            </MenuContainer>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccountMenu />
            </Box>
          </>
        )}
        {isMobile && (
          <IconButton aria-label='main menu' onClick={handleClick}>
            <MenuIcon />
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {mainMenuItems.map((menuItem) => (
                <MenuItem key={menuItem.id} onClick={handleClose}>
                  <NavigationLink
                    key={menuItem.id}
                    linkTo={menuItem.to}
                    linkText={menuItem.label}
                  />
                </MenuItem>
              ))}
              <MenuItem onClick={handleClose}>
                <FundsMenu />
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <AccountMenu />
              </MenuItem>
            </StyledMenu>
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
