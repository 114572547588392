import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  InvestorSummaryDocument,
  InvestorSummaryQuery,
  InvestorSummaryQueryVariables,
} from 'generated/graphql';

type Data = InvestorSummaryQuery;
type Variables = InvestorSummaryQueryVariables;

function useQueryInvestorSummary(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(InvestorSummaryDocument, options);
}

export default useQueryInvestorSummary;
