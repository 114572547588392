import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ResponsiveText36 = styled(Typography)`
  font-family: 'Teko';
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 144%;
  text-transform: uppercase;
  color: black;
`;

export default ResponsiveText36;
