import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  InvestorDocumentsDocument,
  InvestorDocumentsQuery,
  InvestorDocumentsQueryVariables,
} from 'generated/graphql';

type Data = InvestorDocumentsQuery;
type Variables = InvestorDocumentsQueryVariables;

function useQueryInvestorDocuments(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(InvestorDocumentsDocument, options);
}

export default useQueryInvestorDocuments;
