import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, SxProps } from '@mui/material/styles';

const StyledPanel = styled('div')`
  width: 100%;
`;

const StyledMobileFriendlyBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  innerSx?: SxProps;
}

type Props = TabPanelProps;

const TabPanel = (props: Props) => {
  const { children, value, index, innerSx = { paddingTop: '3rem', paddingBottom: '2rem' }, ...other } = props;

  return (
    <StyledPanel
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledMobileFriendlyBox sx={innerSx}>
          <Typography component='div'>{children}</Typography>
        </StyledMobileFriendlyBox>
      )}
    </StyledPanel>
  );
};

export default TabPanel;
