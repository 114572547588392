import { styled } from '@mui/material/styles';

const Form = styled('form')`
  padding: 0 1.5rem;
  max-width: 21rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: -2rem;
  }
`;

export default Form;
