import React from 'react';
import get from 'lodash.get';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { formatCurrency } from 'utils/functions';
import { InvestorSummaryQuery } from 'generated/graphql';

const CapitalSummaryContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    padding: 1.5rem 2rem;
  `
);

const CapitalListRow = styled(Box)(
  ({ theme }) => css`
    display: flex;
    overflow-y: scroll;
    border-bottom: solid 0.0625rem ${theme.colors.mifGray12};
    padding: 1.5rem 0;
  `
);

const CapitalInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const CapitalInfoSubBox = styled(CapitalInfoBox)`
  margin-left: 3rem;
`;

const CapitalValue = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    color: ${theme.colors.fadedGray60};
    font-family: Teko;
    font-size: 2rem;
    justify-content: flex-end;
    align-items: center;
    letter-spacing: 0.0625rem;
  `
);

const CapitalValueTotal = styled(CapitalValue)(
  ({ theme }) => css`
    color: ${theme.colors.gray7};
    font-size: 2.5rem;
  `
);

const StyledHeader = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.gray7};
    font-family: Teko;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 0.0625rem;
    padding-bottom: 0.375rem;
  `
);

const StyledSubtext = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.fadedGray40};
    font-size: 1rem;
    line-height: 1.125rem;
  `
);

interface CapitalSummarySectionProps {
  reportPeriod: string;
  startDate: DateTime;
  endDate: DateTime;
  investorName?: string | null | undefined;
  investorSummary?: InvestorSummaryQuery | undefined;
}

type Props = CapitalSummarySectionProps;

const CapitalSummarySection = ({
  reportPeriod,
  startDate,
  endDate,
  investorName,
  investorSummary,
}: Props): JSX.Element => {
  const currentInvestor = investorSummary?.investorSummary?.investorsData.investors.filter(
    (item) => item?.investor?.memberName === investorName
  );
  const dailyData = get(currentInvestor, '[0].dailyInvestorData');
  const totals = get(currentInvestor, '[0].investorTotals');
  const endDateData = dailyData?.at(-1);

  return (
    <CapitalSummaryContainer>
      <CapitalListRow>
        <CapitalInfoBox>
          <StyledHeader>Opening Capital Amount</StyledHeader>
          <StyledSubtext>
            {`${reportPeriod} | 
              ${startDate.toLocaleString(DateTime.DATE_FULL)} - 
              ${endDate.toLocaleString(DateTime.DATE_FULL)}`}
          </StyledSubtext>
        </CapitalInfoBox>
        <CapitalValue>{formatCurrency(dailyData?.[0]?.committedAmount)}</CapitalValue>
      </CapitalListRow>
      <CapitalListRow>
        <CapitalInfoSubBox>
          <StyledHeader>Capital Contributions</StyledHeader>
        </CapitalInfoSubBox>
        <CapitalValue>{formatCurrency(totals?.nonRollOverAdjustments)}</CapitalValue>
      </CapitalListRow>
      <CapitalListRow>
        <CapitalInfoSubBox>
          <StyledHeader>Allocation of Interested income for</StyledHeader>
          <StyledSubtext>
            {`${reportPeriod} | 
              ${startDate.toLocaleString(DateTime.DATE_FULL)} - 
              ${endDate.toLocaleString(DateTime.DATE_FULL)}`}
          </StyledSubtext>
        </CapitalInfoSubBox>
        <CapitalValue>{formatCurrency(totals?.interestAmount)}</CapitalValue>
      </CapitalListRow>
      <CapitalListRow>
        <CapitalInfoSubBox>
          <StyledHeader>Distributions</StyledHeader>
        </CapitalInfoSubBox>
        <CapitalValue>
          {totals?.distributions !== null || totals?.distributions !== 'undefined'
            ? formatCurrency(totals?.distributions)
            : '-'}
        </CapitalValue>
      </CapitalListRow>
      <CapitalListRow sx={{ borderBottom: 0 }}>
        <CapitalInfoBox>
          <StyledHeader>Closing Capital At</StyledHeader>
          <StyledSubtext>
            {`${reportPeriod} | ${endDate.toLocaleString(DateTime.DATE_FULL)}`}
          </StyledSubtext>
        </CapitalInfoBox>
        <CapitalValueTotal>{formatCurrency(endDateData?.committedAmount)}</CapitalValueTotal>
      </CapitalListRow>
    </CapitalSummaryContainer>
  );
};

export default CapitalSummarySection;
