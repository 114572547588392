import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Time: any;
};

export type Activity = {
  __typename?: 'Activity';
  amount?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  id: Scalars['String'];
  text: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type AddLoanDocumentResponse = {
  __typename?: 'AddLoanDocumentResponse';
  documents?: Maybe<Array<Maybe<DocumentDownloadData>>>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AddLoanNoteResponse = {
  __typename?: 'AddLoanNoteResponse';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  city?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AllInvestorsData = {
  __typename?: 'AllInvestorsData';
  investors: Array<InvestorWithDailyInvestorData>;
  total: TotalInvestorData;
};

export type AllLoansData = {
  __typename?: 'AllLoansData';
  loans: Array<LoanWithDailyLoanData>;
  total: AllLoansTotal;
};

export type AllLoansTotal = {
  __typename?: 'AllLoansTotal';
  dailyLoanData: Array<DailyTotalData>;
  interestTotal: Scalars['Float'];
  totalCapitalAtWork: Scalars['Float'];
};

export type BillingInput = {
  accountNumber?: InputMaybe<Scalars['Float']>;
  bankName?: InputMaybe<Scalars['String']>;
  investorId: Scalars['Int'];
  routingNumber?: InputMaybe<Scalars['Float']>;
};

export type CapitalAdjustment = {
  __typename?: 'CapitalAdjustment';
  adjustedDate?: Maybe<Scalars['Date']>;
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type CapitalData = {
  __typename?: 'CapitalData';
  capitalCommitment: Scalars['Float'];
  deployedCapital: Scalars['Float'];
};

export type Client = {
  __typename?: 'Client';
  clientName?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  loanInfos?: Maybe<Array<Maybe<LoanInfo>>>;
};

export type CommittedInput = {
  id: Scalars['Int'];
  totalCommitted?: InputMaybe<Scalars['Float']>;
};

export type DailyInvestorData = {
  __typename?: 'DailyInvestorData';
  averageRate?: Maybe<Scalars['Float']>;
  committedAdjustmentsOnDay?: Maybe<Array<Maybe<CapitalAdjustment>>>;
  committedAmount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  distributions?: Maybe<Scalars['Float']>;
  gpCommitted?: Maybe<Scalars['Float']>;
  hasCommittedAdjustments?: Maybe<Scalars['Boolean']>;
  hasInterestAdjustments?: Maybe<Scalars['Boolean']>;
  interestAdjustmentsOnDay?: Maybe<Array<InterestAdjustment>>;
  interestAmount?: Maybe<Scalars['Float']>;
  interestRollOver?: Maybe<Scalars['Boolean']>;
  isStarted?: Maybe<Scalars['Boolean']>;
  rollOverPeriods?: Maybe<Array<Maybe<RollOverPeriod>>>;
};

export type DailyLoanData = {
  __typename?: 'DailyLoanData';
  committedAdjustmentsOnDay?: Maybe<Array<LoanCommittedAdjustment>>;
  committedAmount: Scalars['Float'];
  date: Scalars['String'];
  deployedAdjustmentsOnDay?: Maybe<Array<DeployedAdjustment>>;
  deployedAmount: Scalars['Float'];
  hasCommittedAdjustments: Scalars['Boolean'];
  hasDeployedAdjustments: Scalars['Boolean'];
  hasLateFees: Scalars['Boolean'];
  interestAmount: Scalars['Float'];
  isEnded?: Maybe<Scalars['Boolean']>;
  isStarted: Scalars['Boolean'];
  isWithSofrRate?: Maybe<Scalars['Boolean']>;
  lateFeesOnDay?: Maybe<Array<LateFee>>;
  rate: Scalars['Float'];
};

export type DailyTotalData = {
  __typename?: 'DailyTotalData';
  averageRate: Scalars['Float'];
  committedAmount: Scalars['Float'];
  date: Scalars['String'];
  deployedAmount: Scalars['Float'];
  interestAmount: Scalars['Float'];
  isStarted: Scalars['Boolean'];
};

export enum DateRangeVariant {
  Month = 'month',
  Quarter = 'quarter',
}

export type DeployedAdjustment = {
  __typename?: 'DeployedAdjustment';
  adjustmentAmount?: Maybe<Scalars['Float']>;
  adjustmentDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  loanInfo?: Maybe<LoanInfo>;
  notes?: Maybe<Scalars['String']>;
};

export type DocumentDownloadData = {
  __typename?: 'DocumentDownloadData';
  filename: Scalars['String'];
  loanId: Scalars['Int'];
  s3Url: Scalars['String'];
  uploadDate: Scalars['String'];
};

export type EmailInput = {
  allInvestors: Scalars['Boolean'];
  documentId: Scalars['Int'];
};

export type InterestAdjustment = {
  __typename?: 'InterestAdjustment';
  adjustedDate?: Maybe<Scalars['Date']>;
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type InterestData = {
  __typename?: 'InterestData';
  blendedInterestRate: Scalars['Float'];
  dailyInterestExpense: Scalars['Float'];
  interestAmount: Scalars['Float'];
  totalInterestExpense: Scalars['Float'];
};

export type InterestEarned = {
  __typename?: 'InterestEarned';
  blendedRate?: Maybe<Scalars['Float']>;
  capital?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['Float']>;
  loanInterest?: Maybe<Scalars['Float']>;
};

export type Investor = {
  __typename?: 'Investor';
  accountNumber?: Maybe<Scalars['Float']>;
  addressInfos?: Maybe<Array<AddressInfo>>;
  bankName?: Maybe<Scalars['String']>;
  committedCapital?: Maybe<Scalars['Float']>;
  committedCapitalAdjustments?: Maybe<Array<CapitalAdjustment>>;
  earnedInterestAdjustments?: Maybe<Array<InterestAdjustment>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  interestRollOver?: Maybe<Scalars['Boolean']>;
  isGP?: Maybe<Scalars['Boolean']>;
  memberName?: Maybe<Scalars['String']>;
  memberType?: Maybe<Scalars['String']>;
  rollOverPeriods?: Maybe<Array<RollOverPeriod>>;
  routingNumber?: Maybe<Scalars['Float']>;
  users?: Maybe<Array<User>>;
};

export type InvestorId = {
  __typename?: 'InvestorId';
  id: Scalars['Int'];
};

export type InvestorS3Document = {
  __typename?: 'InvestorS3Document';
  allInvestors?: Maybe<Scalars['Boolean']>;
  documentDate?: Maybe<Scalars['Date']>;
  documentName?: Maybe<Scalars['String']>;
  documentS3Url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  investors?: Maybe<Array<InvestorId>>;
  investorsViewed?: Maybe<Array<InvestorId>>;
};

export type InvestorSummary = {
  __typename?: 'InvestorSummary';
  id: Scalars['String'];
  investorsData: AllInvestorsData;
  monthlySummary: SummaryReport;
};

export type InvestorSummaryInput = {
  endDate?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<DateRangeVariant>;
  yearMonth: Scalars['String'];
};

export type InvestorTotals = {
  __typename?: 'InvestorTotals';
  committedAdjustmentsOnDay?: Maybe<Array<Maybe<CapitalAdjustment>>>;
  distributions?: Maybe<Scalars['Float']>;
  gpCommitted?: Maybe<Scalars['Float']>;
  interestAdjustmentsOnDay?: Maybe<Array<InterestAdjustment>>;
  interestAmount: Scalars['Float'];
  lastCommittedAmount: Scalars['Float'];
  nonRollOverAdjustments?: Maybe<Scalars['Float']>;
};

export type InvestorViewedInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type InvestorWithDailyInvestorData = {
  __typename?: 'InvestorWithDailyInvestorData';
  dailyInvestorData: Array<DailyInvestorData>;
  investor: Investor;
  investorTotals: InvestorTotals;
};

export type LateFee = {
  __typename?: 'LateFee';
  feeAmount?: Maybe<Scalars['Float']>;
  feeDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  loanInfo?: Maybe<LoanInfo>;
  notes?: Maybe<Scalars['String']>;
};

export type LoanActivity = {
  __typename?: 'LoanActivity';
  activity?: Maybe<Array<Maybe<Activity>>>;
  id: Scalars['Int'];
};

export type LoanCommittedAdjustment = {
  __typename?: 'LoanCommittedAdjustment';
  adjustmentAmount: Scalars['Float'];
  adjustmentDate: Scalars['Date'];
  id: Scalars['Int'];
  loanInfo?: Maybe<LoanInfo>;
  notes?: Maybe<Scalars['String']>;
};

export type LoanDocument = {
  __typename?: 'LoanDocument';
  createdAt: Scalars['String'];
  documentS3Bucket: Scalars['String'];
  documentS3MimeType: Scalars['String'];
  documentS3Url: Scalars['String'];
  id: Scalars['String'];
  loanId: Scalars['String'];
};

export type LoanInfo = {
  __typename?: 'LoanInfo';
  billDate?: Maybe<Scalars['Date']>;
  client?: Maybe<Client>;
  clientId: Scalars['Int'];
  commitmentAmount?: Maybe<Scalars['Float']>;
  committedAdjustments?: Maybe<Array<Maybe<LoanCommittedAdjustment>>>;
  compoundInterest?: Maybe<Scalars['Boolean']>;
  deployedAdjustments?: Maybe<Array<DeployedAdjustment>>;
  deployedAmount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  interestRate?: Maybe<Scalars['Float']>;
  lateFees?: Maybe<Array<Maybe<LateFee>>>;
  loanDocuments?: Maybe<Array<Maybe<LoanDocument>>>;
  loanDuration?: Maybe<Scalars['String']>;
  loanNotes?: Maybe<Array<Maybe<LoanNote>>>;
  sofrRate?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type LoanNote = {
  __typename?: 'LoanNote';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  loanInfo?: Maybe<LoanInfo>;
  loanInfoId?: Maybe<Scalars['Int']>;
  note: Scalars['String'];
};

export type LoanTotals = {
  __typename?: 'LoanTotals';
  blendedInterestRate: Scalars['Float'];
  interestAmount: Scalars['Float'];
  lastCommittedAmount: Scalars['Float'];
  lastDeployedAmount: Scalars['Float'];
};

export type LoanWithDailyLoanData = {
  __typename?: 'LoanWithDailyLoanData';
  dailyLoanData: Array<DailyLoanData>;
  loanInfo: LoanInfo;
  loanTotals: LoanTotals;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  emailVerified?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  mfaMethod?: Maybe<Scalars['String']>;
  mfaSid?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type MonthlySummaryReport = {
  __typename?: 'MonthlySummaryReport';
  capitalData: CapitalData;
  interestData: InterestData;
  month: Scalars['String'];
  year: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addLoanDocument?: Maybe<AddLoanDocumentResponse>;
  addLoanNote?: Maybe<AddLoanNoteResponse>;
  initiateVerifyEmail?: Maybe<Scalars['String']>;
  initiateVerifyPhone?: Maybe<Scalars['String']>;
  sendInvestorDocumentEmail?: Maybe<UpdateResponse>;
  sendPasswordReset?: Maybe<SendPasswordResetResponse>;
  set2FAMethod?: Maybe<LoginResponse>;
  updateBilling?: Maybe<UpdateResponse>;
  updateInvestorDocumentsViewed?: Maybe<UpdateResponse>;
  updatePasswordWithToken?: Maybe<UpdatePasswordWithToken>;
  updateTotalCommitted?: Maybe<UpdateResponse>;
  validate2FACode?: Maybe<LoginResponse>;
  validateUser?: Maybe<LoginResponse>;
  verifyEmail?: Maybe<LoginResponse>;
  verifyPhone?: Maybe<LoginResponse>;
};

export type MutationAddLoanDocumentArgs = {
  documentBlob: Scalars['String'];
  documentName: Scalars['String'];
  documentType: Scalars['String'];
  loanInfoId: Scalars['Int'];
};

export type MutationAddLoanNoteArgs = {
  loanId?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationInitiateVerifyEmailArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type MutationInitiateVerifyPhoneArgs = {
  phone?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type MutationSendInvestorDocumentEmailArgs = {
  input: EmailInput;
};

export type MutationSendPasswordResetArgs = {
  email?: InputMaybe<Scalars['String']>;
  isNewUser?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSet2FaMethodArgs = {
  method?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateBillingArgs = {
  input: BillingInput;
};

export type MutationUpdateInvestorDocumentsViewedArgs = {
  input?: InputMaybe<InvestorViewedInput>;
};

export type MutationUpdatePasswordWithTokenArgs = {
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type MutationUpdateTotalCommittedArgs = {
  input?: InputMaybe<Array<InputMaybe<CommittedInput>>>;
};

export type MutationValidate2FaCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type MutationValidateUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationVerifyEmailArgs = {
  code?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type MutationVerifyPhoneArgs = {
  code?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type PortfolioSummary = {
  __typename?: 'PortfolioSummary';
  id: Scalars['String'];
  loansData: AllLoansData;
  monthlySummary: MonthlySummaryReport;
};

export type PortfolioSummaryInput = {
  endDate?: InputMaybe<Scalars['String']>;
  loanId?: InputMaybe<Scalars['Int']>;
  variant?: InputMaybe<DateRangeVariant>;
  yearMonth: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allCalculationsDaily?: Maybe<AllInvestorsData>;
  allLoanCalculationsDaily?: Maybe<AllLoansData>;
  client?: Maybe<Client>;
  clientById?: Maybe<Client>;
  clients?: Maybe<Array<Client>>;
  dailyInvestorData?: Maybe<Array<Maybe<DailyInvestorData>>>;
  investor?: Maybe<Investor>;
  investorById?: Maybe<Investor>;
  investorDocuments: Array<InvestorS3Document>;
  investorSummary?: Maybe<InvestorSummary>;
  investors?: Maybe<Array<Investor>>;
  investorsTotalCommitted?: Maybe<Array<Maybe<TotalCommitted>>>;
  loanActivity?: Maybe<LoanActivity>;
  portfolioSummary?: Maybe<PortfolioSummary>;
  uploadedDocuments: Array<Maybe<DocumentDownloadData>>;
  user?: Maybe<User>;
  userRoles?: Maybe<Array<UserRole>>;
  users?: Maybe<Array<User>>;
};

export type QueryClientArgs = {
  clientName?: InputMaybe<Scalars['String']>;
};

export type QueryClientByIdArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryDailyInvestorDataArgs = {
  investorId?: InputMaybe<Scalars['Int']>;
};

export type QueryInvestorArgs = {
  memberName?: InputMaybe<Scalars['String']>;
};

export type QueryInvestorByIdArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryInvestorDocumentsArgs = {
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryInvestorSummaryArgs = {
  input?: InputMaybe<InvestorSummaryInput>;
  investorName?: InputMaybe<Scalars['String']>;
};

export type QueryInvestorsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryLoanActivityArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryPortfolioSummaryArgs = {
  input: PortfolioSummaryInput;
};

export type QueryUploadedDocumentsArgs = {
  loanInfoId: Scalars['Int'];
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
};

export type RollOverPeriod = {
  __typename?: 'RollOverPeriod';
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
};

export type SendPasswordResetResponse = {
  __typename?: 'SendPasswordResetResponse';
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SummaryCapitalData = {
  __typename?: 'SummaryCapitalData';
  committedCapital: Scalars['Float'];
  gpCapital: Scalars['Float'];
};

export type SummaryInterestData = {
  __typename?: 'SummaryInterestData';
  interestAmount: Scalars['Float'];
};

export type SummaryReport = {
  __typename?: 'SummaryReport';
  capitalData: SummaryCapitalData;
  interestData: SummaryInterestData;
};

export type TotalCommitted = {
  __typename?: 'TotalCommitted';
  id: Scalars['Int'];
  totalCommitted?: Maybe<Scalars['Float']>;
};

export type TotalInvestorData = {
  __typename?: 'TotalInvestorData';
  dailyInvestorData?: Maybe<Array<Maybe<DailyInvestorData>>>;
  interestTotal?: Maybe<Scalars['Float']>;
};

export type UpdatePasswordWithToken = {
  __typename?: 'UpdatePasswordWithToken';
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  investors?: Maybe<Array<Investor>>;
  mfaMethod?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type GetClientByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type GetClientByIdQuery = {
  __typename?: 'Query';
  clientById?: {
    __typename?: 'Client';
    id: number;
    clientName?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    loanInfos?: Array<{
      __typename?: 'LoanInfo';
      id: number;
      clientId: number;
      commitmentAmount?: number | null;
      deployedAmount?: number | null;
      interestRate?: number | null;
      compoundInterest?: boolean | null;
      sofrRate?: boolean | null;
      loanDuration?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      billDate?: any | null;
      client?: {
        __typename?: 'Client';
        id: number;
        clientName?: string | null;
        contactFirstName?: string | null;
        contactLastName?: string | null;
      } | null;
      lateFees?: Array<{
        __typename?: 'LateFee';
        id: number;
        feeAmount?: number | null;
        feeDate?: string | null;
        notes?: string | null;
        loanInfo?: {
          __typename?: 'LoanInfo';
          id: number;
          clientId: number;
          commitmentAmount?: number | null;
          deployedAmount?: number | null;
          interestRate?: number | null;
          compoundInterest?: boolean | null;
          sofrRate?: boolean | null;
          loanDuration?: string | null;
          startDate?: any | null;
          endDate?: any | null;
          billDate?: any | null;
        } | null;
      } | null> | null;
      deployedAdjustments?: Array<{
        __typename?: 'DeployedAdjustment';
        id: number;
        adjustmentAmount?: number | null;
        adjustmentDate?: any | null;
        notes?: string | null;
      }> | null;
      committedAdjustments?: Array<{
        __typename?: 'LoanCommittedAdjustment';
        id: number;
        adjustmentAmount: number;
        adjustmentDate: any;
        notes?: string | null;
      } | null> | null;
      loanNotes?: Array<{
        __typename?: 'LoanNote';
        id: number;
        createdById?: string | null;
        note: string;
        createdAt?: any | null;
        loanInfoId?: number | null;
        createdBy?: {
          __typename?: 'User';
          id: string;
          createdAt?: string | null;
          updatedAt?: string | null;
          password?: string | null;
          email?: string | null;
          roles?: Array<{
            __typename?: 'UserRole';
            id: string;
            name?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetInvestorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvestorsQuery = {
  __typename?: 'Query';
  investors?: Array<{ __typename?: 'Investor'; id: number; memberName?: string | null }> | null;
};

export type GetLoanActivityByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type GetLoanActivityByIdQuery = {
  __typename?: 'Query';
  loanActivity?: {
    __typename?: 'LoanActivity';
    id: number;
    activity?: Array<{
      __typename?: 'Activity';
      id: string;
      amount?: string | null;
      date: string;
      timestamp: number;
      text: string;
    } | null> | null;
  } | null;
};

export type AddLoanNoteMutationVariables = Exact<{
  loanId?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}>;

export type AddLoanNoteMutation = {
  __typename?: 'Mutation';
  addLoanNote?: {
    __typename?: 'AddLoanNoteResponse';
    success?: boolean | null;
    errorMessage?: string | null;
  } | null;
};

export type InitiateVerifyEmailMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
}>;

export type InitiateVerifyEmailMutation = {
  __typename?: 'Mutation';
  initiateVerifyEmail?: string | null;
};

export type InitiateVerifyPhoneMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;

export type InitiateVerifyPhoneMutation = {
  __typename?: 'Mutation';
  initiateVerifyPhone?: string | null;
};

export type Set2FaMethodMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
}>;

export type Set2FaMethodMutation = {
  __typename?: 'Mutation';
  set2FAMethod?: {
    __typename?: 'LoginResponse';
    success?: boolean | null;
    errorMessage?: string | null;
    token?: string | null;
    phoneVerified?: boolean | null;
    emailVerified?: boolean | null;
  } | null;
};

export type UpdateBillingMutationVariables = Exact<{
  input: BillingInput;
}>;

export type UpdateBillingMutation = {
  __typename?: 'Mutation';
  updateBilling?: {
    __typename?: 'UpdateResponse';
    success: boolean;
    errorMessage?: string | null;
  } | null;
};

export type UpdateInvestorDocumentsViewedMutationVariables = Exact<{
  input: InvestorViewedInput;
}>;

export type UpdateInvestorDocumentsViewedMutation = {
  __typename?: 'Mutation';
  updateInvestorDocumentsViewed?: {
    __typename?: 'UpdateResponse';
    success: boolean;
    errorMessage?: string | null;
  } | null;
};

export type UpdatePasswordWithTokenMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
  confirmPassword: Scalars['String'];
}>;

export type UpdatePasswordWithTokenMutation = {
  __typename?: 'Mutation';
  updatePasswordWithToken?: {
    __typename?: 'UpdatePasswordWithToken';
    success: boolean;
    errorMessage?: string | null;
  } | null;
};

export type AddLoanDocumentMutationVariables = Exact<{
  loanInfoId: Scalars['Int'];
  documentName: Scalars['String'];
  documentBlob: Scalars['String'];
  documentType: Scalars['String'];
}>;

export type AddLoanDocumentMutation = {
  __typename?: 'Mutation';
  addLoanDocument?: {
    __typename?: 'AddLoanDocumentResponse';
    success: boolean;
    errorMessage?: string | null;
    documents?: Array<{
      __typename?: 'DocumentDownloadData';
      loanId: number;
      filename: string;
      uploadDate: string;
      s3Url: string;
    } | null> | null;
  } | null;
};

export type Validate2FaCodeMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
}>;

export type Validate2FaCodeMutation = {
  __typename?: 'Mutation';
  validate2FACode?: {
    __typename?: 'LoginResponse';
    success?: boolean | null;
    errorMessage?: string | null;
    token?: string | null;
    emailVerified?: boolean | null;
    phoneVerified?: boolean | null;
  } | null;
};

export type ValidateUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}>;

export type ValidateUserMutation = {
  __typename?: 'Mutation';
  validateUser?: {
    __typename?: 'LoginResponse';
    emailVerified?: boolean | null;
    errorMessage?: string | null;
    mfaMethod?: string | null;
    mfaSid?: string | null;
    phoneNumber?: string | null;
    phoneVerified?: boolean | null;
    success?: boolean | null;
    token?: string | null;
  } | null;
};

export type VerifyEmailMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
}>;

export type VerifyEmailMutation = {
  __typename?: 'Mutation';
  verifyEmail?: {
    __typename?: 'LoginResponse';
    success?: boolean | null;
    errorMessage?: string | null;
    emailVerified?: boolean | null;
    token?: string | null;
  } | null;
};

export type VerifyPhoneMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
}>;

export type VerifyPhoneMutation = {
  __typename?: 'Mutation';
  verifyPhone?: {
    __typename?: 'LoginResponse';
    success?: boolean | null;
    errorMessage?: string | null;
    phoneVerified?: boolean | null;
    token?: string | null;
  } | null;
};

export type GetClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientsQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    id: number;
    clientName?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    loanInfos?: Array<{
      __typename?: 'LoanInfo';
      id: number;
      clientId: number;
      commitmentAmount?: number | null;
      deployedAmount?: number | null;
      interestRate?: number | null;
      compoundInterest?: boolean | null;
      sofrRate?: boolean | null;
      loanDuration?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      billDate?: any | null;
      client?: {
        __typename?: 'Client';
        id: number;
        clientName?: string | null;
        contactFirstName?: string | null;
        contactLastName?: string | null;
      } | null;
    } | null> | null;
  }> | null;
};

export type GetInvestorByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type GetInvestorByIdQuery = {
  __typename?: 'Query';
  investorById?: {
    __typename?: 'Investor';
    id: number;
    email?: string | null;
    bankName?: string | null;
    memberName?: string | null;
    accountNumber?: number | null;
    routingNumber?: number | null;
    committedCapital?: number | null;
    addressInfos?: Array<{
      __typename?: 'AddressInfo';
      id: number;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
    }> | null;
  } | null;
};

export type GetInvestorByNameQueryVariables = Exact<{
  memberName?: InputMaybe<Scalars['String']>;
}>;

export type GetInvestorByNameQuery = {
  __typename?: 'Query';
  investor?: {
    __typename?: 'Investor';
    id: number;
    email?: string | null;
    bankName?: string | null;
    memberName?: string | null;
    accountNumber?: number | null;
    routingNumber?: number | null;
    committedCapital?: number | null;
    addressInfos?: Array<{
      __typename?: 'AddressInfo';
      id: number;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      postalCode?: string | null;
    }> | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    createdAt?: string | null;
    investors?: Array<{
      __typename?: 'Investor';
      id: number;
      memberName?: string | null;
      memberType?: string | null;
      isGP?: boolean | null;
    }> | null;
    roles?: Array<{ __typename?: 'UserRole'; id: string; name?: string | null } | null> | null;
  } | null;
};

export type InvestorDocumentsQueryVariables = Exact<{
  investorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type InvestorDocumentsQuery = {
  __typename?: 'Query';
  investorDocuments: Array<{
    __typename?: 'InvestorS3Document';
    id: number;
    documentName?: string | null;
    documentDate?: any | null;
    documentS3Url?: string | null;
    allInvestors?: boolean | null;
    investors?: Array<{ __typename?: 'InvestorId'; id: number }> | null;
    investorsViewed?: Array<{ __typename?: 'InvestorId'; id: number }> | null;
  }>;
};

export type InvestorSummaryQueryVariables = Exact<{
  input: InvestorSummaryInput;
  investorName?: InputMaybe<Scalars['String']>;
}>;

export type InvestorSummaryQuery = {
  __typename?: 'Query';
  investorSummary?: {
    __typename?: 'InvestorSummary';
    id: string;
    monthlySummary: {
      __typename?: 'SummaryReport';
      interestData: { __typename?: 'SummaryInterestData'; interestAmount: number };
      capitalData: {
        __typename?: 'SummaryCapitalData';
        committedCapital: number;
        gpCapital: number;
      };
    };
    investorsData: {
      __typename?: 'AllInvestorsData';
      investors: Array<{
        __typename?: 'InvestorWithDailyInvestorData';
        investor: {
          __typename?: 'Investor';
          id: number;
          isGP?: boolean | null;
          memberName?: string | null;
          committedCapital?: number | null;
          rollOverPeriods?: Array<{
            __typename?: 'RollOverPeriod';
            startDate?: any | null;
            endDate?: any | null;
          }> | null;
          committedCapitalAdjustments?: Array<{
            __typename?: 'CapitalAdjustment';
            adjustedDate?: any | null;
            notes?: string | null;
            amount?: number | null;
          }> | null;
        };
        dailyInvestorData: Array<{
          __typename?: 'DailyInvestorData';
          date?: string | null;
          isStarted?: boolean | null;
          gpCommitted?: number | null;
          hasCommittedAdjustments?: boolean | null;
          committedAmount?: number | null;
          interestAmount?: number | null;
          committedAdjustmentsOnDay?: Array<{
            __typename?: 'CapitalAdjustment';
            amount?: number | null;
            adjustedDate?: any | null;
          } | null> | null;
        }>;
        investorTotals: {
          __typename?: 'InvestorTotals';
          interestAmount: number;
          gpCommitted?: number | null;
          nonRollOverAdjustments?: number | null;
          distributions?: number | null;
          lastCommittedAmount: number;
        };
      }>;
      total: {
        __typename?: 'TotalInvestorData';
        interestTotal?: number | null;
        dailyInvestorData?: Array<{
          __typename?: 'DailyInvestorData';
          date?: string | null;
          gpCommitted?: number | null;
          committedAmount?: number | null;
          averageRate?: number | null;
          interestAmount?: number | null;
        } | null> | null;
      };
    };
  } | null;
};

export type InvestorsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type InvestorsQuery = {
  __typename?: 'Query';
  investors?: Array<{
    __typename?: 'Investor';
    id: number;
    isGP?: boolean | null;
    bankName?: string | null;
    memberName?: string | null;
    accountNumber?: number | null;
    committedCapital?: number | null;
  }> | null;
};

export type PortfolioSummaryQueryVariables = Exact<{
  input: PortfolioSummaryInput;
}>;

export type PortfolioSummaryQuery = {
  __typename?: 'Query';
  portfolioSummary?: {
    __typename?: 'PortfolioSummary';
    id: string;
    monthlySummary: {
      __typename?: 'MonthlySummaryReport';
      month: string;
      year: string;
      interestData: {
        __typename?: 'InterestData';
        totalInterestExpense: number;
        interestAmount: number;
        dailyInterestExpense: number;
        blendedInterestRate: number;
      };
      capitalData: {
        __typename?: 'CapitalData';
        deployedCapital: number;
        capitalCommitment: number;
      };
    };
    loansData: {
      __typename?: 'AllLoansData';
      loans: Array<{
        __typename?: 'LoanWithDailyLoanData';
        loanInfo: {
          __typename?: 'LoanInfo';
          id: number;
          clientId: number;
          interestRate?: number | null;
          compoundInterest?: boolean | null;
          sofrRate?: boolean | null;
          commitmentAmount?: number | null;
          deployedAmount?: number | null;
          startDate?: any | null;
          endDate?: any | null;
          committedAdjustments?: Array<{
            __typename?: 'LoanCommittedAdjustment';
            id: number;
            adjustmentDate: any;
            adjustmentAmount: number;
            notes?: string | null;
          } | null> | null;
          deployedAdjustments?: Array<{
            __typename?: 'DeployedAdjustment';
            id: number;
            adjustmentDate?: any | null;
            adjustmentAmount?: number | null;
            notes?: string | null;
          }> | null;
          client?: { __typename?: 'Client'; id: number; clientName?: string | null } | null;
        };
        dailyLoanData: Array<{
          __typename?: 'DailyLoanData';
          isStarted: boolean;
          date: string;
          committedAmount: number;
          deployedAmount: number;
          interestAmount: number;
          rate: number;
          hasCommittedAdjustments: boolean;
          hasDeployedAdjustments: boolean;
          hasLateFees: boolean;
          committedAdjustmentsOnDay?: Array<{
            __typename?: 'LoanCommittedAdjustment';
            id: number;
            adjustmentDate: any;
            adjustmentAmount: number;
            notes?: string | null;
          }> | null;
          deployedAdjustmentsOnDay?: Array<{
            __typename?: 'DeployedAdjustment';
            id: number;
            adjustmentDate?: any | null;
            adjustmentAmount?: number | null;
            notes?: string | null;
          }> | null;
          lateFeesOnDay?: Array<{
            __typename?: 'LateFee';
            id: number;
            feeDate?: string | null;
            feeAmount?: number | null;
            notes?: string | null;
          }> | null;
        }>;
        loanTotals: {
          __typename?: 'LoanTotals';
          lastCommittedAmount: number;
          lastDeployedAmount: number;
          interestAmount: number;
          blendedInterestRate: number;
        };
      }>;
      total: {
        __typename?: 'AllLoansTotal';
        interestTotal: number;
        totalCapitalAtWork: number;
        dailyLoanData: Array<{
          __typename?: 'DailyTotalData';
          isStarted: boolean;
          date: string;
          committedAmount: number;
          deployedAmount: number;
          interestAmount: number;
          averageRate: number;
        }>;
      };
    };
  } | null;
};

export type UploadedDocumentsQueryVariables = Exact<{
  loanInfoId: Scalars['Int'];
}>;

export type UploadedDocumentsQuery = {
  __typename?: 'Query';
  uploadedDocuments: Array<{
    __typename?: 'DocumentDownloadData';
    loanId: number;
    filename: string;
    uploadDate: string;
    s3Url: string;
  } | null>;
};

export const GetClientByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loanInfos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'commitmentAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deployedAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'compoundInterest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sofrRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loanDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lateFees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feeDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loanInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'commitmentAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deployedAmount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'interestRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'compoundInterest' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sofrRate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'loanDuration' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'billDate' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deployedAdjustments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adjustmentAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adjustmentDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'committedAdjustments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adjustmentAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adjustmentDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loanNotes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'password' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loanInfoId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientByIdQuery, GetClientByIdQueryVariables>;
export const GetInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvestors' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInvestorsQuery, GetInvestorsQueryVariables>;
export const GetLoanActivityByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLoanActivityById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loanActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLoanActivityByIdQuery, GetLoanActivityByIdQueryVariables>;
export const AddLoanNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addLoanNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loanId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addLoanNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loanId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loanId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'note' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddLoanNoteMutation, AddLoanNoteMutationVariables>;
export const InitiateVerifyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateVerifyEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateVerifyEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateVerifyEmailMutation, InitiateVerifyEmailMutationVariables>;
export const InitiateVerifyPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateVerifyPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateVerifyPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateVerifyPhoneMutation, InitiateVerifyPhoneMutationVariables>;
export const Set2FaMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Set2FAMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'method' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'set2FAMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'method' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'method' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Set2FaMethodMutation, Set2FaMethodMutationVariables>;
export const UpdateBillingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBilling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBilling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBillingMutation, UpdateBillingMutationVariables>;
export const UpdateInvestorDocumentsViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateInvestorDocumentsViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorViewedInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInvestorDocumentsViewed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInvestorDocumentsViewedMutation,
  UpdateInvestorDocumentsViewedMutationVariables
>;
export const UpdatePasswordWithTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePasswordWithToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'confirmPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePasswordWithToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'confirmPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'confirmPassword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePasswordWithTokenMutation,
  UpdatePasswordWithTokenMutationVariables
>;
export const AddLoanDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addLoanDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loanInfoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentBlob' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addLoanDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loanInfoId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loanInfoId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentBlob' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentBlob' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'loanId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 's3Url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddLoanDocumentMutation, AddLoanDocumentMutationVariables>;
export const Validate2FaCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Validate2FACode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validate2FACode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneVerified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Validate2FaCodeMutation, Validate2FaCodeMutationVariables>;
export const ValidateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'validateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaSid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidateUserMutation, ValidateUserMutationVariables>;
export const VerifyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const VerifyPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyPhoneMutation, VerifyPhoneMutationVariables>;
export const GetClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loanInfos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'commitmentAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deployedAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'compoundInterest' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sofrRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loanDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientsQuery, GetClientsQueryVariables>;
export const GetInvestorByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInvestorById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'committedCapital' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressInfos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInvestorByIdQuery, GetInvestorByIdQueryVariables>;
export const GetInvestorByNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvestorByName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'memberName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'memberName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'committedCapital' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressInfos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInvestorByNameQuery, GetInvestorByNameQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'memberType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isGP' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const InvestorDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'investorDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentS3Url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allInvestors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorsViewed' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvestorDocumentsQuery, InvestorDocumentsQueryVariables>;
export const InvestorSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorSummaryInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlySummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interestData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'interestAmount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'capitalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'committedCapital' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gpCapital' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorsData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isGP' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedCapital' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rollOverPeriods' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'startDate' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedCapitalAdjustments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustedDate' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dailyInvestorData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isStarted' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'gpCommitted' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAdjustmentsOnDay' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustedDate' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasCommittedAdjustments' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interestAmount' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorTotals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interestAmount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'gpCommitted' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nonRollOverAdjustments' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'distributions' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastCommittedAmount' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'interestTotal' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dailyInvestorData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'gpCommitted' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAmount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'averageRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interestAmount' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvestorSummaryQuery, InvestorSummaryQueryVariables>;
export const InvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Investors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'committedCapital' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memberName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvestorsQuery, InvestorsQueryVariables>;
export const PortfolioSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortfolioSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioSummaryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlySummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interestData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalInterestExpense' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'interestAmount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dailyInterestExpense' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'blendedInterestRate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'capitalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'deployedCapital' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'capitalCommitment' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loansData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loans' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loanInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'interestRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'compoundInterest' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sofrRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'commitmentAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deployedAmount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAdjustments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentAmount' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deployedAdjustments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentAmount' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'client' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'clientName' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dailyLoanData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isStarted' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deployedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interestAmount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasCommittedAdjustments' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAdjustmentsOnDay' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentAmount' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasDeployedAdjustments' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deployedAdjustmentsOnDay' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentDate' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'adjustmentAmount' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasLateFees' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lateFeesOnDay' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'feeDate' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'feeAmount' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loanTotals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastCommittedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastDeployedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interestAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'blendedInterestRate' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'interestTotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCapitalAtWork' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dailyLoanData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isStarted' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'committedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deployedAmount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interestAmount' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'averageRate' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioSummaryQuery, PortfolioSummaryQueryVariables>;
export const UploadedDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'uploadedDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loanInfoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadedDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loanInfoId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'loanInfoId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'loanId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 's3Url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadedDocumentsQuery, UploadedDocumentsQueryVariables>;
