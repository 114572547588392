export const columnMapping = {
  rate: 2,
  committed: 4,
  deployed: 4,
  interestTotal: 4,
  interestEarned: 4,
  totalCapitalAtWork: 6,
};

export const LOANS = 'Loans';
export const INVESTORS = 'Investors';
export const AUTH_TOKEN = 'userAuthToken';

export const MILLENNIUM_USER = 'Millennium User';
export const INVESTOR = 'Investor';

export const DEFAULT_LOCALE = 'en-us';

export const HARBORLINK_CLIENT_ID = 5;
