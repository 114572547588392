import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  Validate2FaCodeDocument,
  Validate2FaCodeMutation,
  Validate2FaCodeMutationVariables,
} from 'generated/graphql';

type Data = Validate2FaCodeMutation;
type Variables = Validate2FaCodeMutationVariables;

export const useMutationValidate2FACode = (
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> => {
  return useMutation<Data, Variables>(Validate2FaCodeDocument, options);
};

export default useMutationValidate2FACode;
