import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from 'assets/images/logo/MIF-logo.svg';
import useMutationUpdatePasswordWithToken from 'hooks/mutations/useMutationUpdatePasswordWithToken/useMutationUpdatePasswordWithToken';
import AlertBar from 'components/AlertBar/AlertBar';
import SplashLayout from 'components/SplashLayout/SplashLayout';
import ResponsiveText36 from 'components/ResponsiveText36/ResponsiveText36';
import loginSplash from 'assets/images/Login/login-splash.webp';
import PasswordResetForm, {
  PasswordResetFormErrors,
  PasswordResetInitialFormValues,
  PasswordResetType,
} from 'components/PasswordResetForm/PasswordResetForm';
import useQueryParams from 'hooks/useQueryParams';
import { AUTH_TOKEN } from 'utils/constants';
import useAppContext from 'contexts/AppContext/AppContext';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  height: calc(100% - 10rem);
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1.5rem;
    height: calc(100% - 3rem);
  }
`;

const Logo = styled('img')`
  margin: 1.5rem 0 3rem;
`;

const PasswordReset = (): JSX.Element => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [updatePassword] = useMutationUpdatePasswordWithToken();
  const { setToken } = useAppContext();

  const type: PasswordResetType = queryParams.get('type') === 'create' ? 'create' : 'reset';
  const email = queryParams.get('email') ?? '';
  const token = queryParams.get('token') ?? '';

  // navigate() has to be run in an action or useEffect()
  useEffect(() => {
    if (!email || !token) {
      navigate('/');
    }

    // Only run this once - we assume we'll have query parameters available on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialFormValues: PasswordResetInitialFormValues = {
    newPassword: '',
    confirmPassword: '',
    showNewPassword: false,
    showConfirmPassword: false,
    errorMessage: null,
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as PasswordResetFormErrors);

  const submitAttempt = async () => {
    const data = await updatePassword({
      variables: {
        token,
        email,
        newPassword: values?.newPassword,
        confirmPassword: values?.confirmPassword,
      },
    });

    const dataResponse = data?.data?.updatePasswordWithToken;

    setValues({
      ...values,
      errorMessage: dataResponse?.errorMessage,
    });

    if (dataResponse?.success) {
      // Clear the session if they had one
      // so that they don't get logged in automatically
      // and they're forced to enter their new credentials
      setToken(null);

      // Navigate to index and make them log in with their new password.
      navigate(
        `/login?success=${encodeURIComponent(
          `Your ${type === 'create' ? 'account' : 'password'} has been ${
            type === 'create' ? 'created' : 'updated'
          }. Please log in with your new password below.`
        )}`
      );
    } else {
      return;
    }
  };

  const handleFormError = () => {
    setValues({
      ...values,
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const isValid =
      values.newPassword &&
      values.confirmPassword &&
      values.newPassword === values.confirmPassword &&
      Object.values(errors).every((x) => x === '');

    if (isValid) {
      submitAttempt();
    } else {
      handleFormError();
    }
  };

  return (
    <SplashLayout img={loginSplash}>
      <Container>
        <Logo src={logo} alt='Millennium Fund Logo' />
        <div>{values.errorMessage && <AlertBar message={values.errorMessage} type='error' />}</div>
        <ResponsiveText36>{type === 'create' ? 'Create' : 'Reset'} your password</ResponsiveText36>
        <PasswordResetForm
          errors={errors}
          handleFormSubmit={handleFormSubmit}
          setErrors={setErrors}
          setValues={setValues}
          values={values}
          type={type}
        />
      </Container>
    </SplashLayout>
  );
};

export default PasswordReset;
