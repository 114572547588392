import React from 'react';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import { css, styled } from '@mui/material/styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Button from '@mui/material/Button';

const UploadedDocRow = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    color: ${theme.colors.gray7};
    border-bottom: solid 0.0625rem ${theme.colors.mifGray12};
    padding: 1rem 0;
    align-items: center;
    font-weight: 500;
    font-size: 1.3125rem;
    line-height: 1.5625rem;

    svg {
      fill: ${theme.colors.gray7};
    }

    &:last-child {
      border-bottom: none;
    }
  `
);

export const DocumentName = styled(Box)`
  display: flex;
  flex-grow: 1;
  font-size: 1.3125rem;
`;

export const UploadDate = styled(Box)`
  display: flex;
  font-size: 1rem;
  margin-right: 0.75rem;
  width: 5.625rem;
  display: flex;
  justify-content: flex-end;
`;

const CategoryButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.colors.gray7};
    background-color: ${theme.colors.mifGray13};
    border-radius: 2rem;
    padding: 0.5rem 2rem;
    margin-right: 2.5rem;
    text-transform: capitalize;
    font-size: 1rem;
    font-family: 'Roboto';
    &:hover {
      background-color: ${theme.colors.mifGray8};
    }
  `
);

interface UploadedDocProps {
  filename: string | null | undefined;
  uploadDate: string;
  downloadUrl: string;
}

type Props = UploadedDocProps;

const UploadedDoc = ({ filename, uploadDate, downloadUrl }: Props): JSX.Element => {
  const setDate = new Date(uploadDate);
  const formattedDate = DateTime.fromJSDate(setDate).toFormat('MM/dd/yyyy');
  const openLink = () => {
    window.open(downloadUrl, '_blank', 'noreferrer');
  };

  return (
    <UploadedDocRow>
      <InsertDriveFileIcon fontSize={'small'} sx={{ mr: '.8rem' }} />
      <DocumentName>{filename}</DocumentName>
      <CategoryButton onClick={openLink}>Download</CategoryButton>
      <UploadDate>{formattedDate}</UploadDate>
    </UploadedDocRow>
  );
};

export default UploadedDoc;
