import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  AddLoanDocumentDocument,
  AddLoanDocumentMutation,
  AddLoanDocumentMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = AddLoanDocumentMutation;
type Variables = AddLoanDocumentMutationVariables;

function useMutationAddLoanDocument(
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(AddLoanDocumentDocument, opts);
}

export default useMutationAddLoanDocument;
