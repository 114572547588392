import React, { useMemo } from 'react';
import get from 'lodash.get';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { css, styled } from '@mui/material/styles';
import OverviewCard, {
  CapitalFont,
  SubHeaderText,
  SubInterestFont,
  Wrapper,
} from 'components/LoanSummary/OverviewCard/OverviewCard';
import { formatCurrency, formatPercent, getQuarterMonthRangeFromDate } from 'utils/functions';
import { DateRangeVariant, getDateTime, getShortDateByVariant } from 'utils/dates';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { Maybe, PortfolioSummaryQuery } from 'generated/graphql';

const GridContainer = styled(Grid)(
  ({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `
);

interface InvestorMonthlySummaryProps {
  variant: DateRangeVariant;
  currentDate: string;
  setCurrentDate: (newValue: string, endValue: string) => void;
  data: any;
  portfolioSummary?: PortfolioSummaryQuery['portfolioSummary'];
}

type Props = InvestorMonthlySummaryProps;

const InvestorMonthlySummary = ({
  currentDate,
  data,
  setCurrentDate,
  variant,
  portfolioSummary,
}: Props): JSX.Element => {
  const { user } = useUserContext();
  const timePeriod = useMemo(
    () => getShortDateByVariant(getDateTime(currentDate), variant),
    [currentDate, variant]
  );

  const displayDateSubheading =
    variant === 'quarter' ? getQuarterMonthRangeFromDate(currentDate) : undefined;

  const investors = get(data, 'investorsData.investors');
  const userInvestorIds = user?.investors?.map((x) => x.id) || [];
  const investorDetails = investors?.filter(
    (item: { investor: { id: Maybe<number> | undefined } }) =>
      item.investor?.id && userInvestorIds.includes(item.investor.id)
  );

  let lastCommitted = 0;
  let interestAmount = 0;

  if (investorDetails) {
    for (const investorDetail of investorDetails) {
      lastCommitted += get(investorDetail, 'investorTotals.lastCommittedAmount', 0);
      interestAmount += get(investorDetail, 'investorTotals.interestAmount', 0);
    }
  }

  const totalCapital = get(data, 'monthlySummary.capitalData.committedCapital');
  const gpCapital = get(data, 'monthlySummary.capitalData.gpCapital');
  const lpPercent = ((totalCapital - gpCapital) / totalCapital) * 100;
  const gpPercent = (gpCapital / totalCapital) * 100;
  const interestRate = get(portfolioSummary, 'monthlySummary.interestData.blendedInterestRate');

  return (
    <Container sx={{ mb: 8 }}>
      <DateRangeSelector
        value={currentDate}
        onChange={setCurrentDate}
        variant={variant}
        displayDateSubheading={displayDateSubheading}
      />
      <GridContainer container spacing={0}>
        <Grid item xs={12} md={7}>
          <OverviewCard withChildren title={`${timePeriod} Investor Details`}>
            <Wrapper>
              <CapitalFont variant='h1'>
                {lastCommitted ? formatCurrency(lastCommitted) : '-'}
              </CapitalFont>
              <SubHeaderText>Current Capital</SubHeaderText>
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Box sx={{ mr: 3 }}>
                  <SubInterestFont>
                    {interestAmount ? formatCurrency(interestAmount) : '-'}
                  </SubInterestFont>
                  <SubHeaderText>{timePeriod} Interest Earned</SubHeaderText>
                </Box>
                <Box sx={{ mr: 3 }}>
                  <SubInterestFont>
                    {interestAmount
                      ? formatPercent(interestRate ? interestRate * 0.8 : interestRate)
                      : '-'}
                  </SubInterestFont>
                  <SubHeaderText>{timePeriod} Blended Interest Rate</SubHeaderText>
                </Box>
              </Box>
            </Wrapper>
          </OverviewCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <OverviewCard withChildren divider title='Capital'>
            <Wrapper>
              <CapitalFont variant='h1'>
                {totalCapital ? formatCurrency(totalCapital) : '-'}
              </CapitalFont>
              <SubHeaderText>Deployed Capital</SubHeaderText>
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Box sx={{ mr: 3 }}>
                  <SubInterestFont>
                    {lpPercent ? `${parseFloat(lpPercent.toFixed(2))}%` : '-'}
                  </SubInterestFont>
                  <SubHeaderText>LP Fund Amount</SubHeaderText>
                </Box>
                <Box>
                  <SubInterestFont>
                    {gpPercent ? `${parseFloat(gpPercent.toFixed(2))}%` : '-'}
                  </SubInterestFont>
                  <SubHeaderText>GP Fund Amount</SubHeaderText>
                </Box>
              </Box>
            </Wrapper>
          </OverviewCard>
        </Grid>
      </GridContainer>
    </Container>
  );
};

export default InvestorMonthlySummary;
