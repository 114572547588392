import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

interface OwnProps {
  children: JSX.Element;
}

type Props = OwnProps;

function AnalyticsListener({ children }: Props) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return children;
}

export default AnalyticsListener;
