import React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import AnalyticsListener from 'components/AnalyticsListener';
import AppRoutes from './routes';

function App(): JSX.Element {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GOOGLE_ANALYTICS_CODE) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
  }

  return (
    <BrowserRouter>
      <AnalyticsListener>
        <AppRoutes />
      </AnalyticsListener>
    </BrowserRouter>
  );
}

export default App;
