export const loansHeaders = ['Date', 'Deployed', 'Interest'];

export const loansInfo = {
  committed: 1000000,
  interest: 333.33,
};

export const totalLoansHeaders = ['Date', 'Investor Total', 'LP Investment', 'Interest Earned'];

export const totalLoansInfo = {
  investorTotal: 1000000,
  lpInvestment: 777.77,
  interestEarned: 333.33,
};

export const mockSummaryLoanDetails = {
  initialLoanAmount: 2500813.78,
  initialRate: 0.12,
  currentLoanAmount: 7500813.78,
  currentRate: 0.18,
};
