import React from 'react';
import get from 'lodash.get';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { formatCurrency, formatPercent } from 'utils/functions';
import { InvestorSummaryQuery, PortfolioSummaryQuery } from 'generated/graphql';

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    background-color: ${theme.colors.fadedBlack30};
    padding: 2.5rem 3rem;
    text-align: left;
    color: ${theme.palette.common.white};

    ${theme.breakpoints.down('lg')} {
      padding: 2.5rem 2rem;
    }
  `
);

const StyledPaperInnerContainer = styled(Container)(
  ({ theme }) => css`
    padding-left: 4rem;
    border-left: 3px solid ${theme.colors.mifGray1};

    ${theme.breakpoints.down('lg')} {
      padding-left: 1.25rem;
    }

    ${theme.breakpoints.down('md')} {
      padding-left: 0;
      border: 0;
    }
  `
);

const SummaryAmountFont = styled(Typography)`
  font-size: 4rem;
  line-height: 4rem;
  padding-bottom: 0.5rem;
  font-family: Teko;
`;

const SummaryLabelFont = styled(Typography)(
  ({ theme }) => css`
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-size: 0.875rem;
    font-weight: 700;
    color: ${theme.colors.fadedBlack50};
  `
);

const SummaryCardContainer = styled(Grid)(
  ({ theme }) => css`
    margin-top: 0;
    padding-top: 5.25rem;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `
);

interface ReportSummaryCardProps {
  investorId?: number;
  investorSummary?: InvestorSummaryQuery | undefined;
  portfolioSummary?: PortfolioSummaryQuery | undefined;
}

type Props = ReportSummaryCardProps;

const ReportSummaryCards = ({
  investorId,
  investorSummary,
  portfolioSummary,
}: Props): JSX.Element => {
  const currentInvestor = investorSummary?.investorSummary?.investorsData.investors.filter(
    (item) => item?.investor?.id === investorId
  );
  const dailyData = get(currentInvestor, '[0].dailyInvestorData');
  const totals = get(currentInvestor, '[0].investorTotals');
  const endDateData = dailyData?.at(-1);

  const rate = get(
    portfolioSummary,
    'portfolioSummary.monthlySummary.interestData.blendedInterestRate'
  );

  return (
    <SummaryCardContainer container>
      <Grid item md={4}>
        <StyledPaper elevation={0}>
          <SummaryAmountFont>{formatCurrency(endDateData?.committedAmount)}</SummaryAmountFont>
          <SummaryLabelFont variant='h5'>Committed Capital</SummaryLabelFont>
        </StyledPaper>
      </Grid>
      <Grid item md={4}>
        <StyledPaper elevation={0}>
          <StyledPaperInnerContainer>
            <SummaryAmountFont>{formatPercent(rate ? rate * 0.8 : rate)}</SummaryAmountFont>
            <SummaryLabelFont variant='h5'>Blended Interest Rate</SummaryLabelFont>
          </StyledPaperInnerContainer>
        </StyledPaper>
      </Grid>
      <Grid item md={4}>
        <StyledPaper elevation={0}>
          <StyledPaperInnerContainer>
            <SummaryAmountFont>{formatCurrency(totals?.interestAmount)}</SummaryAmountFont>
            <SummaryLabelFont variant='h5'>Return</SummaryLabelFont>
          </StyledPaperInnerContainer>
        </StyledPaper>
      </Grid>
    </SummaryCardContainer>
  );
};

export default ReportSummaryCards;
