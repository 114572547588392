import React from 'react';
import { useNavigate } from 'react-router-dom';
import { css, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const StyledBackButton = styled(Button)(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.palette.common.white};
    display: flex;
    font-weight: 700;
    padding-left: 1.5rem;
    padding-top: 1rem;
    text-decoration: none;
    width: fit-content;

    &:hover,
    &:focus {
      color: ${theme.palette.common.white};
      text-decoration: underline;
    }

    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  `
);

interface BackButtonProps {
  text: string;
}

type Props = BackButtonProps;

const BackButton = ({ text }: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <StyledBackButton onClick={() => navigate(-1)} disableRipple>
      <ArrowBackIosIcon />
      <span>{text}</span>
    </StyledBackButton>
  );
};

export default BackButton;
