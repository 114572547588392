import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  UploadedDocumentsDocument,
  UploadedDocumentsQuery,
  UploadedDocumentsQueryVariables,
} from 'generated/graphql';

type Data = UploadedDocumentsQuery;
type Variables = UploadedDocumentsQueryVariables;

function useQueryUploadedDocuments(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(UploadedDocumentsDocument, options);
}

export default useQueryUploadedDocuments;
