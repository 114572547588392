import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { Investor, Maybe } from 'generated/graphql';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import FormInput from 'components/InvestorProfile/FormInput/FormInput';

const GridItem = styled(Grid)`
  margin-top: 10px;
  min-height: 3.3125rem;
`;

const BillingContainer = styled('div')(
  ({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${theme.palette.common.white};
    display: flex;
    height: 1.9375rem;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  `
);

const BillingHeading = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.125rem;
    font-size: 1.3125rem;
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    svg {
      color: ${theme.palette.common.white};
    }
  `
);

interface BillingFormProps {
  investor: Maybe<Investor> | undefined;
}

type Props = BillingFormProps;

const BillingForm = ({ investor }: Props): JSX.Element => {
  const [showAccount, setShowAccount] = useState(false);
  const handleClickShowAccount = () => {
    setShowAccount(!showAccount);
  };

  return (
    <>
      <Grid item md={5}>
        <Grid container>
          <GridItem item md={12}>
            <BillingContainer>
              <BillingHeading variant='h4'>Billing</BillingHeading>
            </BillingContainer>
          </GridItem>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <FormInput
              id='bank'
              label='Bank'
              readOnly={true}
              type='text'
              value={investor?.bankName ?? ''}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <FormInput
              id='routing-number'
              label='Routing #'
              readOnly={true}
              type='text'
              value={investor?.routingNumber ?? ''}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <FormInput
              id='account-number'
              name='account-number'
              label='Account #'
              readOnly={true}
              type={showAccount ? 'number' : 'password'}
              value={investor?.accountNumber ?? ''}
              endAdornment={
                <InputAdornment position='end'>
                  <StyledIconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowAccount}
                  >
                    {showAccount ? <VisibilityOff /> : <Visibility />}
                  </StyledIconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BillingForm;
