import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import theme from '../../../theme';
import { styled } from '@mui/material/styles';
import { useUserContext } from 'contexts/UserContext/UserContext';
import useQueryInvestors from 'hooks/queries/useQueryInvestors/useQueryInvestors';
import useQueryGetInvestorById from 'hooks/queries/useQueryGetInvestorById/useQueryGetInvestorById';
import { INVESTOR, MILLENNIUM_USER } from 'utils/constants';

const StyledButton = styled(Button)`
  display: block;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const FundMenu = styled(Menu)`
  .MuiPaper-root {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    overflow: visible;
    margin-top: 0.75rem;
    min-width: 6.5rem;

    &:before {
      background-color: white;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 14px;
      width: 10px;
      height: 10px;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;

const StyledFundsMenuName = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

const FundsMenu = (): JSX.Element => {
  const { user, role } = useUserContext();
  const [anchorElFundsNav, setAnchorElFundsNav] = React.useState<null | HTMLElement>(null);
  const fundsNavOpen = Boolean(anchorElFundsNav);

  const { data } = useQueryInvestors({
    skip: role === INVESTOR && !!user?.investors && user.investors.length < 2,
    variables: {
      ids: user?.investors ? user.investors.map((x) => x.id) : undefined,
    },
  });

  const { data: investorData } = useQueryGetInvestorById({
    skip: role === MILLENNIUM_USER || (!!user?.investors && user.investors.length > 1),
    variables: {
      id: user?.investors?.[0]?.id,
    },
  });

  const singleInvestor = investorData?.investorById;
  const sortedInvestors = useMemo(
    () =>
      data?.investors
        ?.slice()
        .sort((a, b) =>
          (a.memberName || '') > (b.memberName || '')
            ? 1
            : (b.memberName || '') > (a.memberName || '')
            ? -1
            : 0
        ),
    [data?.investors]
  );

  const handleOpenFundsNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFundsNav(event.currentTarget);
  };

  const handleCloseFundsNavMenu = () => {
    setAnchorElFundsNav(null);
  };

  return (
    <>
      <StyledButton
        sx={{ color: theme.colors.mifBlue1 }}
        onClick={handleOpenFundsNavMenu}
        aria-controls={fundsNavOpen ? 'funds-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={fundsNavOpen ? 'true' : undefined}
      >
        <StyledFundsMenuName>Investor Funds</StyledFundsMenuName>
      </StyledButton>
      <FundMenu
        anchorEl={anchorElFundsNav}
        id='funds-menu'
        open={fundsNavOpen}
        onClose={handleCloseFundsNavMenu}
        PaperProps={{ elevation: 0 }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {sortedInvestors?.map((investor) => (
          <MenuItem
            key={investor.id}
            component={Link}
            to={`/investors/${investor.id}`}
            onClick={handleCloseFundsNavMenu}
          >
            {investor.memberName}
          </MenuItem>
        ))}
        {singleInvestor && (
          <MenuItem
            key={singleInvestor.id}
            component={Link}
            to={`/investors/${singleInvestor.id}`}
            onClick={handleCloseFundsNavMenu}
          >
            {singleInvestor.memberName}
          </MenuItem>
        )}
      </FundMenu>
    </>
  );
};

export default FundsMenu;
