import {
  VerifyPhoneDocument,
  VerifyPhoneMutation,
  VerifyPhoneMutationVariables,
} from 'generated/graphql';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';

type Data = VerifyPhoneMutation;
type Variables = VerifyPhoneMutationVariables;

export const useMutationVerifyPhone = (
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> => {
  return useMutation<Data, Variables>(VerifyPhoneDocument, options);
};

export default useMutationVerifyPhone;
