import { css, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { ifProp } from 'styled-tools';

const Input = styled(InputBase)<{ error: boolean }>(
  ({ theme }) => css`
    background-color: white;
    border: 1px solid ${theme.colors.gray7};
    width: 21.25rem;
    max-width: 100%;

    ${ifProp(
      'error',
      css`
        border: 1px solid ${theme.palette.error.main};
      `
    )}

    .MuiInputBase-input {
      padding: 10px 12px;
      position: relative;
    }

    svg {
      height: 0.75em;
      width: 0.75em;
    }
  `
);

export default Input;
