import { columnMapping } from 'utils/constants';

export type Callback = (...args: any[]) => void;

export type ObjectKey = keyof typeof columnMapping;

export interface MonthlySummaryData {
  totalCapitalAtWork: number;
  interestTotal: number;
}

export interface CommittedCapitalByMonth {
  month: string;
  data: {
    date: string;
    capital: number;
    loanInterest: number;
    interest: number;
    blendedRate: number;
  };
}

export interface MonthlySummaryReport {
  month: string;
  year: string;
  interestData: {
    totalInterestExpense: number;
    interestAmount: number;
    dailyInterestExpense: number;
    blendedInterestRate: number;
  };
  capitalData: { deployedCapital: number; capitalCommitment: number };
}

export type LoanSummaryDetails = {
  summaryData: MonthlySummaryReport;
  loansDataWithTotal: Loan[];
  loansInfoItems: { committed: number; interest: number }[];
  loansInfoTotal: { committed: number; interest: number };
  investorsWithTotal: {
    interestEarned: number;
    name: string;
    isGP?: boolean | null | undefined;
    capital?: number | null | undefined;
  }[];
  investorsInfoItems: { committed: number; interest: number }[];
  investorsInfoTotal: {
    investorTotal: number;
    lpInvestment: number;
    interestEarned: number;
  };
};

export interface MonthlyDetails {
  date: string;
  committed?: number;
  interest?: number;
  'Investor Total'?: number;
  'LP Investment'?: number;
  'Interest Earned'?: number;
}

export interface Loan {
  id?: number;
  name: string;
  memberName?: string;
  rate?: number | null | undefined;
  committed?: number | null | undefined;
  committedCapital?: number;
  deployed?: number | null | undefined;
  interestTotal: number;
  totalCapitalAtWork?: number | null | undefined;
  __typename?: string;
}

export interface Investor {
  id?: number;
  email?: string;
  name: string;
  memberName?: string;
  capital?: number | null | undefined;
  committedCapital?: number;
  isGP?: boolean | null | undefined;
  deployed?: number;
  interestEarned: number;
  addressInfos?: AddressInfo;
  __typename?: string;
}

export interface AddressInfo {
  id: number;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface UpdatedLoan {
  id?: number;
  rate?: number;
  committed?: number;
  interestTotal: number;
  totalCapitalAtWork?: number;
}

export interface UpdatedInvestor {
  id?: number;
  'Interest Earned': number;
}

export interface MenuItemType {
  label: string;
  value: string;
}

export type FormErrors = {
  email: string;
  password: string;
};

export type InitialFormValues = {
  email: string;
  password: string;
  showPassword: boolean;
  errorMessage?: string | null;
  success?: boolean | null;
};

export type JwtToken = {
  email: string;
  exp: number;
  iat: number;
  userId: string;
};

export type TotalInterestItem = {
  date: string;
  capital: number;
  interest: number;
};

export type MfaValues = {
  method: string;
  emailVerified: boolean;
  email: string;
  phone?: string;
  phoneVerified: boolean;
  sid: string;
};

export enum LoginState {
  Password = 'password',
  MFA = 'mfa',
  EnrollMFA = 'enrollMFA',
  Complete = 'complete',
}
