import React, { createContext, useContext, useMemo } from 'react';
import get from 'lodash.get';
import jwt_decode from 'jwt-decode';
import useQueryGetUser from 'hooks/queries/useQueryGetUser/useQueryGetUser';
import { User } from 'generated/graphql';
import { JwtToken } from 'types';
import useAppContext from 'contexts/AppContext/AppContext';

type UserContextState = {
  loading: boolean;
  token: string;
  user?: User | null | undefined;
  role?: string;
};

const UserContext = createContext<UserContextState | undefined>(undefined);

interface UserContextProviderProps {
  children: React.ReactNode;
}

type Props = UserContextProviderProps;

const UserContextProvider = ({ children }: Props) => {
  const { token } = useAppContext();
  const decodedToken: JwtToken | undefined = token ? jwt_decode(token) : undefined;
  const data = useQueryGetUser({
    variables: {
      email: decodedToken?.email,
    },
  });
  const userData = get(data, 'data.user');
  const userRole = get(data, 'data.user.roles.[0].name');

  // Memoize the state value to improve context performance.
  const stateValue = useMemo<UserContextState>(
    () => ({
      loading: false,
      token: token || '',
      user: userData,
      role: userRole,
    }),
    [userData, userRole, token]
  );

  return <UserContext.Provider value={stateValue}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext was used outside of its Provider');
  }

  return context;
};

export { UserContext, UserContextProvider, useUserContext };
