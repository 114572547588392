import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Error404Page = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: (theme) => ({ xs: 'auto', lg: `calc(100vh - ${theme.headerHeight / 16}rem)` }),
        minHeight: (theme) => ({ xs: `calc(100vh - ${theme.headerHeight / 16}rem)`, lg: 0 }),
        width: '100%',
      }}
    >
      <Stack direction='column' alignItems='center' spacing={5}>
        <Typography variant='h1'>404</Typography>
        <Typography variant='h2' align='center'>
          Page Not Found
        </Typography>
      </Stack>
    </Box>
  );
};

export default Error404Page;
