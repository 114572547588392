import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DateRangeVariant, getDateTime, getDisplayDateByVariant } from 'utils/dates';
import { DateTime, DurationLike } from 'luxon';

const DateContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 1rem;
  max-width: 100%;
`;

const StyledButton = styled(Button)<ButtonProps>(
  ({ theme }) => css`
    min-width: auto;
    border-radius: 50%;
    border-color: ${theme.colors.red};
    color: ${theme.colors.red};
    height: 2.5rem;
    width: 2.5rem;
    border-width: 0.125rem;
    margin: 0 !important;

    &:hover {
      border-color: ${theme.colors.red};
      color: ${theme.colors.red};
    }
  `
);

const DateText = styled(Typography)(
  ({ theme }) => css`
    font-size: 4rem;
    line-height: 80%;
    font-weight: 600;
    font-family: 'Teko';
    text-align: center;
    color: ${theme.colors.gray7};

    ${theme.breakpoints.down('sm')} {
      font-size: 2.5rem;
    }
  `
);

const DateTextSubheading = styled('span')`
  font-size: 2rem;
  line-height: 1;
`;

const VARIANT_DATE_MODIFIER: Record<DateRangeVariant, DurationLike> = {
  month: { month: 1 },
  quarter: { quarter: 1 },
};

interface DateRangeSelectorProps {
  variant: DateRangeVariant;
  value: string;
  onChange: (newValue: string, endDate: string) => void;
  displayDateSubheading?: string;
}

type Props = DateRangeSelectorProps;

const DateRangeSelector = ({
  value,
  onChange,
  displayDateSubheading,
  variant = 'month',
}: Props) => {
  const dateTime = useMemo<DateTime>(() => getDateTime(value), [value]);
  const displayDate = useMemo<string>(
    () => getDisplayDateByVariant(dateTime, variant),
    [dateTime, variant]
  );

  const handleModifyDate = useCallback(
    (direction: 'plus' | 'minus') => {
      const newValueDt = dateTime[direction](VARIANT_DATE_MODIFIER[variant]);
      const newValue = newValueDt.toFormat('yyyy-MM');
      const endValue = newValueDt.endOf(variant).toFormat('yyyy-MM-dd');

      onChange(newValue, endValue);
    },
    [dateTime, onChange, variant]
  );

  const handlePrevClick = useCallback(() => {
    handleModifyDate('minus');
  }, [handleModifyDate]);

  const handleNextClick = useCallback(() => {
    handleModifyDate('plus');
  }, [handleModifyDate]);

  return (
    <DateContainer>
      <StyledButton onClick={handlePrevClick} variant='outlined' sx={{ mr: 10 }}>
        <NavigateBeforeIcon />
      </StyledButton>
      <DateText variant='h2' color={'primary'}>
        {displayDate}
        {displayDateSubheading && (
          <>
            <br />
            <DateTextSubheading>{displayDateSubheading}</DateTextSubheading>
          </>
        )}
      </DateText>
      <StyledButton onClick={handleNextClick} variant='outlined' sx={{ ml: 10 }}>
        <NavigateNextIcon />
      </StyledButton>
    </DateContainer>
  );
};

export default DateRangeSelector;
