import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from 'generated/graphql';

type Data = GetUserQuery;
type Variables = GetUserQueryVariables;

function useQueryGetUser(
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(GetUserDocument, {
    ...options,
  });
}

export default useQueryGetUser;
