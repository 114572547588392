import React from 'react';
import { styled, css } from '@mui/material/styles';

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    height: 100vh;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column-reverse;
    }
  `
);

const Img = styled('div')<{ img: string }>`
  width: 50%;
  background: ${(props) => `url(${props.img}) no-repeat center`};
  background-size: cover;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: 40vh;
  }
`;

const Content = styled('div')`
  width: 50%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: 60vh;
  }
`;

type Props = {
  img: string;
  children: React.ReactNode;
};

const SplashLayout: React.FC<Props> = ({ img, children }) => {
  return (
    <Container>
      <Img img={img} />
      <Content>{children}</Content>
    </Container>
  );
};

export default SplashLayout;
